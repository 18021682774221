import React, {useCallback, useEffect, useState} from 'react'
import {FormularioLivros} from "./FormularioLivros";
import {Livro} from "../../types/Livro";
import {useDispatch} from "react-redux";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useAlert} from "react-alert";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {UsuarioType} from "../../types/Usuario";
import {GrupoAlunos} from "../../types/GrupoAlunos";
import {Turma} from "../../types/Turma";
import {Secretaria} from "../../types/Secretaria";
import {Escola} from "../../types/Escola";

export const CadastrarLivrosContainer = () => {
    const dispatch = useDispatch()
    const api = useGrowApi()
    const alert = useAlert()
    const [usuarios, setUsuarios] = useState<UsuarioType[]>([])
    const [grupos, setGrupos] = useState<GrupoAlunos[]>([])
    const buscaUsuarios = async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Usuario.buscarAlunos();
            setUsuarios(response.data.rows)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }

    const cadastrar = async (dados: Livro) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Livro.cadastrar(dados);
            alert.success(response.message, {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }
    const buscaGrupos2 = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.GrupoAlunos.buscarTodos();
            setGrupos(response.data.rows)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [alert, api.GrupoAcesso, dispatch, setGrupos])
    useEffect(() => {
        buscaGrupos2()
        buscaUsuarios()
    }, [])
    return <>
        <FormularioLivros
            grupos={grupos}
            usuarios={usuarios}
            initialValues={{
                name: '',
                isPublic: false,
                file: {} as File,
                users: [],
                grupos: [],
                turmas: [],
                destinationUrl: '',
                secretariaId: 0,
                escolas: []
            } as Livro}
            onSubmit={cadastrar}
            resetarForm
            titulo="Cadastrar livro"
        />
    </>
}
