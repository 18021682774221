import React, {useCallback, useEffect, useState} from 'react'
import {ResolverRecuperacao} from "./ResolverRecuperacao";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {useDispatch, useSelector} from "react-redux";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useAlert} from "react-alert";
import {useParams} from "react-router-dom";
import {RootState} from "../../redux/RootState";
import {UsuarioType} from "../../types/Usuario";
import {TotalAcertos} from "../Simulados/TotalAcertos";
import {Recuperacao} from "../../types/Recuperacao";

export const ResolverRecuperacaoContainer = () => {
    const [certas, setCertas] = useState<number>(0)
    const [tela, setTela] = useState<1 | 2>(1)
    const dispatch = useDispatch()
    const api = useGrowApi()
    const alert = useAlert()
    const {id} = useParams<{id: string}>()
    const [prova, setRecuperacao] = useState<Recuperacao>({} as Recuperacao)
    const [aluno, setAluno] = useState<UsuarioType>({} as UsuarioType)
    const usuarioLogado = useSelector((state: RootState) => state.usuarioLogado);
    const buscarSimulado = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Recuperacao.buscarUm(Number(id));
            setRecuperacao(response.data)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [api, dispatch, alert, setRecuperacao, id])

    const buscarAluno = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Usuario.buscarUm(usuarioLogado.id!);
            setAluno(response.data)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [api, dispatch, alert, setAluno, usuarioLogado])

    const onSubmit = async (dados: Recuperacao) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const dadosFormatados: {nome: string, cpf: string, respostas: any[]} = {
                nome: aluno.name,
                cpf: aluno.cpf ?? "",
                respostas: []
            }
            let resultado = 0;
            for (const questao of dados?.simulados!) {
                const opcoesFormatadas: any[] = []
                for (const opcao of questao.opcoes) {
                    opcoesFormatadas.push({
                        opcaoId: opcao.id,
                        selecionado: opcao.selecionado ?? false,
                        correto: opcao.correto
                    })
                    if (opcao.selecionado && opcao.correto) {
                        resultado += 1;
                    }
                }
                dadosFormatados.respostas.push({
                    simuladoId: questao.id,
                    resposta: questao.resposta ?? null,
                    opcoes: opcoesFormatadas
                })
            }
            await api.Jogo.score(dadosFormatados)
            if (prova.revelarNota) {
                setTela(2)
            }
            setCertas(resultado)
            alert.success('Respostas registradas com sucesso !', {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }
    useEffect(() => {
        buscarSimulado()
        buscarAluno()
    }, [])
    return <>
        {
            tela === 1 &&
            <ResolverRecuperacao
                onSubmit={onSubmit}
                initialValues={prova}
            />
        }
        {
            tela === 2 &&
            <TotalAcertos recuperacao certas={certas} total={prova?.simulados!.length} />
        }
    </>
}
