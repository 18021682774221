import React, {useCallback, useEffect, useState} from 'react'
import {Usuario} from "./Usuario";
import {ROUTES_PATH} from "../RoutesPath";
import {UsuarioType} from "../../types/Usuario";
import {ColumnHeader} from "../TabelaBase/TabelaBase";
import {useGrowApi} from "../../hooks/useGrowApi";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {useDispatch} from "react-redux";
import {useAlert} from "react-alert";
import {FindAllParams} from "../../types/FindAllParams";
import {BotaoAcoes} from "../Botoes/BotaoAcoes";
import {useNavigate} from "react-router-dom";
import {CarregarCSV} from "../CarregarCSV/CarregarCSV";
import { Turma } from '../../types/Turma';

export const ListarAlunosContainer = () => {
    const [usuarios, setUsuarios] = useState<UsuarioType[]>([])
    const [qtdPag, setQtdPag] = useState<number>(0)
    const [pesquisa, setPesquisa] = useState<string>('')
    const [page, setPage] = useState<number>(1)
    const [grupos, setGrupos] = useState<Turma[]>([])
    const api = useGrowApi();
    const dispatch = useDispatch()
    const alert = useAlert()
    const navigate = useNavigate()
    const cadastrar = useCallback(async (file: File, id: number) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Usuario.cadastrar({
                grupoAlunoId: id,
                student: true
            } as UsuarioType, file);
            alert.success(response.message, {
                timeout: 4000
            })
            await buscaDados({
                page,
                search: pesquisa
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [api, dispatch, alert])
    const buscaDados = useCallback(
        async (dados?: FindAllParams) => {
            try {
                dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
                const response = await api.Usuario.buscarAlunos(dados);
                setQtdPag(response.data.count)
                setUsuarios(response.data.rows)
            } catch (e: any) {
                TratamentoErroPadrao(e, alert);
            } finally {
                dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
            }
        }, [page])
    const handleExcluir = useCallback(async (id: number) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Usuario.excluir(id)
            await buscaDados({page, search: pesquisa})
            alert.success(response.message, {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [])
    const headers: ColumnHeader[] = [
        {
            label: '#',
            columnName: 'id'
        },
        {
            label: 'Nome',
            columnName: 'name',
            render: (value) => value ?? `Não cadastrado`
        },
        {
            label: 'CPF',
            columnName: 'cpf'
        },
        {
            label: 'Turma',
            columnName: 'turma.nome',
            render: (value) => value ?? `Não cadastrado`
        },
        {
            label: 'E-mail',
            columnName: 'email',
            render: (value) => value ?? `Não cadastrado`
        },
        {
            label: 'Ações',
            columnName: 'id',
            render: (id) => <BotaoAcoes
                onBotaoEditarClicado={() => navigate(`/alunos/editar/${id}`)}
                onBotaoExcluirClicado={async () => {
                    await handleExcluir(id)
                }}
            />
        }
    ]
    const buscaGrupos2 = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Turma.buscarTodos();
            setGrupos(response.data.rows)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [alert, api.GrupoAcesso, dispatch, setGrupos])
    useEffect(() => {
        buscaDados({
            page,
            search: pesquisa
        })
        buscaGrupos2()
    }, [page, pesquisa])
    return <>
        <Usuario
            extra={<CarregarCSV cadastrar={cadastrar} grupos={grupos} />}
            rows={usuarios}
            cadastrarNovoLink={ROUTES_PATH.CADASTRAR_ALUNO}
            qtdPaginas={qtdPag}
            pesquisar={(pesquisa) => setPesquisa(pesquisa)}
            onPageChange={(page) => setPage(page)}
            headers={headers}
        />
    </>
}
