import React, {useCallback, useEffect, useState} from 'react'
import {Usuario} from "./Usuario";
import {ROUTES_PATH} from "../RoutesPath";
import {UsuarioType} from "../../types/Usuario";
import {ColumnHeader} from "../TabelaBase/TabelaBase";
import {useGrowApi} from "../../hooks/useGrowApi";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {useDispatch} from "react-redux";
import {useAlert} from "react-alert";
import {FindAllParams} from "../../types/FindAllParams";
import {BotaoAcoes} from "../Botoes/BotaoAcoes";
import {useNavigate} from "react-router-dom";

export const UsuarioContainer = () => {
    const [usuarios, setUsuarios] = useState<UsuarioType[]>([])
    const [qtdPag, setQtdPag] = useState<number>(0)
    const [pesquisa, setPesquisa] = useState<string>('')
    const [page, setPage] = useState<number>(1)
    const api = useGrowApi();
    const dispatch = useDispatch()
    const alert = useAlert()
    const navigate = useNavigate()
    const buscaDados = useCallback(
        async (dados?: FindAllParams) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Usuario.buscarTodos(dados);
            setQtdPag(response.data.count)
            setUsuarios(response.data.rows)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [page])
    const handleExcluir = useCallback(async (id: number) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Usuario.excluir(id)
            await buscaDados({page, search: pesquisa})
            alert.success(response.message, {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [])
    const headers: ColumnHeader[] = [
        {
            label: '#',
            columnName: 'id'
        },
        {
            label: 'Nome',
            columnName: 'name'
        },
        {
            label: 'Tipo de usuário',
            columnName: 'tipo_usuario'
        },
        {
            label: 'Escola relacionada',
            columnName: 'escola.nome',
            render: (nome) => nome ?? 'Não se aplica'
        },
        {
            label: 'Secretaria relacionada',
            columnName: 'secretaria.nome',
            render: (nome) => nome ?? 'Não se aplica'
        },
        {
            label: 'E-mail',
            columnName: 'email'
        },
        {
            label: 'Ações',
            columnName: 'id',
            render: (id) => <BotaoAcoes
                onBotaoEditarClicado={() => navigate(`/usuario/editar/${id}`)}
                onBotaoExcluirClicado={async () => {
                    await handleExcluir(id)
                }}
            />
        }
    ]
    useEffect(() => {
        buscaDados({
            page,
            search: pesquisa
        })
    }, [page, pesquisa])
    return <>
        <Usuario
            rows={usuarios}
            cadastrarNovoLink={ROUTES_PATH.CADASTRAR_USUARIO}
            qtdPaginas={qtdPag}
            pesquisar={(pesquisa) => setPesquisa(pesquisa)}
            onPageChange={(page) => setPage(page)}
            headers={headers}
        />
    </>
}
