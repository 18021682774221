import { BaseApi } from './BaseApi';
import {Paginacao} from "../types/Paginacao";
import {FindAllParams} from "../types/FindAllParams";
import {IResponsePadrao} from "../types/IResponsePadrao";
import {Constants} from "../types/Constants";
import {RealidadeAumentada} from "../types/RealidadeAumentada";

export class RealidadeAumentadaApi extends BaseApi {
    async buscarTodos(dados?: FindAllParams): Promise<IResponsePadrao<Paginacao<RealidadeAumentada>>> {
        const response = await this.api.get('/three-d', {
            params: {
                page: dados?.page,
                take: Constants.ITEMS_PER_PAGE,
                search: dados?.search
            }
        });
        return response.data;
    }

    async buscarUm(id: number): Promise<IResponsePadrao<RealidadeAumentada>> {
        const response = await this.api.get(`/three-d/${id}`);
        return response.data;
    }

    async editar(dados: RealidadeAumentada, id: number): Promise<IResponsePadrao<RealidadeAumentada>> {
        const formData = new FormData();
        formData.append("name", dados?.name ?? '')
        if (dados.file){
            formData.append("file", dados.file)
        }
        formData.append("bookId", String(dados.book.id))
        if (dados.page) {
            formData.append("page", String(dados.page))
        }
        return (await this.api.put(`/three-d/update-file/${id}`, formData)).data;
    }

    async cadastrar(dados: RealidadeAumentada): Promise<IResponsePadrao<RealidadeAumentada>> {
        const formData = new FormData();
        formData.append("name", dados?.name ?? '')
        formData.append("file", dados.file!)
        formData.append("bookId", String(dados.book.id))
        if (dados.page) {
            formData.append("page", String(dados.page))
        }
        return (await this.api.post(`/three-d`, formData)).data;
    }

    async excluir(id: number): Promise<IResponsePadrao<RealidadeAumentada>> {
        const response = await this.api.delete(`/three-d/${id}`);
        return response.data;
    }
}
