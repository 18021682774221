import React from "react";
import Dashboard from "../../assets/icons/IconHome.png";
import BuscarClientes from "../../assets/icons/BuscarClientes.png";
import Operacoes from "../../assets/icons/Operacoes.png";
import {ROUTES_PATH} from "../RoutesPath";
import {TipoUsuario} from "../../types/TipoUsuario";

export const SidebarData = [
    {
        title: "DASHBOARD",
        icon: <img src={Dashboard} />,
        tiposNecessarios: [TipoUsuario.OUTRO, TipoUsuario.PROFESSOR, TipoUsuario.SECRETARIA, TipoUsuario.ESCOLA],
        link: ROUTES_PATH.HOME,
    },
    {
        title: "Relatório por simulados/jogos",
        tiposNecessarios: [TipoUsuario.OUTRO],
        link: ROUTES_PATH.RELATORIO_POR_PROVA,
    },
    {
        title: "Relatório por simulados/jogos",
        tiposNecessarios: [TipoUsuario.ALUNO],
        link: ROUTES_PATH.RELATORIO_POR_PROVA_ALUNO,
    },
    {
        title: "Ranking",
        tiposNecessarios: [TipoUsuario.OUTRO],
        link: ROUTES_PATH.RANKING,
    },
    {
        title: "Ranking",
        tiposNecessarios: [TipoUsuario.SECRETARIA],
        link: ROUTES_PATH.RANKING_SECRETARIA,
    },
    {
        title: "Ranking",
        tiposNecessarios: [TipoUsuario.ESCOLA],
        link: ROUTES_PATH.RANKING_ESCOLA,
    },
    {
        title: "Ranking",
        tiposNecessarios: [TipoUsuario.PROFESSOR],
        link: ROUTES_PATH.RANKING_PROFESSOR,
    },
    {
        title: "Diretores",
        tiposNecessarios: [TipoUsuario.SECRETARIA],
        icon: <img src={BuscarClientes} />,
        link: ROUTES_PATH.DIRETORES,
    },
    {
        title: "Turmas",
        tiposNecessarios: [TipoUsuario.ESCOLA],
        link: ROUTES_PATH.TURMAS,
    },
    {
        title: "Escolas",
        icon: <img src={Dashboard} />,
        tiposNecessarios: [TipoUsuario.SECRETARIA, TipoUsuario.OUTRO],
        link: ROUTES_PATH.LISTAR_ESCOLAS,
    },
    {
        title: "Relatório por grupo",
        tiposNecessarios: [TipoUsuario.OUTRO],
        link: ROUTES_PATH.RELATORIO_POR_TURMA,
    },
    {
        title: "Relatório por grupo",
        tiposNecessarios: [TipoUsuario.SECRETARIA],
        link: ROUTES_PATH.RELATORIO_POR_TURMA_SECRETARIA,
    },
    {
        title: "Relatório por grupo",
        tiposNecessarios: [TipoUsuario.PROFESSOR],
        link: ROUTES_PATH.RELATORIO_POR_TURMA_PROFESSOR,
    },
    {
        title: "Relatório por grupo",
        tiposNecessarios: [TipoUsuario.ESCOLA],
        link: ROUTES_PATH.RELATORIO_POR_TURMA_ESCOLA,
    },
    {
        title: "Secretarias",
        tiposNecessarios: [TipoUsuario.OUTRO],
        link: ROUTES_PATH.SECRETARIA,
    },
    {
        title: "USUÁRIOS",
        icon: <img src={BuscarClientes} />,
        tiposNecessarios: [TipoUsuario.OUTRO],
        link: ROUTES_PATH.USUARIOS,
    },
    {
        title: "Alunos",
        icon: <img src={BuscarClientes} />,
        tiposNecessarios: [TipoUsuario.ESCOLA, TipoUsuario.PROFESSOR],
        link: ROUTES_PATH.ALUNOS,
    },
    {
        title: "Professores",
        link: ROUTES_PATH.PROFESSORES,
        tiposNecessarios: [TipoUsuario.ESCOLA],
    },
    {
        title: "LIVROS",
        icon: <img src={Operacoes} />,
        tiposNecessarios: [TipoUsuario.OUTRO],
        link: ROUTES_PATH.LIVROS,
    },
    {
        title: "Realidade aumentada",
        link: ROUTES_PATH.REALIDADE_AUMENTADA,
        tiposNecessarios: [TipoUsuario.OUTRO],
    },
    /*{
        title: "Realidade aumentada",
        link: ROUTES_PATH.REALIDADE_AUMENTADA_ALUNO,
        tiposNecessarios: [TipoUsuario.ALUNO, TipoUsuario.PROFESSOR],
    },*/
    {
        title: "Jogos",
        link: ROUTES_PATH.JOGOS_ALUNO,
        tiposNecessarios: [TipoUsuario.ALUNO, TipoUsuario.PROFESSOR],
    },
    {
        title: "Jogos",
        link: '/jogos',
        tiposNecessarios: [TipoUsuario.OUTRO],
    },
    {
        title: "Simulados",
        link: ROUTES_PATH.SIMULADOS,
        tiposNecessarios: [TipoUsuario.OUTRO, TipoUsuario.PROFESSOR],
    },
    {
        title: "Recuperação",
        link: ROUTES_PATH.RECUPERACAO,
        tiposNecessarios: [TipoUsuario.PROFESSOR],
    },
    {
        title: "Recuperação",
        link: ROUTES_PATH.RECUPERACAO_ALUNO,
        tiposNecessarios: [TipoUsuario.ALUNO],
    },
    {
        title: "Simulados",
        link: ROUTES_PATH.SIMULADOS_ALUNO,
        tiposNecessarios: [TipoUsuario.ALUNO],
    },
    {
        title: "Meus livros",
        link: ROUTES_PATH.MEUS_LIVROS_PROFESSOR,
        tiposNecessarios: [TipoUsuario.PROFESSOR, TipoUsuario.ALUNO],
    },
    {
        title: "Corrigir questões",
        link: ROUTES_PATH.CORRECAO,
        tiposNecessarios: [TipoUsuario.PROFESSOR],
    },
    {
        title: "sair",
        link: "/sair",
        tiposNecessarios: [TipoUsuario.OUTRO, TipoUsuario.PROFESSOR, TipoUsuario.ALUNO, TipoUsuario.SECRETARIA, TipoUsuario.ESCOLA],
    },
];
