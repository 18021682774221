/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { ListarLivros } from "./ListarLivros";
import { ROUTES_PATH } from "../RoutesPath";
import { ColumnHeader } from "../TabelaBase/TabelaBase";
import { useGrowApi } from "../../hooks/useGrowApi";
import { useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { FindAllParams } from "../../types/FindAllParams";
import { REALIZANDO_PROCESSAMENTO } from "../../redux/actions/realizandoProcessamento.actions";
import { TratamentoErroPadrao } from "../../utils/TratamentoErroPadrao";
import { Livro } from "../../types/Livro";

export const MeusLivrosContainer = () => {
  const [livros, setLivros] = useState<Livro[]>([]);
  const [qtdPag, setQtdPag] = useState<number>(0);
  const [pesquisa, setPesquisa] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const api = useGrowApi();
  const dispatch = useDispatch();
  const alert = useAlert();
  const headers: ColumnHeader[] = [
    {
      label: "ID",
      columnName: "id",
    },
    {
      label: "Nome",
      columnName: "name",
    },
    {
      label: "Baixar Livro",
      columnName: "destinationUrl",
      render: (url) => <a href={url}>Baixar Livro</a>,
    },
    {
      label: "Visualizar Livro",
      columnName: "id",
      render: (bookId) => (
        <a href={`/book/render/${bookId}`}>Visualizar livro</a>
      ),
    },
  ];

  const buscaDados = useCallback(
    async (dados?: FindAllParams) => {
      try {
        dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
        const response = await api.Livro.meusLivros(dados);
        setQtdPag(response.data.count);
        setLivros(response.data.rows);
      } catch (e: any) {
        TratamentoErroPadrao(e, alert);
      } finally {
        dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
      }
    },
    [page]
  );

  useEffect(() => {
    buscaDados({
      page,
      search: pesquisa,
    });
  }, [page, pesquisa]);

  return (
    <>
      <ListarLivros
        rows={livros}
        cadastrarNovoLink={ROUTES_PATH.CADASTRA_LIVROS}
        qtdPaginas={qtdPag}
        pesquisar={(pesquisa) => setPesquisa(pesquisa)}
        onPageChange={(page) => setPage(page)}
        headers={headers}
      />
    </>
  );
};
