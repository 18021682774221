export function JuntarDoisArraysEliminandoIguais<T>(
    array1: T[],
    array2: T[],
): T[] {
    const indexes: number[] = [];
    array2?.map((item: any) => {
        if (array1.filter((x: any) => x.id === item.id).length > 0) {
            const index = array2.indexOf(item);
            indexes.push(index);
        }
    });

    const valoresDiferentes: any[] = [];
    array2?.map((item, index) => {
        if (!indexes.includes(index)) {
            valoresDiferentes.push(item);
        }
    });

    return array1.concat(valoresDiferentes);
}
