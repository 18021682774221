import React, {useCallback, useEffect, useState} from 'react'
import {ListarEscolas} from "./ListarEscolas";
import {ROUTES_PATH} from "../RoutesPath";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useDispatch} from "react-redux";
import {useAlert} from "react-alert";
import {useNavigate} from "react-router-dom";
import {ColumnHeader} from "../TabelaBase/TabelaBase";
import {BotaoAcoes} from "../Botoes/BotaoAcoes";
import {FindAllParams} from "../../types/FindAllParams";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {Escola} from "../../types/Escola";
import {MenuItem} from "@material-ui/core";

export const ListarEscolasContainer = () => {
    const [dados, setDados] = useState<Escola[]>([])
    const [qtdPag, setQtdPag] = useState<number>(0)
    const [pesquisa, setPesquisa] = useState<string>('')
    const [page, setPage] = useState<number>(1)
    const api = useGrowApi();
    const dispatch = useDispatch()
    const alert = useAlert()
    const navigate = useNavigate()
    const headers: ColumnHeader[] = [
        {
            label: '#',
            columnName: 'id'
        },
        {
            label: 'Nome',
            columnName: 'nome'
        },
        {
            label: 'Secretaria',
            columnName: 'secretaria.nome'
        },
        {
            label: 'Ações',
            columnName: 'id',
            render: (id) => <BotaoAcoes
                onBotaoEditarClicado={() => navigate(`/escola/editar/${id}`)}
                onBotaoExcluirClicado={async () => {
                    await handleExcluir(id)
                }}
                linksExtras={
                    <MenuItem
                        onClick={() => {
                            navigate(`/escola/trumas/${id}`)
                        }}
                    >
                        Editar turmas
                    </MenuItem>
                }
            />
        }
    ]
    const buscaDados = useCallback(
        async (dados?: FindAllParams) => {
            try {
                dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
                const response = await api.Escola.buscarTodos(dados);
                setQtdPag(response.data.count)
                setDados(response.data.rows)
            } catch (e: any) {
                TratamentoErroPadrao(e, alert);
            } finally {
                dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
            }
        }, [page])
    const handleExcluir = useCallback(async (id: number) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Escola.excluir(id)
            await buscaDados({page, search: pesquisa})
            alert.success(response.message, {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [page, pesquisa])
    useEffect(() => {
        buscaDados({
            page,
            search: pesquisa
        })
    }, [page, pesquisa])
    return <>
        <ListarEscolas
            rows={dados}
            pesquisar={(pesquisa: any) => setPesquisa(pesquisa)}
            onPageChange={(page: any) => setPage(page)}
            cadastrarNovoLink={ROUTES_PATH.CADASTRAR_ESCOLA}
            headers={headers}
            qtdPaginas={qtdPag}
        />
    </>
}
