import React from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import {CancelButton} from "../Botoes/BotaoAcoes";
import {CadastrarTurmaContainer} from "../Turma/CadastrarTurmaContainer";

type Props = {
    open: boolean
    onClose: () => void
    escolaId: number
}

export const ModalAdicionarTurma = (props: Props) => {
    const handleClose = () => {
        props.onClose()
    }
    return <>
        <Dialog
            fullWidth
            maxWidth="md"
            open={props.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Adicionar alunos
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <CadastrarTurmaContainer escolaId={props.escolaId} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleClose}>Fechar</CancelButton>
            </DialogActions>
        </Dialog>
    </>
}
