import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch} from "react-redux";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useAlert} from "react-alert";
import {Livro} from "../../types/Livro";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {FormularioSimulados} from "./FormularioSimulados";
import {Prova} from "../../types/Simulado";
import {useParams} from "react-router-dom";

export const ClonarSimuladoContainer = () => {
    const dispatch = useDispatch()
    const api = useGrowApi()
    const alert = useAlert()
    const {id} = useParams<{id: string}>()
    const [livros, setLivros] = useState<Livro[]>([])
    const [prova, setProva] = useState<Prova>({
        book: {} as Livro
    } as Prova)
    const buscaLivros = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Livro.buscarTodos();
            setLivros(response.data.rows)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [api, dispatch, alert])
    const buscarSimulado = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Prova.buscarUm(Number(id));
            setProva({
                ...response.data,
                id: undefined,
                nome: `${response.data.nome}(1)`,
                simulados: response.data.simulados.map(s => {
                    (s.id as any) = undefined
                    return s;
                })
            } as any)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [api, dispatch, alert, setProva, id])
    const cadastrar = async (dados: Prova) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const {simulados, ...complemento} = dados
            const response = await api.Prova.cadastrar({...complemento, id: undefined} as any)
            for (const simulado of simulados) {
                const novo = await api.Simulado.cadastrar({
                    ...simulado,
                    provaId: response.data.id
                });
                if (simulado.titulo) {
                    const formData = new FormData();
                    formData.append('titulo', simulado.titulo);
                    if (simulado.fileTitulo) {
                        formData.append('file', simulado.fileTitulo);
                    }
                    await api.Simulado.cadastrarTitulo(formData, Number(novo?.data?.id ?? 0));
                }
                alert.success(response.message, {
                    timeout: 4000
                })
            }
            await api.Prova.montarLivro(response.data.id)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }
    useEffect(() => {
        buscaLivros()
        buscarSimulado()
    }, [])
    return <>
        <FormularioSimulados
            initialValues={prova}
            onSubmit={cadastrar}
            resetarForm={false}
            titulo="Clonar simulado"
            livros={livros}
        />
    </>
}
