import axios, { AxiosInstance } from 'axios';
import { RootState } from '../redux/RootState';
import { store } from '../redux/store';
import { useGrowApi } from '../hooks/useGrowApi';
import { USUARIO_LOGADO } from '../redux/actions/usuarioLogado.actions';

let usuarioLogado: RootState['usuarioLogado'];
store.subscribe(() => {
	usuarioLogado = store.getState().usuarioLogado;
});

export class BaseApi {
	protected api: AxiosInstance;
	constructor() {
		this.api = axios.create({
			baseURL: process.env.REACT_APP_API_URL,
			headers: {
				Accept: 'application/json',
				Content: 'application/json',
			},
		});

		this.api.interceptors.request.use(
			(config): any => {
				if (usuarioLogado?.access_token) {
					if (config.headers) {
						config.headers.Authorization = `Bearer ${usuarioLogado.access_token}`;
					}
				}
				return config;
			},
			(error) => {
				return Promise.reject(error);
			}
		);

		this.api.interceptors.response.use(
			(response): any => response,
			async (error) => {
				const originalReq = error.config;
				const api = useGrowApi();
				if (
					error.response.status === 401 &&
					!error.response.data.message.includes('E-mail e/ou senha inválidos')
				) {
					const response = await api.Login.refresh({
						refresh_token: usuarioLogado?.refresh_token ?? '',
						userId: Number(usuarioLogado?.id) ?? 0,
					});
					if (response?.access_token) {
						store.dispatch({
							type: USUARIO_LOGADO.LOGOU,
							access_token: response.access_token,
							refresh_token: usuarioLogado.refresh_token,
							nome: response.nome,
							id: response.id,
						} as RootState['usuarioLogado'] & { type: string });
						originalReq.headers[
							'Authorization'
						] = `Bearer ${response.access_token}`;
						return axios(originalReq);
					}
					return Promise.reject(error);
				}
				return Promise.reject(error);
			}
		);
	}
}
