import React from 'react'
import {Container, Grid, TextField} from "@material-ui/core";
import {Formik, FormikState} from "formik";
import {GrupoAlunos} from "../../types/GrupoAlunos";
import * as Yup from 'yup'
import styled from "styled-components";
import {CancelButton, ConfirmButton} from "../Botoes/BotaoAcoes";
import {useNavigate} from "react-router-dom";
import {ROUTES_PATH} from "../RoutesPath";

export const StyledContainer = styled(Container)`
  margin-top: 1em;
  background: #FFF;
  max-height: 95vh;
  overflow-y: auto;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(92, 92, 92, 1);
  -moz-box-shadow: 0px 1px 3px 0px rgba(92, 92, 92, 1);
  box-shadow: 0px 1px 3px 0px rgba(92, 92, 92, 1);
`

const FormContainer = styled(Grid)`
  border: 1px solid rgba(0,0,0,0.2);
  padding: 10px;
  margin-top: 10px;
  max-height: 70vh;
`

export type FormularioGrupoAlunosProps = {
    initialValues: GrupoAlunos
    onSubmit: (values: GrupoAlunos) => void;
    resetarForm?: boolean
    editar?: boolean
}

export const FormularioGrupoAlunos = (props: FormularioGrupoAlunosProps) => {
    const navigate = useNavigate()
    const onSubmit = async (
        values: GrupoAlunos,
        {
            resetForm,
            setStatus,
            setErrors,
        }: {
            resetForm: (
                nextState?: Partial<FormikState<GrupoAlunos>> | undefined
            ) => void;
            setStatus: any;
            setErrors: any;
        }
    ) => {
        try {
            await props.onSubmit(values);
            if (props.resetarForm) {
                resetForm();
            }
        } catch (error: any) {
            setStatus({ success: false });
            setErrors({ submit: error.message });
        }
    };
    const schema = Yup.object().shape({
        nome: Yup.string().required('Campo obrigatório.'),
    });
    return <>
        <Formik
            initialValues={props.initialValues}
            enableReinitialize
            validationSchema={schema}
            onSubmit={onSubmit}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
              }) => (
                  <StyledContainer>
                      <h1>{props.editar ? 'Editar grupos de acesso' : 'Cadastrar grupos de acesso'}</h1>
                    <form onSubmit={handleSubmit}>
                        <Container>
                            <Grid container spacing={2}>
                                <Grid item sm={12} xs={12}>
                                    <TextField
                                        label="Nome"
                                        variant="standard"
                                        id="nome"
                                        name="nome"
                                        value={values.nome}
                                        onChange={handleChange}
                                        fullWidth
                                        error={!!errors.nome && touched.nome}
                                        helperText={touched.nome && errors.nome ? errors.nome : ''}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4} style={{marginTop: "10px"}}>
                                    <ConfirmButton
                                        type="submit"
                                        fullWidth
                                    >
                                        Salvar
                                    </ConfirmButton>
                                </Grid>
                                <Grid item xs={4} style={{marginTop: "10px"}}>
                                    <CancelButton
                                        type="submit"
                                        fullWidth
                                        onClick={() => navigate(ROUTES_PATH.GRUPOS_ALUNO)}
                                    >
                                        Cancelar
                                    </CancelButton>
                                </Grid>
                            </Grid>
                        </Container>
                    </form>
                  </StyledContainer>
            )}
        </Formik>
    </>
}
