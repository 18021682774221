import React, {useCallback, useEffect, useState} from 'react'
import {ROUTES_PATH} from "../RoutesPath";
import {ColumnHeader} from "../TabelaBase/TabelaBase";
import {BotaoAcoes} from "../Botoes/BotaoAcoes";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useDispatch} from "react-redux";
import {useAlert} from "react-alert";
import {useNavigate} from "react-router-dom";
import {FindAllParams} from "../../types/FindAllParams";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {ListarRecuperacao} from "./ListarRecuperacao";
import {Recuperacao} from "../../types/Recuperacao";
import moment from "moment";
import {MenuItem} from "@material-ui/core";

type ListarSimuladosContainerProps = {
    aluno?: boolean
}

export const ListarRecuperacaoContainer = (props: ListarSimuladosContainerProps) => {
    const [provas, setProvas] = useState<Recuperacao[]>([])
    const [qtdPag, setQtdPag] = useState<number>(0)
    const [pesquisa, setPesquisa] = useState<string>('')
    const [page, setPage] = useState<number>(1)
    const api = useGrowApi();
    const dispatch = useDispatch()
    const alert = useAlert()
    const navigate = useNavigate()
    const headers: ColumnHeader[] = [
       {
            label: '#',
            columnName: 'id'
        },
        {
            label: 'Nome',
            columnName: 'nome',
        },
        {
            label: 'Professor',
            columnName: 'criador.name',
            render: value => value ?? ''
        },
        {
            label: 'Ações',
            columnName: 'id',
            render: (id) => <BotaoAcoes
                onBotaoEditarClicado={() => navigate(`/recuperacao/editar/${id}`)}
                onBotaoExcluirClicado={async () => {
                    await handleExcluir(id)
                }}
                esconderEditar={true}
                esconderExcluir={props.aluno}
                linksExtras={
                <>
                    {
                        props.aluno && <MenuItem
                            onClick={() => {
                                navigate(`/recuperacao/resolver/${id}`)
                            }}
                        >
                            Fazer recuperação
                        </MenuItem>
                    }
                </>
            }
            />
        }
    ]
    const buscaDados = useCallback(
        async (dados?: FindAllParams) => {
            try {
                dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
                const response = props.aluno ? await api.Recuperacao.buscarTodosAluno(dados) : await api.Recuperacao.buscarTodos(dados);
                setQtdPag(response.data.count)
                setProvas(response.data.rows)
            } catch (e: any) {
                TratamentoErroPadrao(e, alert);
            } finally {
                dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
            }
        }, [page])
    const handleExcluir = useCallback(async (id: number) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Recuperacao.excluir(id)
            await buscaDados({page, search: pesquisa})
            alert.success(response.message, {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [page, pesquisa])
    useEffect(() => {
        buscaDados({
            page,
            search: pesquisa
        })
    }, [page, pesquisa])
    return <>
        <ListarRecuperacao
            esconderAdicionar={props.aluno}
            rows={provas}
            cadastrarNovoLink={ROUTES_PATH.CADASTRAR_RECUPERACAO}
            qtdPaginas={qtdPag}
            pesquisar={(pesquisa) => setPesquisa(pesquisa)}
            onPageChange={(page) => setPage(page)}
            headers={headers}
        />
    </>
}
