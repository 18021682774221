import React from 'react'
import {TabelaBase, TabelaBaseProps} from "../TabelaBase/TabelaBase";
import {UsuarioType} from "../../types/Usuario";

interface UsuarioProps extends TabelaBaseProps<UsuarioType> {
}

export const Usuario = (props: UsuarioProps) => {
    return <>
        <TabelaBase
            esconderAdicionar={props.esconderAdicionar}
            extra={props.extra}
            qtdPaginas={props.qtdPaginas}
            rows={props.rows}
            pesquisar={props.pesquisar}
            cadastrarNovoLink={props.cadastrarNovoLink}
            onPageChange={props.onPageChange}
            headers={props.headers}
        />
    </>
}
