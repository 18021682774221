export enum ROUTES_PATH {
	SEM_PERMISSAO = '/sem-permissao',
	HOME = '/',
	DIRETORES = '/diretores',
	TURMAS = '/turmas',
	RELATORIO_POR_PROVA = '/relatorio/prova',
	RELATORIO_POR_PROVA_ALUNO = '/relatorio/prova/aluno',
	RANKING = '/ranking',
	RANKING_SECRETARIA = '/ranking/secretaria',
	RANKING_ESCOLA = '/ranking/escola',
	RANKING_PROFESSOR = '/ranking/professor',
	LISTAR_ESCOLAS = "/escola",
	RELATORIO_POR_TURMA = '/relatorio/turma',
	RELATORIO_POR_TURMA_SECRETARIA = '/secretaria/relatorio/turma',
	RELATORIO_POR_TURMA_PROFESSOR = '/secretaria/relatorio/professor',
	RELATORIO_POR_TURMA_ESCOLA = '/escola/relatorio/turma',
	SECRETARIA = '/secretarias',
	CADASTRAR_SECRETARIA = '/secretarias/cadastrar',
	CADASTRAR_ESCOLA = '/escola/cadastrar',
	CADASTRAR_TURMA = '/turma/cadastrar',
	EDITAR_ESCOLA = '/escola/editar/:id',
	EDITAR_SECRETARIA = '/secretarias/editar/:id',
	USUARIOS = '/usuario',
	LIVROS = '/livros',
	JOGOS = '/jogos',
	JOGOS_ALUNO = '/jogos/aluno',
	REALIDADE_AUMENTADA_ALUNO = '/realidade-aumentada/aluno',
	CADASTRAR_JOGOS = '/jogos/cadastrar',
	EDITAR_JOGOS = '/jogos/editar/:id',
	REALIDADE_AUMENTADA = '/realidade-aumentada',
	CADASTRAR_REALIDADE_AUMENTADA = '/realidade-aumentada/cadastrar',
	EDITAR_REALIDADE_AUMENTADA = '/realidade-aumentada/editar/:id',
	SIMULADOS = '/simulados',
	RECUPERACAO = '/recuperacao',
	RECUPERACAO_ALUNO = '/recuperacao/aluno',
	SIMULADOS_ALUNO = '/simulados/aluno',
	MEUS_LIVROS_PROFESSOR = '/meus-livros/professor',
	CORRECAO = '/questoes/correcao',
	HOME_PROFESSOR = '/home/professor',
	CADASTRAR_SIMULADOS = '/simulados/cadastrar',
	CADASTRAR_RECUPERACAO = '/recuperacao/cadastrar',
	EDITAR_RECUPERACAO = '/recuperacao/editar/:id',
	EDITAR_SIMULADOS = '/simulados/editar/:id',
	CADASTRA_LIVROS = '/livros/cadastrar',
	EDITAR_LIVROS = '/livros/editar/:id',
	GRUPOS_ACESSO = '/grupo-acesso',
	CADASTRAR_USUARIO='/usuario/cadastrar',
	EDITAR_USUARIO='/usuario/editar/:id',
	CADASTRAR_GRUPOS_ACESSO = '/grupo-acesso/cadastrar',
	CADASTRAR_GRUPOS_ALUNO = '/grupo-alunos/cadastrar',
	EDITAR_GRUPOS_ACESSO = '/grupo-acesso/editar/:id',
	EDITAR_GRUPOS_ALUNO = '/grupo-aluno/editar/:id',
	GRUPOS_ALUNO = '/grupo-aluno',
	ALUNOS = '/alunos',
	PROFESSORES = '/professores',
	CADASTRAR_ALUNO = '/alunos/cadastrar',
	CADASTRAR_PROFESSOR = '/professores/cadastrar',
	CADASTRAR_DIRETOR = '/diretore/cadastrar',
	EDITAR_ALUNO = '/alunos/editar/:id',
	SAIR = '/sair',
	RENDER_LIVRO = '/book/render/:bookId',
	RESOLVER_SIMULADO = '/simulado/resolver/:id',
	CLONAR_SIMULADO = '/livros/clonar/:id'
}
