import React, {useCallback, useState} from 'react'
import {FormularioGrupoAlunos} from "./FormularioGrupoAlunos";
import {GrupoAlunos} from "../../types/GrupoAlunos";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useDispatch} from "react-redux";
import {useAlert} from "react-alert";

export const CadastrarGrupoAlunosContainer = () => {
    const api = useGrowApi();
    const dispatch = useDispatch()
    const alert = useAlert()
    const [grupo, setGrupo] = useState<GrupoAlunos>({
        nome: '',
    })
    const cadastrar = useCallback(async (dados: GrupoAlunos) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.GrupoAlunos.cadastrar(dados);
            setGrupo({} as GrupoAlunos)
            alert.success(response.message, {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [])
    return <>
        <FormularioGrupoAlunos
            resetarForm
            initialValues={grupo}
            onSubmit={cadastrar}
        />
    </>
}
