import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom';
import {Sidebar} from "./Sidebar";
import styled from 'styled-components'

const Content = styled.div`
  overflow: hidden;
`

export const Menu = () => {
    return <>
        <Router>
            <Content>
                <Sidebar />
            </Content>
        </Router>
    </>
}
