import React, {useEffect, useState} from "react";
import { StyledContainer } from "../TabelaBase/TabelaBase";
import {
    Box,
    Button, Checkbox,
    Container, Divider, FormControlLabel,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core";
import { Formik, FormikState } from "formik";
import { ConfirmButton } from "../Botoes/BotaoAcoes";
import { Autocomplete } from "@material-ui/lab";
import styled from "styled-components";
import {Secretaria} from "../../types/Secretaria";
import {Escola} from "../../types/Escola";
import {Turma} from "../../types/Turma";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {useDispatch, useSelector} from "react-redux";
import {useAlert} from "react-alert";
import {useGrowApi} from "../../hooks/useGrowApi";
import {RootState} from "../../redux/RootState";
import {JuntarDoisArraysEliminandoIguais} from "../../utils/JuntarArraysEliminandoIguais";
import {RankingResponse} from "../../types/RankingResponse";
import {v4 as uuid} from 'uuid'


export type RelatorioRankingValues = {
    turmas: Turma[];
    secretaria: Secretaria;
    escolas: Escola[];
    materias: string[];
    dataInicial: string | Date;
    dataFinal: string | Date;
};

type RelatorioPorTurmaProps = {
    initialValues: RelatorioRankingValues;
    onSubmit: (values: RelatorioRankingValues) => void;
    secretarias: Secretaria[];
    dados: RankingResponse[];
    secretaria?: boolean
    escola?: boolean
    professor?: boolean
};

const materias = [
    'Língua portuguesa',
    'Artes',
    'Ed. Física',
    'Língua inglesa',
    'Matemática',
    'Ciências',
    'Geografia',
    'História'
]

export const Box2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 30px;
  grid-column-gap: 10px;
  margin-left: 25px;
  margin-top: 25px;
`

export const Ranking = (props: RelatorioPorTurmaProps) => {
    const [rerender, setRerender] = useState<string>(uuid())
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const dispatch = useDispatch()
    const alert = useAlert()
    const api = useGrowApi()
    const usuarioLogado = useSelector((state: RootState) => state.usuarioLogado);
    const [escolas, setEscolas] = useState<Escola[]>([])
    const [turmas, setTurmas] = useState<Turma[]>([])
    const onSubmit = async (
        values: RelatorioRankingValues,
        {
            setStatus,
            setErrors,
        }: {
            resetForm: (nextState?: Partial<FormikState<any>> | undefined) => void;
            setStatus: any;
            setErrors: any;
        }
    ) => {
        try {
            await props.onSubmit(values);
        } catch (error: any) {
            setStatus({ success: false });
            setErrors({ submit: error.message });
        }
    };

    const obterEscolas = async (secretariaId: number) => {
        try {
            dispatch({type: REALIZANDO_PROCESSAMENTO.ATIVO});
            if (secretariaId !== 0 ) {
                const response = await api.Escola.getBySecretariaId(secretariaId)
                setEscolas(response.data);
                return;
            }
            setEscolas([])
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({type: REALIZANDO_PROCESSAMENTO.NENHUM});
        }
    }

    const obterTurmas = async (escolas: Escola[]) => {
        try {
            dispatch({type: REALIZANDO_PROCESSAMENTO.ATIVO});
            const dados: Turma[] = []
            if (escolas.length > 0) {
                for (const escola of escolas) {
                    const response = await api.Turma.porEscola(escola?.id)
                    for (const dado of response.data) {
                        dados.push(dado);
                    }
                }
                setTurmas(dados)
                return;
            }
            setTurmas([])
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({type: REALIZANDO_PROCESSAMENTO.NENHUM});
        }
    }


    const obterTurmasPorPorfessor = async () => {
        try {
            dispatch({type: REALIZANDO_PROCESSAMENTO.ATIVO});
            const response = await api.Turma.porProfessor(Number(usuarioLogado?.id ?? 0))
            setTurmas(response.data);
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({type: REALIZANDO_PROCESSAMENTO.NENHUM});
        }
    }

    const TurmasPorAno = (ano: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void, values: any) => {
        const dados = turmas.filter(t => t.serie === ano)
        const novoArray = JuntarDoisArraysEliminandoIguais<Turma>(dados, values.turmas ?? [])
        setFieldValue('turmas', novoArray)
        setRerender(prev => prev + 1)
    }

    const obterTurmasPorMateria = async (materias: string[], escolas: Escola[]) => {
        try {
            dispatch({type: REALIZANDO_PROCESSAMENTO.ATIVO});
            const dados: Turma[] = []
            if (materias.length > 0) {
                for (const materia of materias) {
                    if (props.escola) {
                        const response = await api.Turma.porMateria2(materia, usuarioLogado?.escolaId ?? 0)
                        for (const dado of response.data) {
                            dados.push(dado);
                        }
                    }
                    if (props.professor) {
                        const response = await api.Turma.porMateria3(materia, Number(usuarioLogado?.id ?? 0))
                        for (const dado of response.data) {
                            dados.push(dado);
                        }
                    }
                    for (const escola of escolas ?? []) {
                        const response = await api.Turma.porMateria(materia, usuarioLogado?.secretariaId ?? 0, escola?.id)
                        for (const dado of response.data) {
                            dados.push(dado);
                        }
                    }
                }
                setTurmas(dados)
                return;
            }
            setTurmas([])
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({type: REALIZANDO_PROCESSAMENTO.NENHUM});
        }
    }
    const handleToggleSelectAll = (setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        setSelectAll(prev => {
            if (!prev) {
                setFieldValue('turmas', turmas)
            } else {
                setFieldValue('turmas', [])
            }
            setRerender(prev => prev + 1)
            return !prev
        })
    };
    useEffect(() => {
        if (props.secretaria) {
            obterEscolas(usuarioLogado?.secretariaId ?? 0)
        }
    }, [props.secretaria, usuarioLogado?.secretariaId])

    useEffect(() => {
        if (props.professor) {
            obterTurmasPorPorfessor()
        }
    }, [props.professor])
    return (
        <>
            <Formik
                initialValues={props.initialValues}
                enableReinitialize
                onSubmit={onSubmit}
            >
                {({ values, handleChange, handleSubmit, setFieldValue }) => (
                    <StyledContainer>
                        <form
                            onSubmit={handleSubmit}
                            style={{ marginTop: "25px", marginBottom: "25px" }}
                        >
                            <Container>
                                <Grid container spacing={2}>
                                    {!props.secretaria && !props.escola && !props.professor && <Grid item sm={4} xs={12}>
                                        <Autocomplete
                                            id="secretarias"
                                            value={values.secretaria}
                                            options={props.secretarias}
                                            getOptionLabel={(option) => option.nome ?? ""}
                                            onChange={(e, value) => {
                                                setFieldValue("secretaria", value)
                                                obterEscolas(value?.id ?? 0)
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Secretaria"
                                                />
                                            )}
                                        />
                                    </Grid>}
                                    {!props.escola && !props.professor && <Grid item sm={4} xs={12}>
                                        <Autocomplete
                                            id="escolas"
                                            value={values.escolas}
                                            multiple
                                            options={escolas}
                                            getOptionLabel={(option) => option.nome ?? ""}
                                            onChange={(e, value) => {
                                                setFieldValue("escolas", value)
                                                obterTurmas(value)
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Escola"
                                                />
                                            )}
                                        />
                                    </Grid>}
                                    {(props.secretaria || props.escola || props.professor) &&<Grid item sm={4} xs={12}>
                                        <Autocomplete
                                            id="materias"
                                            value={values.materias}
                                            multiple
                                            options={materias}
                                            getOptionLabel={(option) => option ?? ""}
                                            onChange={(e, value) => {
                                                setFieldValue("materias", value)
                                                obterTurmasPorMateria(value, values.escolas)
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Matérias"
                                                />
                                            )}
                                        />
                                    </Grid>}
                                    <Grid item sm={4} xs={12}>
                                        <Autocomplete
                                            id="turmas"
                                            value={values.turmas}
                                            key={rerender}
                                            multiple
                                            options={turmas}
                                            getOptionLabel={(option) => option.nome ?? ""}
                                            onChange={(e, value) => setFieldValue("turmas", value)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Turma"
                                                />
                                            )}
                                            PaperComponent={(paperProps) => {
                                                const {children, ...restPaperProps} = paperProps;
                                                return (
                                                    <Paper {...restPaperProps}>
                                                        <Box
                                                            onMouseDown={(e) => e.preventDefault()} // prevent blur
                                                            pl={1.5}
                                                            py={0.5}
                                                        >
                                                            <FormControlLabel
                                                                onClick={(e) => {
                                                                    e.preventDefault(); // prevent blur
                                                                    handleToggleSelectAll(setFieldValue);
                                                                }}
                                                                label="Todas as turmas"
                                                                control={
                                                                    <Checkbox id="select-all-checkbox" checked={selectAll}/>
                                                                }
                                                            />
                                                        </Box>
                                                        <Divider/>
                                                        {children}
                                                    </Paper>
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={4}>
                                        <TextField
                                            label="Data inicial"
                                            type="date"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="dataInicial"
                                            name="dataInicial"
                                            value={values.dataInicial}
                                            onChange={handleChange}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={4}>
                                        <TextField
                                            label="Data final"
                                            type="date"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="dataFinal"
                                            name="dataFinal"
                                            value={values.dataFinal}
                                            onChange={handleChange}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={4} style={{ marginTop: "10px" }}>
                                        <ConfirmButton type="submit" fullWidth>
                                            Gerar relatório
                                        </ConfirmButton>
                                    </Grid>
                                </Grid>
                            </Container>
                            {(props.secretaria || props.escola) && values.materias && values.materias.length > 0 && <>
                                <h3 style={{marginLeft: '25px'}}>Turmas</h3>
                                <Box2>
                                    <React.Fragment>
                                        <Button component="span"
                                                onClick={() => {
                                                    TurmasPorAno('Primeiro Ano', setFieldValue, values)
                                                }}
                                                variant="contained"
                                                color="primary"
                                        >
                                            Todas 1° ano
                                        </Button>
                                        <Button
                                            component="span"
                                            variant="contained"
                                            onClick={() => {
                                                TurmasPorAno('Segundo Ano', setFieldValue, values)
                                            }}
                                            color="primary"
                                        >
                                            Todas 2° ano
                                        </Button>
                                        <Button
                                            component="span"
                                            variant="contained"
                                            onClick={() => {
                                                TurmasPorAno('Terceiro Ano', setFieldValue, values)
                                            }}
                                            color="primary"
                                        >
                                            Todas 3° ano
                                        </Button>
                                        <Button
                                            component="span"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                TurmasPorAno('Quarto Ano', setFieldValue, values)
                                            }}
                                        >
                                            Todas 4° ano
                                        </Button>
                                        <Button
                                            component="span"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                TurmasPorAno('Quinto Ano', setFieldValue, values)
                                            }}
                                        >
                                            Todas 5° ano
                                        </Button>
                                        <Button
                                            component="span"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                TurmasPorAno('Sexto Ano', setFieldValue, values)
                                            }}
                                        >
                                            Todas 6° ano
                                        </Button>
                                        <Button
                                            component="span"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                TurmasPorAno('Sétimo Ano', setFieldValue, values)
                                            }}
                                        >
                                            Todas 7° ano
                                        </Button>
                                        <Button
                                            component="span"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                TurmasPorAno('Oitavo Ano', setFieldValue, values)
                                            }}
                                        >
                                            Todas 8° ano
                                        </Button>
                                        <Button
                                            component="span"
                                            variant="contained"
                                            onClick={() => {
                                                TurmasPorAno('Nono Ano', setFieldValue, values)
                                            }}
                                            color="primary"
                                        >
                                            Todas 9° ano
                                        </Button>
                                    </React.Fragment>
                                </Box2>
                            </>}
                        </form>
                        <TableContainer component={Paper}>
                            <Table size="medium">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>Secretaria</TableCell>
                                        {(values.secretaria || props.secretaria) && <TableCell>Escola</TableCell>}
                                        {((values?.escolas && values?.escolas?.length > 0) || props?.escola || props?.professor) && <TableCell>Turma</TableCell>}
                                        {(props.professor && values.turmas && values.turmas.length > 0) && <TableCell>Aluno</TableCell>}
                                        <TableCell>Número de acertos</TableCell>
                                        <TableCell>Número de erros</TableCell>
                                        <TableCell>Tentativas</TableCell>
                                        <TableCell>Taxa de sucesso</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.dados.map((d, key) => (
                                        <TableRow>
                                            <TableCell>{key + 1}º</TableCell>
                                            <TableCell> {d?.secretaria_nome}</TableCell>
                                            {(values.secretaria || props.secretaria) && <TableCell>{d?.escola_nome}</TableCell>}
                                            {((values?.escolas && values?.escolas?.length > 0) || props?.escola || props?.professor) && <TableCell>{d?.grupoAlunos_nome}</TableCell>}
                                            {(props.professor && values.turmas && values.turmas.length > 0) && <TableCell>{d?.professores_name}</TableCell>}
                                            <TableCell>{d?.totalNumeroAcertos}</TableCell>
                                            <TableCell>{d?.totalNumeroErros}</TableCell>
                                            <TableCell>{d?.totalNumeroTentativas}</TableCell>
                                            <TableCell>{d?.proporcaoAcertos.toFixed(2)}%</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </StyledContainer>
                )}
            </Formik>
        </>
    );
};
