import { BaseApi } from './BaseApi';
import {Paginacao} from "../types/Paginacao";
import {UsuarioType} from "../types/Usuario";
import {FindAllParams} from "../types/FindAllParams";
import {IResponsePadrao} from "../types/IResponsePadrao";
import {Constants} from "../types/Constants";
import {Livro} from "../types/Livro";

export class LivroApi extends BaseApi {

    async preparar(id: string): Promise<void> {
        await this.api.get(`books/renderizar/${id}`)
    }

    async buscarTodos(dados?: FindAllParams): Promise<IResponsePadrao<Paginacao<Livro>>> {
        const response = await this.api.get('/books', {
            params: {
                page: dados?.page,
                take: dados?.page ? Constants.ITEMS_PER_PAGE : undefined,
                search: dados?.search
            }
        });
        return response.data;
    }

    async meusLivros(dados?: FindAllParams): Promise<IResponsePadrao<Paginacao<Livro>>> {
        const response = await this.api.get('/books/my', {
            params: {
                page: dados?.page,
                take: Constants.ITEMS_PER_PAGE,
                search: dados?.search
            }
        });
        return response.data;
    }

    async buscarUm(id: number): Promise<IResponsePadrao<Livro>> {
        const response = await this.api.get(`/books/${id}`);
        return response.data;
    }

    async editar(dados: Livro, id: number): Promise<IResponsePadrao<Livro>> {
        const formData = new FormData();
        formData.append("name", dados?.name ?? '')
        formData.append("file", dados.file!)
        for (let i = 0; i < dados.users.length; i++) {
            formData.append('users[]', String(dados.users[i].id));
        }
        for (let i = 0; i < dados.grupos?.length!; i++) {
            formData.append('grupos[]', String(dados.grupos![i].id));
        }
        return (await this.api.put(`/books/update-file/${id}`, formData)).data;
    }

    async cadastrar(dados: Livro): Promise<IResponsePadrao<Livro>> {
        const formData = new FormData();
        formData.append("name", dados?.name ?? '')
        formData.append("file", dados.file!)
        for (let i = 0; i < dados.turmas?.length!; i++) {
            formData.append('turmas[]', String(dados.turmas![i].id));
        }
        return (await this.api.post(`/books`, formData)).data;
    }

    async excluir(id: number): Promise<IResponsePadrao<UsuarioType>> {
        const response = await this.api.delete(`/books/${id}`);
        return response.data;
    }

    async renderById() {
        const res = await this.api.get(`/`)
        return res.data
    }
}
