import React, {useCallback, useState} from 'react'
import {FormularioGrupoAcesso} from "./FormularioGrupoAcesso";
import {GrupoAcesso} from "../../types/GrupoAcesso";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useDispatch} from "react-redux";
import {useAlert} from "react-alert";

export const CadastrarGrupoAcessoContainer = () => {
    const api = useGrowApi();
    const dispatch = useDispatch()
    const alert = useAlert()
    const [grupo, setGrupo] = useState<GrupoAcesso>({
        name: '',
        permissions: '',
        permissionsArray: []
    })
    const cadastrar = useCallback(async (dados: GrupoAcesso) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.GrupoAcesso.cadastrar(dados);
            setGrupo({} as GrupoAcesso)
            alert.success(response.message, {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [])
    return <>
        <FormularioGrupoAcesso
            resetarForm
            initialValues={grupo}
            onSubmit={cadastrar}
        />
    </>
}
