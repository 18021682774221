import React, { useEffect, useState } from "react";
import { StyledContainer } from "../TabelaBase/TabelaBase";
import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  LinearProgress,
  Typography,
  Box,
  LinearProgressProps,
} from "@material-ui/core";
import { Formik, FormikState } from "formik";
import { ConfirmButton } from "../Botoes/BotaoAcoes";
import { Autocomplete } from "@material-ui/lab";
import { Prova } from "../../types/Simulado";
import { Score } from "../../types/Score";
import { FcCancel, FcApproval } from "react-icons/fc";
import moment from "moment";

export type RelatorioValues = {
  provas: Prova[];
  dataInicial: string | Date;
  dataFinal: string | Date;
};

type RelatorioSimuladoProps = {
  initialValues: RelatorioValues;
  onSubmit: (values: RelatorioValues) => void;
  provas: Prova[];
  dados: Score[];
  aluno?: boolean;
};

export const RelatorioSimulado = (props: RelatorioSimuladoProps) => {
  const [media, setMedia] = useState<number>(0);
  function average(arr: number[]) {
    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
      sum += arr[i];
    }
    return Number(((sum / arr.length) * 100).toFixed(2));
  }
  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  const obterMedia = () => {
    let numbers: number[] = [];
    props.dados.map((d) => {
      numbers.push(d.score);
    });
    let avg = average(numbers);
    setMedia(avg);
  };
  const onSubmit = async (
    values: RelatorioValues,
    {
      setStatus,
      setErrors,
    }: {
      resetForm: (nextState?: Partial<FormikState<any>> | undefined) => void;
      setStatus: any;
      setErrors: any;
    }
  ) => {
    try {
      await props.onSubmit(values);
    } catch (error: any) {
      setStatus({ success: false });
      setErrors({ submit: error.message });
    }
  };
  useEffect(() => {
    obterMedia();
  }, [props.dados]);
  return (
    <>
      <Formik
        initialValues={props.initialValues}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <StyledContainer>
            <form
              onSubmit={handleSubmit}
              style={{ marginTop: "25px", marginBottom: "25px" }}
            >
              <Container>
                <Grid container spacing={2}>
                  <Grid item sm={3} xs={12}>
                    <Autocomplete
                      id="livros"
                      multiple
                      value={values.provas}
                      options={props.provas}
                      getOptionLabel={(option) => option.nome ?? ""}
                      onChange={(e, value) => setFieldValue("provas", value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Prova"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <TextField
                      label="Data inicial"
                      type="date"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      id="dataInicial"
                      name="dataInicial"
                      value={values.dataInicial}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <TextField
                      label="Data final"
                      type="date"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      id="dataFinal"
                      name="dataFinal"
                      value={values.dataFinal}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={3} style={{ marginTop: "10px" }}>
                    <ConfirmButton type="submit" fullWidth>
                      Gerar relatório
                    </ConfirmButton>
                  </Grid>
                </Grid>
              </Container>
            </form>
            <TableContainer component={Paper}>
              <Table size="medium">
                <TableHead>
                  <TableRow>
                    <TableCell>Questão</TableCell>
                    <TableCell>Usuário</TableCell>
                    <TableCell>CPF</TableCell>
                    <TableCell>Resposta certa ?</TableCell>
                    <TableCell>Data</TableCell>
                    <TableCell>Hora</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.dados.map((d) => (
                    <TableRow key={d.id}>
                      <TableCell component="th" scope="row">
                        {d?.simulado?.enunciado}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {d?.user?.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {d?.user?.cpf}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {d?.score === 1 ? (
                          <FcApproval size={40} />
                        ) : (
                          <FcCancel size={40} />
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {moment(d?.createdAt).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        onClick={() => {
                          console.log(d?.createdAt);
                        }}
                      >
                        {moment(d?.createdAt).format("HH:mm:ss")}
                      </TableCell>
                    </TableRow>
                  ))}
                  {props.dados.length > 0 && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Média de acertos
                      </TableCell>
                      <TableCell component="th" colSpan={5} scope="row">
                        <LinearProgressWithLabel value={media} />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledContainer>
        )}
      </Formik>
    </>
  );
};
