import React, {ReactElement, useState} from 'react'
import {
    Container,
    Grid, IconButton, InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField
} from "@material-ui/core";
import styled from 'styled-components'
import {Link} from "react-router-dom";
import {AiOutlineSearch} from 'react-icons/ai'
import {ROUTES_PATH} from "../RoutesPath";
import {Pagination} from "@material-ui/lab";
import {Constants} from "../../types/Constants";

export const StyledContainer = styled(Container)`
  margin-top: 1em;
  background: #FFF;
  max-height: 95vh;
  overflow-y: auto;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(92, 92, 92, 1);
  -moz-box-shadow: 0px 1px 3px 0px rgba(92, 92, 92, 1);
  box-shadow: 0px 1px 3px 0px rgba(92, 92, 92, 1);
`

export const AdicionarNovo = styled(Link)`
  color: #0367a5;
  font-size: 22px;
`

const Header = styled(Grid)`
  margin-bottom: 25px;
  margin-top: 25px;
`

export type ColumnHeader = {
    label: string
    columnName: string
    render?: (value: any) => void
}

export type TabelaBaseProps<T> = {
    rows: T[]
    pesquisar: (pesquisa: string) => void
    cadastrarNovoLink: ROUTES_PATH
    onPageChange: (page: number) => void
    headers: ColumnHeader[]
    qtdPaginas: number
    esconderAdicionar?: boolean
    extra?: ReactElement
    esconderPesquisar?: boolean
}

export const TabelaBase = ({esconderPesquisar, esconderAdicionar, extra, qtdPaginas, rows, pesquisar, onPageChange, cadastrarNovoLink, headers}: TabelaBaseProps<any>) => {
    const [pesquisa, setPesquisa] = useState<string>('')
    return <>
        <StyledContainer>
            <Header container>
                <Grid item sm={12}>
                    {extra}
                </Grid>
                {!esconderAdicionar && <Grid item sm={2}>
                    <AdicionarNovo to={cadastrarNovoLink}>Adicionar Novo</AdicionarNovo>
                </Grid>}
                {!esconderPesquisar && <Grid item sm={esconderAdicionar ? 12 : 10}>
                    <TextField
                        style={{position: "relative", bottom: '18px'}}
                        label="Pesquisar"
                        variant="standard"
                        fullWidth
                        onChange={(event) => setPesquisa(event.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        color="primary"
                                        onClick={() => pesquisar(pesquisa)}
                                    >
                                        <AiOutlineSearch/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>}
            </Header>
            <TableContainer component={Paper}>
                <Table size="medium">
                    <TableHead>
                        <TableRow>
                            {
                                headers.map(h => (
                                    <TableCell>{h.label}</TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.id}
                            >
                                {
                                    headers.map(h => (
                                        <TableCell component="th" scope="row">
                                            {h.render ? h.render(h.columnName.includes('.') ? row[h.columnName.split('.')[0]] ? row[h.columnName.split('.')[0]][h.columnName.split('.')[1]] : 'Não cadastrado' : row[h.columnName]) : h.columnName.includes('.') ? row[h.columnName.split('.')[0]][h.columnName.split('.')[1]] : row[h.columnName]}
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container justify="center" style={{marginTop: "25px"}}>
                <Pagination count={Math.ceil(qtdPaginas / Number(Constants.ITEMS_PER_PAGE))} color="primary" onChange={(e, page) => onPageChange(page)} />
            </Grid>
        </StyledContainer>
    </>
}
