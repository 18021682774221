import {Permissions} from "../types/Permissions";

export type PermissoesDataType = {
    titulo: string
    permissoes: {
        label: string
        value: Permissions,
    }[]
}

export const PermissoesData: PermissoesDataType[] = [
    {
        titulo: 'Usuários',
        permissoes: [
            {
                value: Permissions.LIST_USER,
                label: 'Listar usuários'
            },
            {
                value: Permissions.ADD_USER,
                label: 'Cadastrar usuários'
            },
            {
                value: Permissions.UPDATE_USER,
                label: 'Editar usuários'
            },
            {
                value: Permissions.DELETE_USER,
                label: 'Excluir usuários'
            }
        ]
    },
    {
        titulo: 'Grupos de acesso',
        permissoes: [
            {
                value: Permissions.LIST_SCOPE,
                label: 'Listar Grupos de acesso'
            },
            {
                value: Permissions.ADD_SCOPE,
                label: 'Cadastrar Grupos de acesso'
            },
            {
                value: Permissions.UPDATE_SCOPE,
                label: 'Editar Grupos de acesso'
            },
            {
                value: Permissions.DELETE_SCOPE,
                label: 'Excluir Grupos de acesso'
            }
        ]
    },
    {
        titulo: 'Alunos',
        permissoes: [
            {
                value: Permissions.LIST_STUDENT,
                label: 'Listar Alunos'
            },
            {
                value: Permissions.ADD_STUDENT,
                label: 'Cadastrar Alunos'
            },
            {
                value: Permissions.UPDATE_STUDENT,
                label: 'Editar Alunos'
            },
            {
                value: Permissions.DELETE_STUDENT,
                label: 'Excluir Alunos'
            }
        ]
    },
    {
        titulo: 'Grupos de acesso aos livros',
        permissoes: [
            {
                value: Permissions.LIST_STUDENT_GROUP,
                label: 'Listar Grupos de acesso aos livros'
            },
            {
                value: Permissions.ADD_STUDENT_GROUP,
                label: 'Cadastrar Grupos de acesso aos livros'
            },
            {
                value: Permissions.UPDATE_STUDENT_GROUP,
                label: 'Editar Grupos de acesso aos livros'
            },
            {
                value: Permissions.DELETE_STUDENT_GROUP,
                label: 'Excluir Grupos de acesso aos livros'
            }
        ]
    },
    {
        titulo: 'Livros',
        permissoes: [
            {
                value: Permissions.LIST_BOOK,
                label: 'Listar livros'
            },
            {
                value: Permissions.ADD_BOOK,
                label: 'Cadastrar livros'
            },
            {
                value: Permissions.UPDATE_BOOK,
                label: 'Editar livros'
            },
            {
                value: Permissions.DELETE_BOOK,
                label: 'Excluir livros'
            }
        ]
    },
    {
        titulo: 'Jogos',
        permissoes: [
            {
                value: Permissions.LIST_GAMES,
                label: 'Listar jogos'
            },
            {
                value: Permissions.ADD_GAMES,
                label: 'Cadastrar jogos'
            },
            {
                value: Permissions.UPDATE_GAMES,
                label: 'Editar jogos'
            },
            {
                value: Permissions.DELETE_GAMES,
                label: 'Excluir jogos'
            }
        ]
    },
    {
        titulo: 'Realidade aumentada',
        permissoes: [
            {
                value: Permissions.LIST_3D,
                label: 'Listar realidade aumentada'
            },
            {
                value: Permissions.ADD_3D,
                label: 'Cadastrar realidade aumentada'
            },
            {
                value: Permissions.UPDATE_3D,
                label: 'Editar realidade aumentada'
            },
            {
                value: Permissions.DELETE_3D,
                label: 'Excluir realidade aumentada'
            }
        ]
    },
    {
        titulo: 'Simulados',
        permissoes: [
            {
                value: Permissions.LIST_QUESTIONS,
                label: 'Listar simulados'
            },
            {
                value: Permissions.ADD_QUESTIONS,
                label: 'Cadastrar simulados'
            },
            {
                value: Permissions.UPDATE_QUESTIONS,
                label: 'Editar simulados'
            },
            {
                value: Permissions.DELETE_QUESTIONS,
                label: 'Excluir simulados'
            }
        ]
    },
    {
        titulo: 'Relatórios',
        permissoes: [
            {
                value: Permissions.REPORT_PER_QUESTION,
                label: 'Relatório por simulados/jogos'
            },
            {
                value: Permissions.REPORT_PER_GROUP,
                label: 'Relatório por grupo'
            },
        ]
    },
]
