import { BaseApi } from './BaseApi';
import {Paginacao} from "../types/Paginacao";
import {UsuarioType} from "../types/Usuario";
import {FindAllParams} from "../types/FindAllParams";
import {IResponsePadrao} from "../types/IResponsePadrao";
import {Constants} from "../types/Constants";

export class UsuarioApi extends BaseApi {
    async buscarTodos(dados?: FindAllParams): Promise<IResponsePadrao<Paginacao<UsuarioType>>> {
        const response = await this.api.get('/usuario', {
            params: {
                page: dados?.page,
                take: Constants.ITEMS_PER_PAGE,
                search: dados?.search
            }
        });
        return response.data;
    }

    async buscarAlunosPorEscola(id: number): Promise<IResponsePadrao<UsuarioType[]>> {
        const response = await this.api.get(`/usuario/alunos/escola/${id}`);
        return response.data;
    }

    async buscarAlunosPorTurma(id: number): Promise<IResponsePadrao<UsuarioType[]>> {
        const response = await this.api.get(`/usuario/alunos/turmas/${id}`);
        return response.data;
    }


    async cadastrarAlunoNaTurma(alunoId: number, turmaId: number): Promise<IResponsePadrao<UsuarioType>> {
        const response = await this.api.post(`/professor-turma`, {
            id: String(alunoId),
            turmaId: String(turmaId)
        });
        return response.data;
    }

    async buscarAlunos(dados?: FindAllParams): Promise<IResponsePadrao<Paginacao<UsuarioType>>> {
        const response = await this.api.get('/usuario/alunos', {
            params: {
                page: dados?.page,
                take: Constants.ITEMS_PER_PAGE,
                search: dados?.search
            }
        });
        return response.data;
    }

    async buscarProfessores(dados?: FindAllParams): Promise<IResponsePadrao<Paginacao<UsuarioType>>> {
        const response = await this.api.get('/usuario/professores', {
            params: {
                page: dados?.page,
                take: Constants.ITEMS_PER_PAGE,
                search: dados?.search
            }
        });
        return response.data;
    }

    async buscarDiretores(dados?: FindAllParams): Promise<IResponsePadrao<Paginacao<UsuarioType>>> {
        const response = await this.api.get('/usuario/diretores', {
            params: {
                page: dados?.page,
                take: Constants.ITEMS_PER_PAGE,
                search: dados?.search
            }
        });
        return response.data;
    }

    async buscarDiretoresPorSecretaria(id: number, dados?: FindAllParams): Promise<IResponsePadrao<UsuarioType[]>> {
        const response = await this.api.get(`/usuario/diretores/secretaria/${id}`, {
            params: {
                page: dados?.page,
                //take: Constants.ITEMS_PER_PAGE,
                search: dados?.search
            }
        });
        return response.data;
    }

    async buscarUm(id: string): Promise<IResponsePadrao<UsuarioType>> {
        const response = await this.api.get(`/usuario/${id}`);
        return response.data;
    }

    async editar(dados: UsuarioType, id: string): Promise<IResponsePadrao<UsuarioType>> {
        const response = await this.api.put(`/usuario/${id}`, dados);
        return response.data;
    }

    async cadastrar(dados: UsuarioType, file?: File): Promise<IResponsePadrao<UsuarioType>> {
        if (file) {
            const formData = new FormData();
            formData.append("file", file)
            formData.append("grupoAlunoId", String(dados?.grupoAlunoId))
            const response = await this.api.post(`/usuario`, formData);
            return response.data;
        }
        const response = await this.api.post(`/usuario`, dados);
        return response.data;
    }

    async cadastrar2(dados: UsuarioType, file?: File): Promise<IResponsePadrao<UsuarioType>> {
        if (file) {
            const formData = new FormData();
            formData.append("file", file)
            formData.append("grupoAlunoId", String(dados?.grupoAlunoId))
            const response = await this.api.post(`/usuario/public`, formData);
            return response.data;
        }
        const response = await this.api.post(`/usuario/public`, dados);
        return response.data;
    }

    async cadastrarProfessor(dados: UsuarioType): Promise<IResponsePadrao<UsuarioType>> {
        const response = await this.api.post(`/usuario/professor`, {
            ...dados,
            turmas: dados.turmasIds
        });
        return response.data;
    }

    async excluir(id: number): Promise<IResponsePadrao<UsuarioType>> {
        const response = await this.api.delete(`/usuario/${id}`);
        return response.data;
    }
}
