import React, { ReactElement, useState } from 'react';
import * as IoIcons from 'react-icons/io';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Menu, MenuItem} from "@material-ui/core";
import styled from "styled-components";

const BotaoAcoesStyled = styled(Button)`
  color: #0367a5;
  border: 1px solid #0367a5;
`

export const ConfirmButton = styled(Button)`
  background: #66bf3c;
  color: #FFF;
  &:hover {
    background: #66bf3c;
  }
`

export const CancelButton = styled(Button)`
  background: #f54040;
  color: #FFF;

  &:hover {
    background: #f54040;
  }
`

export const WarningButton = styled(Button)`
  background: #ff7300;
  color: #FFF;

  &:hover {
    background: #ff7300;
  }
`

export const AuxButton = styled(Button)`
  background: rgba(157, 142, 142, 1);
  color: #FFF;

  &:hover {
    background: rgba(157, 142, 142, 1);
  }
`

export type BotaoAcoesProps = {
    onBotaoEditarClicado: () => void;
    onBotaoExcluirClicado: () => void;
    esconderEditar?: boolean;
    esconderExcluir?: boolean;
    linksExtras?: ReactElement;
};

export const BotaoAcoes = (props: BotaoAcoesProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openDelete, setOpenDelete] = useState<boolean>(false)
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    return (
        <>
            <BotaoAcoesStyled
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="outlined"
            >
                Ações <IoIcons.IoMdArrowDropdown color="blue" />
            </BotaoAcoesStyled>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClick={handleClose}
            >
                {!props.esconderEditar && (
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            props.onBotaoEditarClicado();
                        }}
                    >
                        Visualizar / Editar
                    </MenuItem>
                )}
                {!props.esconderExcluir && (
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            setOpenDelete(true)
                        }}
                    >
                        Excluir
                    </MenuItem>
                )}
                {props.linksExtras}
            </Menu>
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Deseja realmente excluir ?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Esta ação não poderá ser desfeita. Todos os dados serão perdidos
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CancelButton onClick={handleCloseDelete}>Cancelar</CancelButton>
                    <ConfirmButton onClick={() => {
                        handleCloseDelete()
                        props.onBotaoExcluirClicado()
                    }} autoFocus>
                        Confirmar
                    </ConfirmButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
