import { BaseApi } from './BaseApi';
import {IResponsePadrao} from "../types/IResponsePadrao";
import {FindAllParams} from "../types/FindAllParams";
import {Paginacao} from "../types/Paginacao";
import {Constants} from "../types/Constants";
import {Escola} from "../types/Escola";
export class EscolaApi extends BaseApi {
    async cadastrar(dados: Escola): Promise<IResponsePadrao<Escola>> {
        return (await this.api.post(`/escola`, dados)).data;
    }

    async getBySecretariaId(secretariaId: number): Promise<IResponsePadrao<Escola[]>> {
        const response = await this.api.get(`/escola/secretaria/${secretariaId}`);
        return response.data;
    }

    async buscarTodos(dados?: FindAllParams): Promise<IResponsePadrao<Paginacao<Escola>>> {
        const response = await this.api.get('/escola', {
            params: {
                page: dados?.page,
                take: dados?.page ? Constants.ITEMS_PER_PAGE : undefined,
                search: dados?.search
            }
        });
        return response.data;
    }

    async excluir(id: number): Promise<IResponsePadrao<Escola>> {
        const response = await this.api.delete(`/escola/${id}`);
        return response.data;
    }

    async buscarUm(id: number): Promise<IResponsePadrao<Escola>> {
        const response = await this.api.get(`/escola/${id}`);
        return response.data;
    }

    async editar(dados: Escola, id: number): Promise<IResponsePadrao<Escola>> {
        const response = await this.api.put(`/escola/${id}`, dados);
        return response.data;
    }
}
