import { AlertContainer } from 'react-alert';
import { store } from '../redux/store';
import { USUARIO_LOGADO } from '../redux/actions/usuarioLogado.actions';

export const TratamentoErroPadrao = (
	error: any,
	alertManager: AlertContainer
) => {
	console.log(error)
	if (
		(error?.response?.status &&
			error?.response?.status === 401 &&
			!error.response.data.message.includes('E-mail e/ou senha inválidos')) ||
		(error?.response?.status === 403 &&
			error.response.data.message.includes('Refresh token'))
	) {
		alertManager.error(
			'Seu login expirou ou sua conta foi acessada em outro computador, por favor, faça o login novamente',
			{
				timeout: 4000,
			}
		);
		store.dispatch({
			type: USUARIO_LOGADO.SAIU,
		});
		return;
	}
	if (error?.response?.data?.message) {
		alertManager.error(error.response.data.message, {
			timeout: 4000,
		});
	} else {
		alertManager.error(
			'Houve um erro desconhecido, por favor, tente novamente. Se o error persistir, contacte a Grow Desenvolvimento',
			{
				timeout: 4000,
			}
		);
	}
};
