import React from "react";
import { TabelaBase, TabelaBaseProps } from "../TabelaBase/TabelaBase";
import { Livro } from "../../types/Livro";

interface ListarLivrosProps extends TabelaBaseProps<Livro> {}

export const ListarLivros = (props: ListarLivrosProps) => {
  return (
    <TabelaBase
      qtdPaginas={props.qtdPaginas}
      rows={props.rows}
      pesquisar={props.pesquisar}
      cadastrarNovoLink={props.cadastrarNovoLink}
      onPageChange={props.onPageChange}
      headers={props.headers}
    />
  );
};
