import {RootState} from "../redux/RootState";
import {store} from "../redux/store";
import {TipoUsuario} from "../types/TipoUsuario";

let usuarioLogado: RootState['usuarioLogado'];
store.subscribe(() => {
    usuarioLogado = store.getState().usuarioLogado;
});

export const ObterTipoUsuario = (): TipoUsuario => {
    return usuarioLogado?.student ?
        TipoUsuario.ALUNO :
        usuarioLogado?.teacher ?
            TipoUsuario.PROFESSOR :
            usuarioLogado.secretariaId ?
                TipoUsuario.SECRETARIA
                :
                usuarioLogado.escolaId ?
                    TipoUsuario.ESCOLA:
            TipoUsuario.OUTRO
}
