import React, {useEffect} from 'react'
import {useDispatch} from "react-redux";
import {USUARIO_LOGADO} from "../redux/actions/usuarioLogado.actions";
import {useNavigate} from "react-router-dom";

export const Sair = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        dispatch({
            type: USUARIO_LOGADO.SAIU,
        });
        navigate('/')
    }, [])
    return <>

    </>
}
