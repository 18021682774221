import { BaseApi } from './BaseApi';
import {Paginacao} from "../types/Paginacao";
import {FindAllParams} from "../types/FindAllParams";
import {IResponsePadrao} from "../types/IResponsePadrao";
import {Constants} from "../types/Constants";
import {GrupoAlunos} from "../types/GrupoAlunos";

export class GrupoAlunosApi extends BaseApi {
    async buscarTodos(dados?: FindAllParams): Promise<IResponsePadrao<Paginacao<GrupoAlunos>>> {
        const response = await this.api.get('/grupo-alunos', {
            params: {
                page: dados?.page,
                take: dados?.page ? Constants.ITEMS_PER_PAGE : undefined,
                search: dados?.search
            }
        });
        return response.data;
    }

    async buscarUm(id: string): Promise<IResponsePadrao<GrupoAlunos>> {
        const response = await this.api.get(`/grupo-alunos/${id}`);
        return response.data;
    }

    async excluir(id: number): Promise<IResponsePadrao<GrupoAlunos>> {
        const response = await this.api.delete(`/grupo-alunos/${id}`);
        return response.data;
    }

    async cadastrar(dados: GrupoAlunos): Promise<IResponsePadrao<GrupoAlunos>> {
        const response = await this.api.post(`/grupo-alunos`, dados);
        return response.data;
    }

    async editar(dados: GrupoAlunos, id: string): Promise<IResponsePadrao<GrupoAlunos>> {
        const response = await this.api.put(`/grupo-alunos/${id}`, dados);
        return response.data;
    }
}
