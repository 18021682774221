import React, {useCallback, useEffect, useState} from 'react'
import {ListarGruposDeAcesso} from "./ListarGruposDeAcesso";
import {ROUTES_PATH} from "../RoutesPath";
import {ColumnHeader} from "../TabelaBase/TabelaBase";
import {BotaoAcoes} from "../Botoes/BotaoAcoes";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useDispatch} from "react-redux";
import {useAlert} from "react-alert";
import {useNavigate} from "react-router-dom";
import {FindAllParams} from "../../types/FindAllParams";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {GrupoAcesso} from "../../types/GrupoAcesso";

export const ListarGruposDeAcessoContainer = () => {
    const [grupos, setGrupos] = useState<GrupoAcesso[]>([])
    const [qtdPag, setQtdPag] = useState<number>(0)
    const [pesquisa, setPesquisa] = useState<string>('')
    const [page, setPage] = useState<number>(1)
    const api = useGrowApi();
    const dispatch = useDispatch()
    const alert = useAlert()
    const navigate = useNavigate()
    const buscaDados = useCallback(
        async (dados?: FindAllParams) => {
            try {
                dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
                const response = await api.GrupoAcesso.buscarTodos(dados);
                setQtdPag(response.data.count)
                setGrupos(response.data.rows)
            } catch (e: any) {
                TratamentoErroPadrao(e, alert);
            } finally {
                dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
            }
        }, [page])
    const handleExcluir = useCallback(async (id: number) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.GrupoAcesso.excluir(id)
            await buscaDados({page, search: pesquisa})
            alert.success(response.message, {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [])
    const headers: ColumnHeader[] = [
        {
            label: '#',
            columnName: 'id'
        },
        {
            label: 'Nome',
            columnName: 'name'
        },
        {
            label: 'Ações',
            columnName: 'id',
            render: (id) => <BotaoAcoes
                onBotaoEditarClicado={() => navigate(`/grupo-acesso/editar/${id}`)}
                onBotaoExcluirClicado={async () => {
                    await handleExcluir(id)
                }}
            />
        }
    ]
    useEffect(() => {
        buscaDados({
            page,
            search: pesquisa
        })
    }, [page, pesquisa])
    return <>
        <ListarGruposDeAcesso
            rows={grupos}
            pesquisar={(pesquisa) => setPesquisa(pesquisa)}
            onPageChange={(page) => setPage(page)}
            cadastrarNovoLink={ROUTES_PATH.CADASTRAR_GRUPOS_ACESSO}
            headers={headers}
            qtdPaginas={qtdPag}
        />
    </>
}
