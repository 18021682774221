import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {StyledContainer} from "../TabelaBase/TabelaBase";
import {Simulado} from "../../types/Simulado";
import {useDispatch} from "react-redux";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useAlert} from "react-alert";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import styled from 'styled-components'
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement, Title, Filler
} from "chart.js";
import { Pie, Line } from "react-chartjs-2";
import {shuffle} from "../../utils/shuffle";

ChartJS.register(
    ArcElement,
    Tooltip,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Filler,
    Legend
);

const StyledGrid = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 25px;
`

const BasePie = styled.div`
  background: #eaeaea;
  margin-right: 10px;
  border-radius: 5px;
  padding: 0 10px;
  height: 45vh;
  text-align: center;
`

const TitlePie = styled.h2`
  font-size: 25px;
  color: rgba(0,0,0,0.4);
`

const ChartDiv = styled.div `
  width: 100%;
  height: 35vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  
  @media(max-width: 1400px) {
    height: 30vh;
  }
`

export const Home = () => {
    const dispatch = useDispatch()
    const api = useGrowApi()
    const alert = useAlert()
    const [maisAcertos, setMaisAcertos] = useState<Simulado[]>([])
    const [maisErros, setMaisErros] = useState<Simulado[]>([])
    const [maisRespondidas, setMaisRespondidas] = useState<Simulado[]>([])
    const buscaMaisAcertos = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Simulado.maisAcertos();
            setMaisAcertos(response.data)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [api, alert, dispatch])

    const buscaMaisErros = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Simulado.maisErros();
            setMaisErros(response.data)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [api, alert, dispatch])

    const buscaMaisRespondidas = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Simulado.maisRespondidas();
            setMaisRespondidas(shuffle<Simulado>(response.data))
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [api, alert, dispatch])

    const maisAcertadosConfig = {
        labels: ['Questão 1', 'Questão 2', 'Questão 3', 'Questão 4', 'Questão 5'],
        datasets: [
            {
                label: 'Quantidade de acertos',
                data: [maisAcertos[0]?.totalAcertos, maisAcertos[1]?.totalAcertos, maisAcertos[2]?.totalAcertos, maisAcertos[3]?.totalAcertos, maisAcertos[4]?.totalAcertos],
                backgroundColor: [
                    'rgba(255, 99, 132)',
                    'rgba(54, 162, 235)',
                    'rgba(255, 206, 86)',
                    'rgba(75, 192, 192)',
                    'rgba(153, 102, 255)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };


    const maisErradosConfig = {
        labels: ['Questão 1', 'Questão 2', 'Questão 3', 'Questão 4', 'Questão 5'],
        datasets: [
            {
                label: 'Quantidade de erros',
                data: [maisErros[0]?.totalErros, maisErros[1]?.totalErros, maisErros[2]?.totalErros, maisErros[3]?.totalErros, maisErros[4]?.totalErros],
                backgroundColor: [
                    'rgba(54, 162, 235)',
                    'rgba(255, 206, 86)',
                    'rgba(255, 99, 132)',
                    'rgba(153, 102, 255)',
                    'rgba(75, 192, 192)',
                ],
                borderColor: [
                    'rgba(54, 162, 235)',
                    'rgba(255, 206, 86)',
                    'rgba(255, 99, 132)',
                    'rgba(153, 102, 255)',
                    'rgba(75, 192, 192)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const data = {
        labels: ['Questão 1', 'Questão 2', 'Questão 3', 'Questão 4', 'Questão 5'],
        datasets: [
            {
                fill: true,
                data: [maisRespondidas[0]?.totalErros, maisRespondidas[1]?.totalErros, maisRespondidas[2]?.totalErros, maisRespondidas[3]?.totalErros, maisRespondidas[4]?.totalErros],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };


    useEffect(() => {
        buscaMaisAcertos()
        buscaMaisErros()
        buscaMaisRespondidas()
    }, [])
    return <>
        <StyledContainer>
            <StyledGrid>
                <BasePie>
                    <TitlePie>
                        Questões mais acertadas
                        <ChartDiv>
                            <Pie
                                data={maisAcertadosConfig}
                                options={{
                                    plugins: {
                                        legend: {
                                            display: false
                                        },
                                        tooltip: {
                                            callbacks: {
                                                label: (tooltipItem) => {
                                                    const numQuestao = tooltipItem.label.split('Questão ').join("")
                                                    const enunciado = maisAcertos[Number(numQuestao) - 1]?.enunciado.length > 20 ? maisAcertos[Number(numQuestao) - 1]?.enunciado.slice(0,20) + '...' : maisAcertos[Number(numQuestao) - 1]?.enunciado;
                                                    return `Enunciado: ${enunciado} Total de acertos: ${tooltipItem.formattedValue}`
                                                }
                                            }
                                        },
                                    },
                                }}
                            />
                        </ChartDiv>
                    </TitlePie>
                </BasePie>
                <BasePie>
                    <TitlePie>
                        Questões mais erradas
                    </TitlePie>
                    <ChartDiv>
                        <Pie
                            data={maisErradosConfig}
                            options={{
                                plugins: {
                                    legend: {
                                        display: false
                                    },
                                    tooltip: {
                                        callbacks: {
                                            label: (tooltipItem) => {
                                                const numQuestao = tooltipItem.label.split('Questão ').join("")
                                                const enunciado = maisErros[Number(numQuestao) - 1]?.enunciado.length > 20 ? maisErros[Number(numQuestao) - 1]?.enunciado.slice(0,20) + '...' : maisErros[Number(numQuestao) - 1]?.enunciado;
                                                return `Enunciado: ${enunciado} Total de erros: ${tooltipItem.formattedValue}`
                                            }
                                        }
                                    },
                                },
                            }}
                        />
                    </ChartDiv>
                </BasePie>
            </StyledGrid>
            <BasePie style={{marginTop: "25px"}}>
                <TitlePie>
                    Questões mais respondidas
                </TitlePie>
                <ChartDiv>
                    <Line
                        options={{
                            responsive: true,
                            plugins: {
                                tooltip: {
                                    callbacks: {
                                        label: (tooltipItem) => {
                                            const numQuestao = tooltipItem.label.split('Questão ').join("")
                                            const enunciado = maisRespondidas[Number(numQuestao) - 1]?.enunciado.length > 20 ? maisRespondidas[Number(numQuestao) - 1]?.enunciado.slice(0,20) + '...' : maisRespondidas[Number(numQuestao) - 1]?.enunciado;
                                            return `Enunciado: ${enunciado} Total de acertos: ${tooltipItem.formattedValue}`
                                        }
                                    }
                                },
                                legend: {
                                    display: false,
                                },
                                title: {
                                    display: false,
                                },
                            },
                        }}
                          data={data} />
                </ChartDiv>
            </BasePie>
        </StyledContainer>
    </>
}
