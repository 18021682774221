import React, {useEffect, useState} from 'react'
import {
    Box,
    Button, Checkbox, Chip,
    Container, Divider,
    FormControlLabel,
    Grid, Paper,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow,
    TextField
} from "@material-ui/core";
import {Formik, FormikState} from "formik";
import * as Yup from 'yup'
import {CancelButton, ConfirmButton} from "../Botoes/BotaoAcoes";
import {useNavigate} from "react-router-dom";
import {ROUTES_PATH} from "../RoutesPath";
import {StyledContainer} from "../GrupoAcesso/FormularioGrupoAcesso";
import {Livro} from "../../types/Livro";
import {UsuarioType} from "../../types/Usuario";
import {GrupoAlunos} from "../../types/GrupoAlunos";
import {Secretaria} from "../../types/Secretaria";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {useDispatch} from "react-redux";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useAlert} from "react-alert";
import {Autocomplete} from "@material-ui/lab";
import {Escola} from "../../types/Escola";
import {AdicionarNovo} from '../TabelaBase/TabelaBase';
import {ModalTurma} from "./ModalTurma";
import {Turma} from "../../types/Turma";
import * as R from 'ramda';

export type FormularioLivrosProps = {
    initialValues: Livro
    onSubmit: (values: Livro) => void;
    resetarForm?: boolean
    titulo: string
    usuarios: UsuarioType[]
    grupos: GrupoAlunos[]
}

export const FormularioLivros = (props: FormularioLivrosProps) => {
    const [turmasSelecionadas, setTurmasSelecionadas] = useState<Turma[]>([])
    const [rerender, setRerender] = useState<number>(0)
    const [secretarias, setSecretarias] = useState<Secretaria[]>([])
    const [escolas, setEscolas] = useState<Escola[]>([])
    const [openTurma, setOpenTurma] = useState<boolean>(false)
    const [secretaria, setSecretaria] = useState<Secretaria | null>(null)
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const alert = useAlert();
    const api = useGrowApi()
    const [selectAll, setSelectAll] = useState<boolean>(false);

    const removerTurma = (turma: Turma) => {
        const index = turmasSelecionadas.indexOf(turma)
        const novoArray = R.remove(index, 1, turmasSelecionadas)
        setTurmasSelecionadas(novoArray)
    }

    const handleToggleSelectAll = (setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        setSelectAll(prev => {
            if (!prev) {
                setFieldValue('escolas', escolas)
            } else {
                setFieldValue('escolas', [])
            }
            setRerender(prev => prev + 1)
            return !prev
        })
    };
    const onSubmit = async (
        values: Livro,
        {
            resetForm,
            setStatus,
            setErrors,
        }: {
            resetForm: (
                nextState?: Partial<FormikState<Livro>> | undefined
            ) => void;
            setStatus: any;
            setErrors: any;
        }
    ) => {
        try {
            await props.onSubmit({...values, turmas: turmasSelecionadas});
            if (props.resetarForm) {
                resetForm();
                setTurmasSelecionadas([])
            }
        } catch (error: any) {
            setStatus({success: false});
            setErrors({submit: error.message});
        }
    };
    const buscaSecretarias = async () => {
        try {
            dispatch({type: REALIZANDO_PROCESSAMENTO.ATIVO});
            const response = await api.Secretaria.buscarTodos();
            setSecretarias(response.data.rows)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({type: REALIZANDO_PROCESSAMENTO.NENHUM});
        }
    }
    const buscaEscolas = async () => {
        try {
            dispatch({type: REALIZANDO_PROCESSAMENTO.ATIVO});
            const response = await api.Escola.getBySecretariaId(secretaria!.id);
            setEscolas(response.data)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({type: REALIZANDO_PROCESSAMENTO.NENHUM});
        }
    }
    const schema = Yup.object().shape({
        name: Yup.string().required('Campo obrigatório.'),
    });
    useEffect(() => {
        buscaSecretarias()
    }, [])
    useEffect(() => {
        if (secretaria?.id) {
            buscaEscolas()
        }
    }, [secretaria])
    return <>
        <Formik
            initialValues={props.initialValues}
            enableReinitialize
            validationSchema={schema}
            onSubmit={onSubmit}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue
              }) => (
                <StyledContainer>
                    <form onSubmit={handleSubmit}>
                        <Container>
                            <h2>{props.titulo}</h2>
                            <Grid container spacing={2}>
                                <Grid item sm={12} xs={12}>
                                    <TextField
                                        label="Nome"
                                        variant="standard"
                                        id="name"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        fullWidth
                                        error={!!errors.name && touched.name}
                                        helperText={touched.name && errors.name ? errors.name : ''}
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <FormControlLabel
                                        checked={values.isPublic}
                                        control={
                                            <Checkbox
                                                onChange={(event, checked) => {
                                                    setFieldValue('isPublic', checked)
                                                }}
                                            />
                                        }
                                        label="Livro público"
                                    />
                                </Grid>
                                {!values.isPublic && <Grid item sm={6} xs={12}>
                                    <Autocomplete
                                        id="secretarias"
                                        value={values.secretaria}
                                        options={secretarias ?? []}
                                        getOptionLabel={(option) => option.nome ?? ""}
                                        onChange={(e, value) => {
                                            setFieldValue("secretaria", value)
                                            setSecretaria(value)
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Secretaria"
                                            />
                                        )}
                                    />
                                </Grid>}
                                {!values.isPublic && <Grid item sm={6} xs={12}>
                                    <Autocomplete
                                        id="escolas"
                                        key={rerender}
                                        multiple
                                        value={values.escolas}
                                        options={escolas ?? []}
                                        getOptionLabel={(option) => option?.nome ?? ""}
                                        renderTags={(value, getTagProps) => {
                                            return <>
                                                {
                                                    value.map((option, index) => {
                                                        return <React.Fragment key={index}>
                                                            {(index === 0 || index === 1 || index === 2) &&
                                                                <Chip
                                                                    label={option.nome}
                                                                    {...getTagProps({index})}
                                                                />
                                                            }
                                                        </React.Fragment>
                                                    })
                                                }
                                                {value.length > 3 && `e outras ${value.length - 3} mais`}
                                            </>
                                        }}
                                        onChange={(e, value) => setFieldValue("escolas", value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Escolas"
                                            />
                                        )}
                                        PaperComponent={(paperProps) => {
                                            const {children, ...restPaperProps} = paperProps;
                                            return (
                                                <Paper {...restPaperProps}>
                                                    <Box
                                                        onMouseDown={(e) => e.preventDefault()} // prevent blur
                                                        pl={1.5}
                                                        py={0.5}
                                                    >
                                                        <FormControlLabel
                                                            onClick={(e) => {
                                                                e.preventDefault(); // prevent blur
                                                                handleToggleSelectAll(setFieldValue);
                                                            }}
                                                            label="Todas as escolas"
                                                            control={
                                                                <Checkbox id="select-all-checkbox" checked={selectAll}/>
                                                            }
                                                        />
                                                    </Box>
                                                    <Divider/>
                                                    {children}
                                                </Paper>
                                            );
                                        }}
                                    />
                                </Grid>}
                                <Grid item sm={12} xs={12}>
                                    <input
                                        style={{display: 'none'}}
                                        id="raised-button-file"
                                        onChange={(e) => {
                                            setFieldValue("file", e.target?.files ? e.target.files[0] : undefined)
                                        }}
                                        type="file"
                                    />
                                    <label htmlFor="raised-button-file">
                                        <Button component="span" variant="contained" color="primary">
                                            {values.file?.name ?? "Enviar arquivo"}
                                        </Button>
                                    </label>
                                </Grid>
                            </Grid>
                            {values?.escolas?.length && values?.escolas?.length > 0 && !values.isPublic &&
                                <>
                                    <h3>Turmas</h3>
                                    <AdicionarNovo to="#" onClick={() => setOpenTurma(true)}>Adicionar
                                        turma</AdicionarNovo>
                                    <TableContainer style={{marginTop: '25px'}} component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Escola</TableCell>
                                                    <TableCell align="right">Turma</TableCell>
                                                    <TableCell align="right">Série</TableCell>
                                                    <TableCell align="right">Ações</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    turmasSelecionadas?.map((t, key) => (
                                                        <TableRow key={key}>
                                                            <TableCell component="th" scope="row">
                                                                {t?.escola?.nome}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {t.nome}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {t.serie}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <CancelButton
                                                                    type="button"
                                                                    onClick={() => removerTurma(t)}
                                                                >
                                                                    Remover
                                                                </CancelButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                            }
                            <Grid container spacing={2}>
                                <Grid item xs={4} style={{marginTop: "10px"}}>
                                    <ConfirmButton
                                        type="submit"
                                        fullWidth
                                    >
                                        Salvar
                                    </ConfirmButton>
                                </Grid>
                                <Grid item xs={4} style={{marginTop: "10px"}}>
                                    <CancelButton
                                        type="button"
                                        fullWidth
                                        onClick={() => navigate(ROUTES_PATH.LIVROS)}
                                    >
                                        Cancelar
                                    </CancelButton>
                                </Grid>
                            </Grid>
                        </Container>
                        <ModalTurma
                            turmasEnviadas={(turmas) => setTurmasSelecionadas(prev => [...prev, ...turmas])}
                            escolas={values.escolas ?? []}
                            open={openTurma}
                            onClose={() => setOpenTurma(false)}
                        />
                    </form>
                </StyledContainer>
            )}
        </Formik>
    </>
}
