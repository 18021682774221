import React from 'react'
import {
    Container,
    Grid,
    TextField
} from "@material-ui/core";
import {Formik, FormikState} from "formik";
import * as Yup from 'yup'
import {CancelButton, ConfirmButton} from "../Botoes/BotaoAcoes";
import {useNavigate} from "react-router-dom";
import {ROUTES_PATH} from "../RoutesPath";
import {StyledContainer} from "../GrupoAcesso/FormularioGrupoAcesso";
import {Escola} from "../../types/Escola";

export type FormularioEscolaProps = {
    initialValues: Escola
    onSubmit: (values: Escola) => void;
    resetarForm?: boolean
    titulo: string
}

export const FormularioEscola = (props: FormularioEscolaProps) => {
    const navigate = useNavigate()
    const onSubmit = async (
        values: Escola,
        {
            resetForm,
            setStatus,
            setErrors,
        }: {
            resetForm: (
                nextState?: Partial<FormikState<Escola>> | undefined
            ) => void;
            setStatus: any;
            setErrors: any;
        }
    ) => {
        try {
            await props.onSubmit(values);
            if (props.resetarForm) {
                resetForm();
            }
        } catch (error: any) {
            setStatus({ success: false });
            setErrors({ submit: error.message });
        }
    };
    const schema = Yup.object().shape({
        nome: Yup.string().required('Campo obrigatório.'),
        nomeResponsavel1: Yup.string().required('Campo obrigatório.'),
        emailResponsavel1: Yup.string().required('Campo obrigatório.').email('Envie um e-mail válido')
    });
    return <>
        <Formik
            initialValues={props.initialValues}
            enableReinitialize
            validationSchema={schema}
            onSubmit={onSubmit}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue
              }) => (
                <StyledContainer>
                    <form onSubmit={handleSubmit}>
                        <Container>
                            <h2>{props.titulo}</h2>
                            <Grid container spacing={2}>
                                <Grid item sm={12} xs={12}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="Nome"
                                        variant="standard"
                                        id="nome"
                                        name="nome"
                                        value={values.nome}
                                        onChange={handleChange}
                                        fullWidth
                                        error={!!errors.nome && touched.nome}
                                        helperText={touched.nome && errors.nome ? errors.nome : ''}
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="Endereço"
                                        variant="standard"
                                        id="endereco"
                                        name="endereco"
                                        value={values.endereco}
                                        onChange={handleChange}
                                        fullWidth
                                        error={!!errors.endereco && touched.endereco}
                                        helperText={touched.endereco && errors.endereco ? errors.endereco : ''}
                                    />
                                </Grid>
                            </Grid>
                            <h3>Responsável 1</h3>
                            <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="Nome"
                                        variant="standard"
                                        id="nomeResponsavel1"
                                        name="nomeResponsavel1"
                                        value={values.nomeResponsavel1}
                                        onChange={handleChange}
                                        fullWidth
                                        error={!!errors.nomeResponsavel1}
                                        helperText={errors.nomeResponsavel1 ? errors.nomeResponsavel1 : ''}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="E-mail"
                                        variant="standard"
                                        id="emailResponsavel1"
                                        name="emailResponsavel1"
                                        value={values.emailResponsavel1}
                                        onChange={handleChange}
                                        fullWidth
                                        error={!!errors.emailResponsavel1}
                                        helperText={errors.emailResponsavel1 ? errors.emailResponsavel1 : ''}
                                    />
                                </Grid>
                            </Grid>
                            <h3>Responsável 2</h3>
                            <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="Nome"
                                        variant="standard"
                                        id="nomeResponsavel2"
                                        name="nomeResponsavel2"
                                        value={values.nomeResponsavel2}
                                        onChange={handleChange}
                                        fullWidth
                                        error={!!errors.nomeResponsavel2 && touched.nomeResponsavel2}
                                        helperText={touched.nomeResponsavel2 && errors.nomeResponsavel2 ? errors.nomeResponsavel2 : ''}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="E-mail"
                                        variant="standard"
                                        id="emailResponsavel2"
                                        name="emailResponsavel2"
                                        value={values.emailResponsavel2}
                                        onChange={handleChange}
                                        fullWidth
                                        error={!!errors.emailResponsavel2 && touched.emailResponsavel2}
                                        helperText={touched.emailResponsavel2 && errors.emailResponsavel2 ? errors.emailResponsavel2 : ''}
                                    />
                                </Grid>
                            </Grid>
                            <h3>Responsável 3</h3>
                            <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="Nome"
                                        variant="standard"
                                        id="nomeResponsavel3"
                                        name="nomeResponsavel3"
                                        value={values.nomeResponsavel3}
                                        onChange={handleChange}
                                        fullWidth
                                        error={!!errors.nomeResponsavel3 && touched.nomeResponsavel3}
                                        helperText={touched.nomeResponsavel3 && errors.nomeResponsavel3 ? errors.nomeResponsavel3 : ''}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="E-mail"
                                        variant="standard"
                                        id="emailResponsavel3"
                                        name="emailResponsavel3"
                                        value={values.emailResponsavel3}
                                        onChange={handleChange}
                                        fullWidth
                                        error={!!errors.emailResponsavel3 && touched.emailResponsavel3}
                                        helperText={touched.emailResponsavel3 && errors.emailResponsavel3 ? errors.emailResponsavel3 : ''}
                                    />
                                </Grid>
                            </Grid>
                            <h4>Responsável 4</h4>
                            <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="Nome"
                                        variant="standard"
                                        id="nomeResponsavel4"
                                        name="nomeResponsavel4"
                                        value={values.nomeResponsavel4}
                                        onChange={handleChange}
                                        fullWidth
                                        error={!!errors.nomeResponsavel4 && touched.nomeResponsavel4}
                                        helperText={touched.nomeResponsavel4 && errors.nomeResponsavel4 ? errors.nomeResponsavel4 : ''}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="E-mail"
                                        variant="standard"
                                        id="emailResponsavel4"
                                        name="emailResponsavel4"
                                        value={values.emailResponsavel4}
                                        onChange={handleChange}
                                        fullWidth
                                        error={!!errors.emailResponsavel4 && touched.emailResponsavel4}
                                        helperText={touched.emailResponsavel4 && errors.emailResponsavel4 ? errors.emailResponsavel4 : ''}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4} style={{marginTop: "10px"}}>
                                    <ConfirmButton
                                        type="submit"
                                        fullWidth
                                    >
                                        Salvar
                                    </ConfirmButton>
                                </Grid>
                                <Grid item xs={4} style={{marginTop: "10px"}}>
                                    <CancelButton
                                        type="submit"
                                        fullWidth
                                        onClick={() => navigate(ROUTES_PATH.LISTAR_ESCOLAS)}
                                    >
                                        Cancelar
                                    </CancelButton>
                                </Grid>
                            </Grid>
                        </Container>
                    </form>
                </StyledContainer>
            )}
        </Formik>
    </>
}
