import React, { useCallback, useState } from "react";
import { Login } from "./Login";
import { LoginFields } from "../../types/LoginFields";
import { useDispatch } from "react-redux";
import { useGrowApi } from "../../hooks/useGrowApi";
import { REALIZANDO_PROCESSAMENTO } from "../../redux/actions/realizandoProcessamento.actions";
import { USUARIO_LOGADO } from "../../redux/actions/usuarioLogado.actions";
import { RootState } from "../../redux/RootState";
import { useAlert } from "react-alert";
import { TratamentoErroPadrao } from "../../utils/TratamentoErroPadrao";
import { Cadastrar } from "./Cadastrar";
import { UsuarioType } from "../../types/Usuario";

export const LoginContainer = () => {
  const dispatch = useDispatch();
  const [tela, setTela] = useState<1 | 2>(1);
  const api = useGrowApi();
  const alert = useAlert();
  const fazerLogin = async (values: LoginFields): Promise<void> => {
    try {
      dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
      const response = await api.Login.login(values);
      dispatch({
        type: USUARIO_LOGADO.LOGOU,
        access_token: response.access_token,
        refresh_token: response.refresh_token,
        nome: response.nome,
        student: response.student,
        teacher: response.teacher,
        id: response.id,
        secretariaId: (response as any).secretaridaId,
        escolaId: response.escolaId
      } as RootState["usuarioLogado"] & { type: string });
      alert.success("Usuário autenticado com sucesso !", {
        timeout: 4000,
      });
    } catch (e: any) {
      TratamentoErroPadrao(e, alert);
    } finally {
      dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
    }
  };

  const cadastrar = useCallback(
    async (dados: UsuarioType) => {
      try {
        dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
        const response = await api.Usuario.cadastrar2({
          ...dados,
          cpf: dados.cpf?.split(".").join("").split("-").join(""),
          student: true,
          scopeId: undefined,
        });
        setTela(1);
        alert.success(response.message, {
          timeout: 4000,
        });
      } catch (e: any) {
        TratamentoErroPadrao(e, alert);
      } finally {
        dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
      }
    },
    [alert, api.Usuario, dispatch]
  );
  return (
    <>
      {tela === 1 && (
        <Login onSubmitLogin={fazerLogin} onCadastrar={() => setTela(2)} />
      )}
      {tela === 2 && (
        <Cadastrar onSubmitLogin={cadastrar} onCancelar={() => setTela(1)} />
      )}
    </>
  );
};
