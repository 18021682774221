import React, {useCallback, useEffect, useState} from 'react'
import {FormularioLivros} from "./FormularioLivros";
import {Livro} from "../../types/Livro";
import {useDispatch} from "react-redux";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useAlert} from "react-alert";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {UsuarioType} from "../../types/Usuario";
import {useParams} from "react-router-dom";
import {GrupoAlunos} from "../../types/GrupoAlunos";

export const EditarLivrosContainer = () => {
    const dispatch = useDispatch()
    const api = useGrowApi()
    const alert = useAlert()
    const [usuarios, setUsuarios] = useState<UsuarioType[]>([])
    const [grupos, setGrupos] = useState<GrupoAlunos[]>([])
    const {id} = useParams<{id: string}>()
    const [livro, setLivro] = useState<Livro>({
        name: '',
        isPublic: false,
        file: {} as File,
        users: []
    })

    const buscaDados = async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Livro.buscarUm(Number(id));
            setLivro(response.data)
            await buscaUsuarios()
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }

    const buscaUsuarios = async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Usuario.buscarAlunos();
            setUsuarios(response.data.rows)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        }
    }

    const salvar = async (dados: Livro) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Livro.editar(dados, Number(id));
            alert.success(response.message, {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }
    const buscaGrupos2 = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.GrupoAlunos.buscarTodos();
            setGrupos(response.data.rows)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [alert, api.GrupoAcesso, dispatch, setGrupos])
    useEffect(() => {
        buscaGrupos2()
        buscaDados()
    }, [])
    return <>
        <FormularioLivros
            grupos={grupos}
            usuarios={usuarios}
            initialValues={livro}
            onSubmit={salvar}
            resetarForm={false}
            titulo="Editar livro"
        />
    </>
}
