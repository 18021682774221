import React from 'react'
import { Escola } from '../../types/Escola';
import {FormularioEscola} from "./FormularioEscola";
import {useDispatch} from "react-redux";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useAlert} from "react-alert";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";

export const CadastrarEscolaContainer = () => {
    const dispatch = useDispatch()
    const api = useGrowApi()
    const alert = useAlert()
    const cadastrar = async (dados: Escola) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Escola.cadastrar({
                ...dados,
                nome: dados.nome,
                endereco: dados.endereco
            } as Escola);
            alert.success(response.message, {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }
    return <FormularioEscola
        resetarForm
        initialValues={{
            nome: '',
            nomeResponsavel1: '',
            nomeResponsavel2: '',
            nomeResponsavel3: '',
            nomeResponsavel4: '',
            emailResponsavel1: '',
            emailResponsavel2: '',
            emailResponsavel3: '',
            emailResponsavel4: '',
        } as Escola}
        onSubmit={cadastrar}
        titulo="Cadastrar Escola"
    />
}
