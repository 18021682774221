import { BaseApi } from './BaseApi';
import {Paginacao} from "../types/Paginacao";
import {FindAllParams} from "../types/FindAllParams";
import {IResponsePadrao} from "../types/IResponsePadrao";
import {Constants} from "../types/Constants";
import {Recuperacao} from "../types/Recuperacao";

export class RecuperacaoApi extends BaseApi {
    async buscarTodos(dados?: FindAllParams): Promise<IResponsePadrao<Paginacao<Recuperacao>>> {
        const response = await this.api.get('/recuperacao', {
            params: {
                page: dados?.page,
                take: dados?.page ? Constants.ITEMS_PER_PAGE : undefined,
                search: dados?.search
            }
        });
        return response.data;
    }

    async buscarTodosAluno(dados?: FindAllParams): Promise<IResponsePadrao<Paginacao<Recuperacao>>> {
        const response = await this.api.get('/recuperacao/aluno', {
            params: {
                page: dados?.page,
                take: dados?.page ? Constants.ITEMS_PER_PAGE : undefined,
                search: dados?.search
            }
        });
        return response.data;
    }

    async cadastrar(dados: Recuperacao): Promise<IResponsePadrao<Recuperacao>> {
        const {...resto} = dados
        return (await this.api.post(`/recuperacao`, {
            ...resto,
        })).data;
    }

    async editar(dados: Recuperacao, id: number): Promise<IResponsePadrao<Recuperacao>> {
        const {...resto} = dados
        const response = await this.api.put(`/recuperacao/${id}`, resto);
        return response.data;
    }

    async buscarUm(id: number): Promise<IResponsePadrao<Recuperacao>> {
        const response = await this.api.get(`/recuperacao/${id}`);
        return response.data;
    }

    async excluir(id: number): Promise<IResponsePadrao<Recuperacao>> {
        const response = await this.api.delete(`/recuperacao/${id}`);
        return response.data;
    }
}
