import React from 'react'
import {TabelaBase, TabelaBaseProps} from "../TabelaBase/TabelaBase";
import {RealidadeAumentada} from "../../types/RealidadeAumentada";

interface ListarRealidadeAumentadaProps extends TabelaBaseProps<RealidadeAumentada> {}

export const ListarRealidadeAumentada = (props: ListarRealidadeAumentadaProps) => {
    return <>
        <TabelaBase
            esconderAdicionar={props.esconderAdicionar}
            qtdPaginas={props.qtdPaginas}
            rows={props.rows}
            pesquisar={props.pesquisar}
            cadastrarNovoLink={props.cadastrarNovoLink}
            onPageChange={props.onPageChange}
            headers={props.headers}
        />
    </>
}
