import React, {useState} from 'react'
import {
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from "@material-ui/core";
import {CancelButton, ConfirmButton} from "../Botoes/BotaoAcoes";
import styled from "styled-components";
import {Escola} from "../../types/Escola";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {useDispatch} from "react-redux";
import {useAlert} from "react-alert";
import {useGrowApi} from "../../hooks/useGrowApi";
import {Turma} from "../../types/Turma";
import * as R from 'ramda'

type Props = {
    open: boolean
    onClose: () => void
    escolas: Escola[]
    turmasEnviadas: (turmas: Turma[]) => void
}

export const Box = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 30px;
  grid-column-gap: 10px;
`

export const ModalTurma = (props: Props) => {
    const dispatch = useDispatch();
    const alert = useAlert();
    const api = useGrowApi();
    const [turmasSelecionadas, setTurmasSelecionadas] = useState<Turma[]>([])
    const [turmasEnviadas, setTurmasEnviadas] = useState<Turma[]>([])
    const [tela, setTela] = useState<1 | 2>(1)
    const handleClose = () => {
        props.onClose()
        setTela(1)
    }
    const obterTurmas = async (id: number, serie: string) => {
        try {
            dispatch({type: REALIZANDO_PROCESSAMENTO.ATIVO});
            const turmas = await api.Turma.porEscolaESerie(id, serie);
            props.turmasEnviadas(turmas.data);
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({type: REALIZANDO_PROCESSAMENTO.NENHUM});
        }
    }
    const obterTurmasPorEscola = async (id: number) => {
        try {
            dispatch({type: REALIZANDO_PROCESSAMENTO.ATIVO});
            const turmas = await api.Turma.porEscola(id);
            setTurmasSelecionadas(turmas.data)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({type: REALIZANDO_PROCESSAMENTO.NENHUM});
        }
    }
    return <>
        <Dialog
            fullWidth
            maxWidth="md"
            open={props.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Adicionar turmas
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {tela === 1 && <Box>
                        {
                            props.escolas.map((e, key) => (
                                <React.Fragment key={key}>
                                    <span>{e.nome}</span>
                                    <Button component="span"
                                            onClick={() => {
                                                obterTurmas(e.id, 'Primeiro Ano')
                                                handleClose()
                                            }}
                                            variant="contained"
                                            color="primary"
                                    >
                                        Todas 1° ano
                                    </Button>
                                    <Button
                                        component="span"
                                        variant="contained"
                                        onClick={() => {
                                            obterTurmas(e.id, 'Segundo Ano')
                                            handleClose()
                                        }}
                                        color="secondary"
                                    >
                                        Todas 2° ano
                                    </Button>
                                    <Button
                                        component="span"
                                        variant="contained"
                                        onClick={() => {
                                            obterTurmas(e.id, 'Terceiro Ano')
                                            handleClose()
                                        }}
                                        color="primary"
                                    >
                                        Todas 3° ano
                                    </Button>
                                    <Button
                                        component="span"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            obterTurmas(e.id, 'Quarto Ano')
                                            handleClose()
                                        }}
                                    >
                                        Todas 4° ano
                                    </Button>
                                    <Button
                                        component="span"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            obterTurmas(e.id, 'Quinto Ano')
                                            handleClose()
                                        }}
                                    >
                                        Todas 5° ano
                                    </Button>
                                    <Button
                                        component="span"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            obterTurmas(e.id, 'Sexto Ano')
                                            handleClose()
                                        }}
                                    >
                                        Todas 6° ano
                                    </Button>
                                    <Button
                                        component="span"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            obterTurmas(e.id, 'Sétimo Ano')
                                            handleClose()
                                        }}
                                    >
                                        Todas 7° ano
                                    </Button>
                                    <Button
                                        component="span"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            obterTurmas(e.id, 'Oitavo Ano')
                                            handleClose()
                                        }}
                                    >
                                        Todas 8° ano
                                    </Button>
                                    <Button
                                        component="span"
                                        variant="contained"
                                        onClick={() => {
                                            obterTurmas(e.id, 'Nono Ano')
                                            handleClose()
                                        }}
                                        color="primary"
                                    >
                                        Todas 9° ano
                                    </Button>
                                    <Button onClick={() => {
                                        obterTurmasPorEscola(e.id)
                                        setTela(2)
                                    }} component="span" variant="contained" color="secondary">
                                        Manual
                                    </Button>
                                </React.Fragment>
                            ))
                        }
                    </Box>
                    }
                    {
                        tela === 2 && <>
                            <TableContainer style={{marginTop: '25px'}} component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Escola</TableCell>
                                            <TableCell align="left">Turma</TableCell>
                                            <TableCell align="left">Série</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            turmasSelecionadas.map((t, key) => (
                                                <TableRow key={key}>
                                                    <TableCell component="th" scope="row">
                                                        <Checkbox
                                                            checked={turmasEnviadas.includes(t)}
                                                            onChange={(event, checked) => {
                                                                if (checked) {
                                                                    setTurmasEnviadas(prev => [...prev, t])
                                                                } else {
                                                                    const index = turmasEnviadas.indexOf(t);
                                                                    const novoArray = R.remove(index, 1, turmasEnviadas)
                                                                    setTurmasEnviadas(novoArray)
                                                                }
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {t?.escola?.nome}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {t.nome}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {t.serie}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <ConfirmButton
                                style={{marginTop: '25px'}}
                                onClick={() => {
                                    props.turmasEnviadas(turmasEnviadas)
                                    handleClose()
                                }}
                                type="button">
                                Salvar
                            </ConfirmButton>
                        </>
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleClose}>Fechar</CancelButton>
            </DialogActions>
        </Dialog>
    </>
}
