import React from 'react';
import {Formik} from "formik";
import * as Yup from 'yup';
import {Button, Grid, TextField} from "@material-ui/core";
import styled from 'styled-components';
import Logo from '../../terrasul-logo.png'
import {UsuarioType} from "../../types/Usuario";

const LogoTipo = styled.img`
  margin-left: 18%;
  @media (max-width: 1368px) {
    margin-left: 5%;
  }
`

const Formulario = styled.form`
  background: #1673ac;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Content = styled.div`
  background: #FFF;
  min-height: 90vh;
  width: 40%;
  border-radius: 20px;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(92, 92, 92, 1);
  -moz-box-shadow: 0px 1px 5px 0px rgba(92, 92, 92, 1);
  box-shadow: 0px 1px 5px 0px rgba(92, 92, 92, 1);
`

const Container = styled.div`
    margin-left: 15%;
`

export type CadastrarProps = {
    onSubmitLogin: (dados: UsuarioType) => void;
    onCancelar: () => void
};

export const Cadastrar = (props: CadastrarProps) => {
    const loginSchema = Yup.object().shape({
        name: Yup.string().required('Campo obrigatório.'),
        cpf: Yup.string().required('Campo obrigatório.'),
        email: Yup.string()
            .required('E-mail é obrigatório')
            .email('Envie um e-mail válido'),
        senha: Yup.string().required('Campo obrigatório'),
        confirmarSenha: Yup.string().required('Campo obrigatório').oneOf([Yup.ref('senha'), null], 'Confirmação de senha deve ser igual a senha'),
    });
    return <>
        <Formik
            initialValues={{ email: '', senha: '', name: '', cpf: '', confirmarSenha: '' }}
            validationSchema={loginSchema}
            onSubmit={(values) => {
                props.onSubmitLogin(values);
            }}
        >
            {({ errors, handleChange, handleSubmit, touched }) => (
                <Formulario onSubmit={handleSubmit}>
                    <Content>
                        <Container>
                            <LogoTipo src={Logo} alt="logo" />
                            <Grid item xs={10}>
                                <TextField
                                    label="Nome"
                                    variant="standard"
                                    id="name"
                                    name="name"
                                    onChange={handleChange}
                                    fullWidth
                                    error={!!errors.name && touched.name}
                                    helperText={touched.name && errors.name ? errors.name : ''}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    label="CPF"
                                    variant="standard"
                                    id="cpf"
                                    name="cpf"
                                    onChange={handleChange}
                                    fullWidth
                                    error={!!errors.cpf && touched.cpf}
                                    helperText={touched.cpf && errors.cpf ? errors.cpf : ''}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    label="Seu e-mail"
                                    variant="standard"
                                    id="email"
                                    name="email"
                                    onChange={handleChange}
                                    fullWidth
                                    error={!!errors.email}
                                    helperText={errors.email}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    label="Sua senha"
                                    variant="standard"
                                    id="senha"
                                    name="senha"
                                    onChange={handleChange}
                                    type="password"
                                    error={!!errors.senha}
                                    helperText={errors.senha}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    label="Confirmar senha"
                                    variant="standard"
                                    id="confirmarSenha"
                                    name="confirmarSenha"
                                    type="password"
                                    onChange={handleChange}
                                    fullWidth
                                    error={!!errors.confirmarSenha && touched.confirmarSenha}
                                    helperText={touched.confirmarSenha && errors.confirmarSenha ? errors.confirmarSenha : ''}
                                />
                            </Grid>
                            <Grid item xs={10} style={{marginTop: "10px"}}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                >
                                    Cadastrar-se
                                </Button>
                            </Grid>
                            <Grid item xs={10} style={{marginTop: "10px"}}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    onClick={props.onCancelar}
                                >
                                    Cancelar
                                </Button>
                            </Grid>
                        </Container>
                    </Content>
                </Formulario>
            )}
        </Formik>
    </>
}
