import { Route, Routes } from "react-router-dom";
import { Home } from "./Home/Home";
import { ROUTES_PATH } from "./RoutesPath";
import { UsuarioContainer } from "./Usuario/UsuarioContainer";
import { ListarGruposDeAcessoContainer } from "./GrupoAcesso/ListarGruposDeAcessoContainer";
import { CadastrarGrupoAcessoContainer } from "./GrupoAcesso/CadastrarGrupoAcessContainer";
import { Sair } from "./Sair";
import { EditarGrupoAcessoContainer } from "./GrupoAcesso/EditarGrupoAcessoContainer";
import { CadastrarUsuarioContainer } from "./Usuario/CadastrarUsuarioContainer";
import { EditarUsuarioContainer } from "./Usuario/EditarUsuarioContainer";
import { ListarLivrosContainer } from "./Livros/ListarLivrosContainer";
import { CadastrarLivrosContainer } from "./Livros/CadastrarLivrosContainer";
import { EditarLivrosContainer } from "./Livros/EditarLivrosContainer";
import { ListarJogosContainer } from "./Jogos/ListarJogosContainer";
import { CadastrarJogosContainer } from "./Jogos/CadastrarJogosContainer";
import { EditarJogosContainer } from "./Jogos/EditarJogosContainer";
import { ListarRealidadeAumentadaContainer } from "./RealidadeAumentada/ListarRealidadeAumentadaContainer";
import { CadastrarRealidadeAumentadaContainer } from "./RealidadeAumentada/CadastrarRealidadeAumentadaContainer";
import { EditarRealidadeAumentadaContainer } from "./RealidadeAumentada/EditarRealidadeAumentadaContainer";
import { ListarSimuladosContainer } from "./Simulados/ListarSimuladosContainer";
import { CadastrarSimuladoContainer } from "./Simulados/CadastrarSimuladoContainer";
import { EditarSimuladoContainer } from "./Simulados/EditarSimuladoContainer";
import { ListarAlunosContainer } from "./Usuario/ListarAlunosContainer";
import { ListarProfessoresContainer } from "./Usuario/ListarProfessoresContainer";
import { ListarGruposDeAlunosContainer } from "./GrupoAlunos/ListarGruposDeAlunosContainer";
import { CadastrarGrupoAlunosContainer } from "./GrupoAlunos/CadastrarGrupoAlunosContainer";
import { EditarGrupoAlunosContainer } from "./GrupoAlunos/EditarGrupoAlunosContainer";
import { MeusLivrosContainer } from "./Livros/MeusLivrosContainer";
import { VisualizarLivroContainer } from "./Livros/VisualizarLivroContainer";
import {RelatorioSimuladoContainer} from "./Relatorios/RelatorioSimuladoContainer";
import {RelatorioPorTurmaContainer} from "./Relatorios/RelatorioPorTurmaContainer";
import {QuestoesParaCorrigirContainer} from "./QuestoesParaCorrigir/QuestoesParaCorrigirContainer";
import {ClonarSimuladoContainer} from "./Simulados/ClonarSimuladoContainer";
import {ResolverSimuladoContainer} from "./Simulados/ResolverSimuladoContainer";
import {ListarSecretariasContainer} from "./Secretarias/ListarSecretariasContainer";
import {CadastrarSecretariaContainer} from "./Secretarias/CadastrarSecretariaContainer";
import {EditarSecretariaContainer} from "./Secretarias/EditarSecretariaContainer";
import {ListarEscolasContainer} from "./Escolas/ListarEscolasContainer";
import {CadastrarEscolaContainer} from "./Escolas/CadastrarEscolaContainer";
import {EditarEscolaContainer} from "./Escolas/EditarEscolaContainer";
import {ListarDiretoresContainer} from "./Usuario/ListarDiretoresContainer";
import {Teste} from "./Teste";
import {ListarTurmasContainer} from "./Turma/ListarTurmasContainer";
import {CadastrarTurmaContainer} from "./Turma/CadastrarTurmaContainer";
import {ListarRecuperacaoContainer} from "./Recuperacao/ListarRecuperacaoContainer";
import {CadastrarRecuperacaoContainer} from "./Recuperacao/CadastrarRecuperacaoContainer";
import {ResolverRecuperacaoContainer} from "./Recuperacao/ResolverRecuperacaoContainer";
import {EditarAlunosContainer} from "./Turma/EditarAlunosContainer";
import {EditarTurmasContainer} from "./Escolas/EditarTurmasContainer";
import {RankingContainer} from "./Ranking/RankingContainer";

export const Rotas = () => {
  return (
    <>
      <Routes>
        <Route path={ROUTES_PATH.HOME} element={<Home />} />
        <Route path={ROUTES_PATH.USUARIOS} element={<UsuarioContainer />} />
        <Route path={ROUTES_PATH.JOGOS_ALUNO} element={<ListarJogosContainer aluno />} />
        <Route path={ROUTES_PATH.REALIDADE_AUMENTADA_ALUNO} element={<ListarRealidadeAumentadaContainer aluno />} />
        <Route
            path={ROUTES_PATH.LISTAR_ESCOLAS}
            element={<ListarEscolasContainer />}
        />
        <Route
            path={ROUTES_PATH.DIRETORES}
            element={<ListarDiretoresContainer />}
        />
        <Route
            path={ROUTES_PATH.CADASTRAR_ESCOLA}
            element={<CadastrarEscolaContainer />}
        />
        <Route
            path={ROUTES_PATH.CADASTRAR_DIRETOR}
            element={<CadastrarUsuarioContainer diretor />}
        />
        <Route
            path={ROUTES_PATH.RELATORIO_POR_TURMA_SECRETARIA}
            element={<RelatorioPorTurmaContainer secretaria />}
        />
        <Route
            path={ROUTES_PATH.RELATORIO_POR_TURMA_ESCOLA}
            element={<RelatorioPorTurmaContainer escola />}
        />
        <Route
            path={ROUTES_PATH.RELATORIO_POR_TURMA_PROFESSOR}
            element={<RelatorioPorTurmaContainer professor />}
        />
        <Route
            path={ROUTES_PATH.EDITAR_ESCOLA}
            element={<EditarEscolaContainer />}
        />
        <Route
          path={ROUTES_PATH.CADASTRAR_USUARIO}
          element={<CadastrarUsuarioContainer />}
        />
        <Route
          path={ROUTES_PATH.SECRETARIA}
          element={<ListarSecretariasContainer />}
        />
        <Route
            path={ROUTES_PATH.RANKING}
            element={<RankingContainer />}
        />
         <Route
            path={"/jogos"}
            element={<ListarJogosContainer />}
        />
        <Route
            path={ROUTES_PATH.RANKING_SECRETARIA}
            element={<RankingContainer secretaria />}
        />
        <Route
            path={ROUTES_PATH.RANKING_ESCOLA}
            element={<RankingContainer escola />}
        />
        <Route
            path={ROUTES_PATH.RANKING_PROFESSOR}
            element={<RankingContainer professor />}
        />
        <Route
            path={ROUTES_PATH.CADASTRAR_SECRETARIA}
            element={<CadastrarSecretariaContainer />}
        />
        <Route
            path={ROUTES_PATH.EDITAR_SECRETARIA}
            element={<EditarSecretariaContainer />}
        />
        <Route
            path={ROUTES_PATH.RESOLVER_SIMULADO}
            element={<ResolverSimuladoContainer />}
        />
        <Route
            path={ROUTES_PATH.RELATORIO_POR_PROVA}
            element={<RelatorioSimuladoContainer />}
        />
        <Route
            path={ROUTES_PATH.RELATORIO_POR_PROVA_ALUNO}
            element={<RelatorioSimuladoContainer aluno />}
        />
        <Route
            path={ROUTES_PATH.SIMULADOS_ALUNO}
            element={<ListarSimuladosContainer aluno />}
        />
        <Route
            path={ROUTES_PATH.RELATORIO_POR_TURMA}
            element={<RelatorioPorTurmaContainer />}
        />
        <Route
          path={ROUTES_PATH.EDITAR_USUARIO}
          element={<EditarUsuarioContainer />}
        />
        <Route
          path={ROUTES_PATH.GRUPOS_ACESSO}
          element={<ListarGruposDeAcessoContainer />}
        />
        <Route
          path={ROUTES_PATH.GRUPOS_ALUNO}
          element={<ListarGruposDeAlunosContainer />}
        />
        <Route
          path={ROUTES_PATH.CADASTRAR_GRUPOS_ACESSO}
          element={<CadastrarGrupoAcessoContainer />}
        />
        <Route
          path={ROUTES_PATH.EDITAR_GRUPOS_ACESSO}
          element={<EditarGrupoAcessoContainer />}
        />
        <Route path={ROUTES_PATH.LIVROS} element={<ListarLivrosContainer />} />
        <Route
          path={ROUTES_PATH.CADASTRA_LIVROS}
          element={<CadastrarLivrosContainer />}
        />
        <Route
          path={ROUTES_PATH.EDITAR_LIVROS}
          element={<EditarLivrosContainer />}
        />
        <Route path={ROUTES_PATH.JOGOS} element={<ListarJogosContainer />} />
        <Route
          path={ROUTES_PATH.CADASTRAR_JOGOS}
          element={<CadastrarJogosContainer />}
        />
        <Route
          path={ROUTES_PATH.EDITAR_JOGOS}
          element={<EditarJogosContainer />}
        />
        <Route
          path={ROUTES_PATH.REALIDADE_AUMENTADA}
          element={<ListarRealidadeAumentadaContainer />}
        />
        <Route
          path={ROUTES_PATH.CADASTRAR_REALIDADE_AUMENTADA}
          element={<CadastrarRealidadeAumentadaContainer />}
        />
        <Route
          path={ROUTES_PATH.EDITAR_REALIDADE_AUMENTADA}
          element={<EditarRealidadeAumentadaContainer />}
        />
        <Route
          path={ROUTES_PATH.SIMULADOS}
          element={<ListarSimuladosContainer />}
        />
        <Route
          path={ROUTES_PATH.EDITAR_SIMULADOS}
          element={<EditarSimuladoContainer />}
        />
        <Route
          path={ROUTES_PATH.CADASTRAR_SIMULADOS}
          element={<CadastrarSimuladoContainer />}
        />
        <Route path={ROUTES_PATH.ALUNOS} element={<ListarAlunosContainer />} />
        <Route
          path={ROUTES_PATH.PROFESSORES}
          element={<ListarProfessoresContainer />}
        />
        <Route
          path={ROUTES_PATH.EDITAR_ALUNO}
          element={<EditarUsuarioContainer aluno />}
        />
        <Route
            path={ROUTES_PATH.CADASTRAR_TURMA}
            element={<CadastrarTurmaContainer />}
        />
        <Route
            path={ROUTES_PATH.TURMAS}
            element={<ListarTurmasContainer />}
        />
        <Route
          path={ROUTES_PATH.EDITAR_GRUPOS_ALUNO}
          element={<EditarGrupoAlunosContainer />}
        />
        <Route
          path={ROUTES_PATH.MEUS_LIVROS_PROFESSOR}
          element={<MeusLivrosContainer />}
        />
        <Route
          path={ROUTES_PATH.CADASTRAR_ALUNO}
          element={<CadastrarUsuarioContainer aluno />}
        />
        <Route
          path={ROUTES_PATH.CADASTRAR_GRUPOS_ALUNO}
          element={<CadastrarGrupoAlunosContainer />}
        />
        <Route
            path="/teste"
              element={<Teste />}
        />
        <Route
          path={ROUTES_PATH.CADASTRAR_PROFESSOR}
          element={<CadastrarUsuarioContainer professor />}
        />
        <Route path={ROUTES_PATH.SAIR} element={<Sair />} />
        <Route
          path={ROUTES_PATH.RENDER_LIVRO}
          element={<VisualizarLivroContainer />}
        />
        <Route
            path={ROUTES_PATH.CORRECAO}
            element={<QuestoesParaCorrigirContainer />}
        />
        <Route
            path={ROUTES_PATH.RECUPERACAO}
            element={<ListarRecuperacaoContainer />}
        />
        <Route
            path={ROUTES_PATH.CADASTRAR_RECUPERACAO}
            element={<CadastrarRecuperacaoContainer />}
        />
        <Route
            path={ROUTES_PATH.RECUPERACAO_ALUNO}
            element={<ListarRecuperacaoContainer aluno />}
        />
        <Route
            path={ROUTES_PATH.CLONAR_SIMULADO}
            element={<ClonarSimuladoContainer />}
        />
        <Route
            path="/recuperacao/resolver/:id"
            element={<ResolverRecuperacaoContainer />}
        />
        <Route
            path="/turma/alunos/:id"
            element={<EditarAlunosContainer />}
        />
        <Route
            path="/escola/trumas/:id"
            element={<EditarTurmasContainer />}
        />
      </Routes>
    </>
  );
};
