import React, {useEffect, useState} from 'react'
import {Checkbox, Container, FormControlLabel, Grid, TextField} from "@material-ui/core";
import {Formik, FormikState} from "formik";
import {GrupoAcesso} from "../../types/GrupoAcesso";
import * as Yup from 'yup'
import styled from "styled-components";
import {PermissoesData} from "../../utils/PermissoesData";
import {Permissions} from "../../types/Permissions";
import * as R from 'ramda'
import {CancelButton, ConfirmButton} from "../Botoes/BotaoAcoes";
import {useNavigate} from "react-router-dom";
import {ROUTES_PATH} from "../RoutesPath";

export const StyledContainer = styled(Container)`
  margin-top: 1em;
  background: #FFF;
  max-height: 95vh;
  overflow-y: auto;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(92, 92, 92, 1);
  -moz-box-shadow: 0px 1px 3px 0px rgba(92, 92, 92, 1);
  box-shadow: 0px 1px 3px 0px rgba(92, 92, 92, 1);
`

const FormContainer = styled(Grid)`
  border: 1px solid rgba(0,0,0,0.2);
  padding: 10px;
  margin-top: 10px;
  max-height: 70vh;
  overflow-y: auto;
`

export type FormularioGrupoAcessoProps = {
    initialValues: GrupoAcesso
    onSubmit: (values: GrupoAcesso) => void;
    resetarForm?: boolean
}

export const FormularioGrupoAcesso = (props: FormularioGrupoAcessoProps) => {
    const [permissoes, setPermissoes] = useState<Permissions[]>(props.initialValues.permissionsArray ?? [])
    const [todas, setTodas] = useState<boolean>(false)
    const navigate = useNavigate()
    const onSubmit = async (
        values: GrupoAcesso,
        {
            resetForm,
            setStatus,
            setErrors,
        }: {
            resetForm: (
                nextState?: Partial<FormikState<GrupoAcesso>> | undefined
            ) => void;
            setStatus: any;
            setErrors: any;
        }
    ) => {
        try {
            await props.onSubmit(values);
            if (props.resetarForm) {
                setPermissoes([])
                setTodas(false)
                resetForm();
            }
        } catch (error: any) {
            setStatus({ success: false });
            setErrors({ submit: error.message });
        }
    };
    const schema = Yup.object().shape({
        name: Yup.string().required('Campo obrigatório.'),
    });
    useEffect(() => {
        setPermissoes(props.initialValues?.permissionsArray ?? [])
        setTodas(JSON.stringify(props.initialValues?.permissionsArray) === JSON.stringify(Object.values(Permissions)))
    }, [props.initialValues])
    return <>
        <Formik
            initialValues={props.initialValues}
            enableReinitialize
            validationSchema={schema}
            onSubmit={onSubmit}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue
              }) => (
                  <StyledContainer>
                    <form onSubmit={handleSubmit}>
                        <Container>
                            <Grid container spacing={2}>
                                <Grid item sm={12} xs={12}>
                                    <TextField
                                        label="Nome"
                                        variant="standard"
                                        id="name"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        fullWidth
                                        error={!!errors.name && touched.name}
                                        helperText={touched.name && errors.name ? errors.name : ''}
                                    />
                                </Grid>
                            </Grid>
                            <FormContainer container sm={12} xs={12}>
                                <Grid item sm={3}>
                                    <FormControlLabel
                                        checked={todas}
                                        control={
                                            <Checkbox
                                                onChange={(event, checked) => {
                                                    const novasPermissoes = checked ? Object.values(Permissions) : []
                                                    setTodas(checked)
                                                    setPermissoes(novasPermissoes)
                                                    setFieldValue('permissionsArray', novasPermissoes)
                                                }}
                                            />
                                        }
                                        label="Todas as permissões"
                                    />
                                </Grid>
                                {
                                    PermissoesData.map(item => (
                                        <>
                                            <Grid item xs={12}>
                                                <h2>{item.titulo}</h2>
                                            </Grid>
                                            {
                                                item.permissoes.map(p => (
                                                    <Grid item sm={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={R.includes(p.value, permissoes)}
                                                                    onChange={(event, checked) => {
                                                                        const index = permissoes.indexOf(p.value)
                                                                        const novoArray = checked ? R.append(p.value, permissoes) : R.remove(index, 1, permissoes);
                                                                        setPermissoes(novoArray)
                                                                        setFieldValue('permissionsArray', novoArray)
                                                                    }}
                                                                />
                                                            }
                                                            label={p.label}
                                                        />
                                                    </Grid>
                                                ))
                                            }
                                        </>
                                    ))
                                }
                            </FormContainer>
                            <Grid container spacing={2}>
                                <Grid item xs={4} style={{marginTop: "10px"}}>
                                    <ConfirmButton
                                        type="submit"
                                        fullWidth
                                    >
                                        Salvar
                                    </ConfirmButton>
                                </Grid>
                                <Grid item xs={4} style={{marginTop: "10px"}}>
                                    <CancelButton
                                        type="submit"
                                        fullWidth
                                        onClick={() => navigate(ROUTES_PATH.GRUPOS_ACESSO)}
                                    >
                                        Cancelar
                                    </CancelButton>
                                </Grid>
                            </Grid>
                        </Container>
                    </form>
                  </StyledContainer>
            )}
        </Formik>
    </>
}
