import { BaseApi } from './BaseApi';
import {IResponsePadrao} from "../types/IResponsePadrao";
import {FindAllParams} from "../types/FindAllParams";
import {Paginacao} from "../types/Paginacao";
import {Constants} from "../types/Constants";
import {Turma} from "../types/Turma";
import {UsuarioType} from "../types/Usuario";
export class TurmaApi extends BaseApi {
    async cadastrar(dados: Turma): Promise<IResponsePadrao<Turma>> {
        return (await this.api.post(`/turma`, dados)).data;
    }

    async buscarTodos(dados?: FindAllParams): Promise<IResponsePadrao<Paginacao<Turma>>> {
        const response = await this.api.get('/turma', {
            params: {
                page: dados?.page,
                take: dados?.page ? Constants.ITEMS_PER_PAGE : undefined,
                search: dados?.search
            }
        });
        return response.data;
    }

    async alunosPorTurma(turmaId: number): Promise<IResponsePadrao<UsuarioType[]>> {
        const response = await this.api.get(`/usuario/alunos/turmas/${turmaId}`);
        return response.data;
    }

    async porEscolaESerie(id: number, serie: string): Promise<IResponsePadrao<Turma[]>> {
        const response = await this.api.get(`/turma/escola/${id}/${serie}`);
        return response.data;
    }

    async porEscola(id: number): Promise<IResponsePadrao<Turma[]>> {
        const response = await this.api.get(`/turma/escola/${id}`);
        return response.data;
    }

    async porMateria(materia: string, secretariaId: number, escolaId: number): Promise<IResponsePadrao<Turma[]>> {
        const response = await this.api.get(`/turma/materia/${materia}/${secretariaId}/${escolaId}`);
        return response.data;
    }

    async porProfessor(id:number): Promise<IResponsePadrao<Turma[]>> {
        const response = await this.api.get(`/turma/professor/${id}`);
        return response.data;
    }

    async porMateria2(materia: string, escolaId: number): Promise<IResponsePadrao<Turma[]>> {
        const response = await this.api.get(`/turma/materia/${materia}/${escolaId}`);
        return response.data;
    }

    async porMateria3(materia: string, id: number): Promise<IResponsePadrao<Turma[]>> {
        const response = await this.api.get(`/turma/professor/materia/${materia}/${id}`);
        return response.data;
    }

    async excluir(id: number): Promise<IResponsePadrao<Turma>> {
        const response = await this.api.delete(`/turma/${id}`);
        return response.data;
    }

    async removerAlunoDaTurma(id: number, turmaId: number): Promise<IResponsePadrao<Turma>> {
        const response = await this.api.delete(`/professor-turma/${id}/${turmaId}`);
        return response.data;
    }

    async buscarUm(id: number): Promise<IResponsePadrao<Turma>> {
        const response = await this.api.get(`/turma/${id}`);
        return response.data;
    }

    async editar(dados: Turma, id: number): Promise<IResponsePadrao<Turma>> {
        const response = await this.api.put(`/turma/${id}`, dados);
        return response.data;
    }
}
