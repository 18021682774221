import React from 'react'
import {AiFillCheckCircle} from 'react-icons/ai'
import {StyledContainer} from "../TabelaBase/TabelaBase";
import styled from "styled-components";
import {Button} from "@material-ui/core";
import {ConfirmButton} from "../Botoes/BotaoAcoes";
import {useNavigate} from "react-router-dom";

const Container = styled(StyledContainer)`
  text-align: center;
  font-weight: 600;
  padding-top: 10%;
  font-size: 25px;
`

type Props = {
    certas: number
    total: number
    recuperacao?: boolean
}

export const TotalAcertos = (props: Props) => {
    const navigate = useNavigate();
    return <Container>
        <AiFillCheckCircle color="#0F0" size={100} /> <br/>
        Respostas registradas com sucesso.
        Você acertou {props.certas} de {props.total} questões <br />
        <ConfirmButton type="button" onClick={() => navigate(props.recuperacao ? '/recuperacao/aluno' : '/simulados/aluno')}>
            Voltar
        </ConfirmButton>
    </Container>
}