import React, { useEffect, useState } from "react";
import { Simulado } from "../../types/Simulado";
import { StyledContainer } from "../GrupoAcesso/FormularioGrupoAcesso";
import {
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextareaAutosize,
} from "@material-ui/core";
import { CancelButton, ConfirmButton } from "../Botoes/BotaoAcoes";
import { ROUTES_PATH } from "../RoutesPath";
import { Formik, FormikState } from "formik";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as R from "ramda";
import {Recuperacao} from "../../types/Recuperacao";

const Image = styled.img`
  max-width: 100%;
  max-height: 20em;
`;

const ImageE = styled.img`
  max-width: 600px;
  max-height: 600px;
  margin-left: 20%;
`;

export type ResolverSimuladoProps = {
  initialValues: Recuperacao;
  onSubmit: (prova: Recuperacao) => void;
};

export const ResolverRecuperacao = (props: ResolverSimuladoProps) => {
  const navigate = useNavigate();
  const [questoes, setQuestoes] = useState<Simulado[]>([]);
  useEffect(() => {
    setQuestoes(props.initialValues.simulados || []);
  }, [props.initialValues.simulados]);

  const onSubmit = async (
    values: Recuperacao,
    {
      resetForm,
      setStatus,
      setErrors,
    }: {
      resetForm: (nextState?: Partial<FormikState<Recuperacao>> | undefined) => void;
      setStatus: any;
      setErrors: any;
    }
  ) => {
    try {
      await props.onSubmit({
        ...values,
        simulados: questoes,
      });
      resetForm();
    } catch (error: any) {
      setStatus({ success: false });
      setErrors({ submit: error.message });
    }
  };
  return (
    <>
      <Formik
        initialValues={props.initialValues as Recuperacao}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <StyledContainer>
            <form onSubmit={handleSubmit}>
              <Container>
                <h2>Resolver recuperação</h2>
                {questoes.map((q, index) => (
                  <Grid container spacing={2} key={q.id}>
                    <Grid item sm={12}>
                      <h2>{`${q.titulo}`}</h2>
                    </Grid>
                    {q.imagemTitulo && (
                        <Grid item sm={12} justifyContent="center">
                          <ImageE alt="imagem-titulo" src={q.imagemTitulo} />
                        </Grid>
                    )}
                    <Grid item sm={12} style={{marginTop: '-35px'}}>
                      <h3>{`${q.enunciado}`}</h3>
                    </Grid>
                    {q.imagem && (
                      <Grid item sm={12} justifyContent="center">
                        <ImageE alt="imagem-enunciado" src={q.imagem} />
                      </Grid>
                    )}
                    {q.discursiva && (
                      <Grid item sm={12}>
                        <InputLabel>Resposta</InputLabel>
                        <TextareaAutosize
                          style={{ width: "100%" }}
                          rows={10}
                          onChange={(e) => {
                            const indexQuestao = questoes.indexOf(q);
                            const novaQuestao = questoes[indexQuestao];
                            novaQuestao.resposta = e.target.value;
                            const novoArray = R.update(
                              index,
                              novaQuestao,
                              questoes
                            );
                            setQuestoes(novoArray);
                          }}
                        />
                      </Grid>
                    )}
                    {!q.discursiva && (
                      <>
                        {
                          <Grid item sm={12}>
                            <FormControl>
                              <RadioGroup
                                key={q.opcoes.find((o) => o.selecionado)?.id}
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={q.opcoes.find((o) => o.selecionado)?.id}
                                onChange={(event, value) => {
                                  const selecionado = q.opcoes.find(
                                    (o) => o.id === Number(value)
                                  );
                                  const indexQuestao = questoes.indexOf(q);
                                  const indexOpcao = q.opcoes.indexOf(
                                    selecionado!
                                  );
                                  const novaOpcao = q.opcoes[indexOpcao];
                                  const novaQuestao = q;
                                  novaQuestao.opcoes[indexOpcao] = novaOpcao;
                                  for (const opcao of novaQuestao.opcoes) {
                                    opcao.selecionado =
                                      opcao.id === novaOpcao.id;
                                  }
                                  const novoArray = R.update(
                                    indexQuestao,
                                    novaQuestao,
                                    questoes
                                  );
                                  setQuestoes(novoArray);
                                }}
                              >
                                {q.opcoes.map((o) => (
                                  <React.Fragment key={o.id}>
                                    <FormControlLabel
                                      value={o.id}
                                      control={<Radio />}
                                      label={o.titulo}
                                    />{" "}
                                    <br />
                                    {o.imagem && (
                                      <Image
                                        alt="imagem-opcao"
                                        src={o.imagem}
                                      />
                                    )}
                                  </React.Fragment>
                                ))}
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        }
                      </>
                    )}
                  </Grid>
                ))}
                <Grid container spacing={2}>
                  <Grid item xs={4} style={{ marginTop: "10px" }}>
                    <ConfirmButton type="submit" fullWidth>
                      Salvar
                    </ConfirmButton>
                  </Grid>
                  <Grid item xs={4} style={{ marginTop: "10px" }}>
                    <CancelButton
                      type="submit"
                      fullWidth
                      onClick={() => navigate(ROUTES_PATH.SIMULADOS_ALUNO)}
                    >
                      Cancelar
                    </CancelButton>
                  </Grid>
                </Grid>
              </Container>
            </form>
          </StyledContainer>
        )}
      </Formik>
    </>
  );
};
