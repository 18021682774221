import React, {useCallback, useEffect, useState} from 'react'
import {ROUTES_PATH} from "../RoutesPath";
import {ColumnHeader} from "../TabelaBase/TabelaBase";
import {BotaoAcoes} from "../Botoes/BotaoAcoes";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useDispatch} from "react-redux";
import {useAlert} from "react-alert";
import {useNavigate} from "react-router-dom";
import {FindAllParams} from "../../types/FindAllParams";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {ListarJogos} from "./ListarJogos";
import {Jogo} from "../../types/Jogo";
import {ObterTipoUsuario} from "../../utils/ObterTipoUsuario";
import {TipoUsuario} from "../../types/TipoUsuario";

type ListarJogosContainerProps = {
    aluno?: boolean
}

export const ListarJogosContainer = (props: ListarJogosContainerProps) => {
    const tipoUsuario = ObterTipoUsuario();
    const [jogos, setJogos] = useState<Jogo[]>([])
    const [qtdPag, setQtdPag] = useState<number>(0)
    const [pesquisa, setPesquisa] = useState<string>('')
    const [page, setPage] = useState<number>(1)
    const api = useGrowApi();
    const dispatch = useDispatch()
    const alert = useAlert()
    const navigate = useNavigate()
    const headers: ColumnHeader[] = tipoUsuario === TipoUsuario.PROFESSOR ?
        [
            {
                label: '#',
                columnName: 'id'
            },
            {
                label: 'Nome',
                columnName: 'name'
            },
            {
                label: 'Livro relacionado',
                columnName: 'book.name',
                render: (value) => value ?? 'Não informado'
            },
            {
                label: 'Página do livro',
                columnName: 'page',
                render: (value) => value ?? 'Não informada'
            },
        ]
        : [
        {
            label: '#',
            columnName: 'id'
        },
        {
            label: 'Nome',
            columnName: 'name'
        },
        {
            label: 'Livro relacionado',
            columnName: 'book.name',
            render: (value) => value ?? 'Não informado'
        },
        {
            label: 'Página do livro',
            columnName: 'page',
            render: (value) => value ?? 'Não informada'
        },
        {
            label: 'Ações',
            columnName: 'id',
            render: (id) => <BotaoAcoes
                esconderExcluir={props.aluno}
                esconderEditar={props.aluno}
                onBotaoEditarClicado={() => navigate(`/jogos/editar/${id}`)}
                onBotaoExcluirClicado={async () => {
                    await handleExcluir(id)
                }}
            />
        }
    ]
    const buscaDados = useCallback(
        async (dados?: FindAllParams) => {
            try {
                dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
                const response = await api.Jogo.buscarTodos(dados);
                setQtdPag(response.data.count)
                setJogos(response.data.rows)
            } catch (e: any) {
                TratamentoErroPadrao(e, alert);
            } finally {
                dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
            }
        }, [page])
    const handleExcluir = useCallback(async (id: number) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Jogo.excluir(id)
            await buscaDados({page, search: pesquisa})
            alert.success(response.message, {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [])
    useEffect(() => {
        buscaDados({
            page,
            search: pesquisa
        })
    }, [page, pesquisa])
    return <>
        <ListarJogos
            esconderAdicionar={props.aluno}
            rows={jogos}
            cadastrarNovoLink={ROUTES_PATH.CADASTRAR_JOGOS}
            qtdPaginas={qtdPag}
            pesquisar={(pesquisa) => setPesquisa(pesquisa)}
            onPageChange={(page) => setPage(page)}
            headers={headers}
        />
    </>
}
