import React from "react";
import styled from 'styled-components';
import { SidebarData } from "./SidebarData";
import LogoTerraSul from "../../terrasul-logo.png";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Rotas} from "../Rotas";
import {ObterTipoUsuario} from "../../utils/ObterTipoUsuario";

const ImgQuestify = styled.div`
  padding-top: 10px;
`;

const Logo = styled.img`
  width: 90%;
`

const Container = styled.div`
  height: auto;
  width: 100vw;
  display: flex;
  overflow: hidden;
  background: #eaeaea;
`;

const Content = styled.div`
  height: 100vh;
  width: 250px;
  background-color: #0367A5;
  box-radius: 5px;
  overflow-y: auto;
  scrollbar-width: none;
  scrollbar-color: #ffffff #0367a5;
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #0367a5;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 5px;
    border: 2px dashed #ffffff;
  }
`;

const SidebarList = styled.div`
  height: auto;
  width: 100%;
  padding: 0;
  cursor: pointer;
`;

const SidebarDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  margin: 0;
`;

const SidebarIcon = styled.div`
  flex: 30%;
  display:grid;
  place-items:center;
`;

const SidebarTitulo = styled.div`
  flex: 70%;
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 13px;
`;
const SidebarTitulo1 = styled.div`
  flex: 70%;
  font-weight: bold;
  margin-top: 13px;
  color:#00F29F;
  text-transform: uppercase;
  font-size: 13px;
  width: 1%;
`;

const SidebarTopoFooter = styled.div`
  margin-top: 100px;
`;

const SidebarFooter = styled.div`
  font-family: 'ABeeZee',serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #A0AEC0;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const Sidebar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <Container>
      <Content>
        <SidebarList>
          <ImgQuestify>
            <Link to="/"><Logo src={LogoTerraSul} /></Link>
          </ImgQuestify>
          {SidebarData.map((val, key) => {
            return (
                val?.tiposNecessarios?.includes(ObterTipoUsuario()) &&
              <SidebarList key={key} onClick={() => {
                if (val.link) {
                  navigate(val.link)
                }
              }}>
                <SidebarDiv>
                  <SidebarIcon>
                    {val.icon}
                  </SidebarIcon>
                  {
                    location.pathname === val.link ?
                        <SidebarTitulo1>
                          {val.title}
                        </SidebarTitulo1>
                        :
                        <SidebarTitulo>
                          {val.title}
                        </SidebarTitulo>
                  }
                </SidebarDiv>
              </SidebarList>
            )
          })}
          <SidebarTopoFooter>
            <SidebarFooter>
              Termos de uso
            </SidebarFooter>
          </SidebarTopoFooter>
          <SidebarFooter>
            Política de privacidade
          </SidebarFooter>
          <SidebarFooter>
            Desenvolvido e hospedado por Grow Desenvolvimento
          </SidebarFooter>
          <SidebarFooter>
            @ 2022 Terra Sul Editora
          </SidebarFooter>
        </SidebarList>
      </Content>
      <Rotas />
    </Container>
  )
}
