import { BaseApi } from './BaseApi';
import {IResponsePadrao} from "../types/IResponsePadrao";
import {Secretaria} from "../types/Secretaria";
import {FindAllParams} from "../types/FindAllParams";
import {Paginacao} from "../types/Paginacao";
import {Constants} from "../types/Constants";
export class SecretariaApi extends BaseApi {
    async cadastrar(dados: Secretaria): Promise<IResponsePadrao<Secretaria>> {
        return (await this.api.post(`/secretaria`, dados)).data;
    }

    async buscarTodos(dados?: FindAllParams): Promise<IResponsePadrao<Paginacao<Secretaria>>> {
        const response = await this.api.get('/secretaria', {
            params: {
                page: dados?.page,
                take: dados?.page ? Constants.ITEMS_PER_PAGE : undefined,
                search: dados?.search
            }
        });
        return response.data;
    }

    async excluir(id: number): Promise<IResponsePadrao<Secretaria>> {
        const response = await this.api.delete(`/secretaria/${id}`);
        return response.data;
    }

    async buscarUm(id: number): Promise<IResponsePadrao<Secretaria>> {
        const response = await this.api.get(`/secretaria/${id}`);
        return response.data;
    }

    async editar(dados: Secretaria, id: number): Promise<IResponsePadrao<Secretaria>> {
        const response = await this.api.put(`/secretaria/${id}`, dados);
        return response.data;
    }
}
