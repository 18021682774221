import React, {useEffect, useState} from 'react'
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from "@material-ui/core";
import {CancelButton, ConfirmButton} from "../Botoes/BotaoAcoes";
import styled from "styled-components";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {useDispatch} from "react-redux";
import {useAlert} from "react-alert";
import {useGrowApi} from "../../hooks/useGrowApi";
import {UsuarioType} from "../../types/Usuario";
import * as R from 'ramda'
import {CadastrarUsuarioContainer} from "../Usuario/CadastrarUsuarioContainer";

type Props = {
    open: boolean
    onClose: () => void
    escolaId: number
    turmaId: number
}

const Box = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 30px;
  grid-column-gap: 10px;
`

const Box2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px;
`

export const ModalAdicionarAluno = (props: Props) => {
    const [alunos, setAlunos] = useState<UsuarioType[]>([])
    const [alunosEnviados, setAlunosEnviados] = useState<UsuarioType[]>([])
    const dispatch = useDispatch();
    const alert = useAlert();
    const api = useGrowApi();
    const [tela, setTela] = useState<1 | 2 | 3>(1)
    const handleClose = () => {
        props.onClose()
        setTela(1)
    }
    const cadastrar = async () => {
        try {
            dispatch({type: REALIZANDO_PROCESSAMENTO.ATIVO});
            for (const aluno of alunosEnviados) {
                await api.Usuario.cadastrarAlunoNaTurma(aluno?.id ?? 0, props.turmaId)
            }
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({type: REALIZANDO_PROCESSAMENTO.NENHUM});
        }
    }
    const obterAlunos = async () => {
        try {
            dispatch({type: REALIZANDO_PROCESSAMENTO.ATIVO});
            const response = await api.Usuario.buscarAlunosPorEscola(props.escolaId)
            setAlunos(response.data)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({type: REALIZANDO_PROCESSAMENTO.NENHUM});
        }
    }
    useEffect(() => {
        if (props.escolaId !== 0) {
            obterAlunos()
        }
    }, [props.escolaId])
    return <>
        <Dialog
            fullWidth
            maxWidth="md"
            open={props.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Adicionar alunos
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {
                        tela === 1 && <Box2>
                            <Button component="span"
                                    onClick={() => {
                                        setTela(3)
                                    }}
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                            >
                                Aluno existente
                            </Button>
                            <Button
                                component="span"
                                variant="contained"
                                fullWidth
                                onClick={() => {
                                    setTela(2)
                                }}
                                color="secondary"
                            >
                                Novo aluno
                            </Button>
                        </Box2>
                    }
                    {tela === 2 && <CadastrarUsuarioContainer aluno />}
                    {
                        tela === 3 && <>
                            <TableContainer style={{marginTop: '25px'}} component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">
                                                <Checkbox
                                                    checked={alunosEnviados === alunos}
                                                    onChange={(event, checked) => {
                                                        if (checked) {
                                                            setAlunosEnviados(alunos)
                                                        } else {
                                                            setAlunosEnviados([])
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="left">Aluno</TableCell>
                                            <TableCell align="left">CPF</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            alunos.map((t, key) => (
                                                <TableRow key={key}>
                                                    <TableCell component="th" scope="row">
                                                        <Checkbox
                                                            checked={alunosEnviados.includes(t)}
                                                            onChange={(event, checked) => {
                                                                if (checked) {
                                                                    setAlunosEnviados(prev => [...prev, t])
                                                                } else {
                                                                    const index = alunosEnviados.indexOf(t);
                                                                    const novoArray = R.remove(index, 1, alunosEnviados)
                                                                    setAlunosEnviados(novoArray)
                                                                }
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {t.name}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {t.cpf}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {tela === 3 &&
                    <ConfirmButton
                        onClick={async () => {
                            await cadastrar()
                            handleClose()
                        }}
                        type="button">
                        Salvar
                    </ConfirmButton>
                }
                <CancelButton onClick={handleClose}>Fechar</CancelButton>
            </DialogActions>
        </Dialog>
    </>
}
