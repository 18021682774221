import React, {useCallback, useEffect, useState} from 'react'
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useDispatch} from "react-redux";
import {useAlert} from "react-alert";
import {FormularioUsuario} from "./FormularioUsuario";
import {UsuarioType} from "../../types/Usuario";
import {GrupoAcesso} from "../../types/GrupoAcesso";
import {useParams} from "react-router-dom";
import {GrupoAlunos} from "../../types/GrupoAlunos";
import {Turma} from "../../types/Turma";

type Props = {
    aluno?: boolean
}

export const EditarUsuarioContainer = (props: Props) => {
    const api = useGrowApi();
    const dispatch = useDispatch()
    const {id} = useParams<{id: string}>()
    const alert = useAlert()
    const [turmas, setTurmas] = useState<Turma[]>([])
    const [originalEmail, setOriginalEmail] = useState<string>('')
    const [usuario, setUsuario] = useState<UsuarioType>({
        name: '',
        cpf: '',
        email: '',
        scopeId: 0,
        senha: '',
        confirmarSenha: ''
    })
    const [grupos, setGrupos] = useState<GrupoAcesso[]>([])
    const [grupos2, setGrupos2] = useState<GrupoAlunos[]>([])
    const buscaGrupos = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.GrupoAcesso.buscarTodos();
            setGrupos(response.data.rows)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [])
    const buscaGrupos2 = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.GrupoAlunos.buscarTodos();
            setGrupos2(response.data.rows)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [])
    const buscaTurmas = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Turma.buscarTodos();
            setTurmas(response.data.rows)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [alert, api.Turma, dispatch])
    const buscaDados = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Usuario.buscarUm(id!);
            setUsuario(response.data)
            setOriginalEmail(response.data.email ?? '')
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [id])
    const salvar = useCallback(async (dados: UsuarioType) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const compare = originalEmail === dados.email
            const response = await api.Usuario.editar({
                ...dados,
                email: compare ? undefined : dados.email,
                confirmarSenha: undefined,
                refreshToken: undefined,
                turmas: undefined,
            }, id!)
            alert.success(response.message, {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [originalEmail, usuario])

    useEffect(() => {
        buscaGrupos()
        buscaGrupos2()
        buscaDados()
        buscaTurmas()
    }, [buscaGrupos, buscaDados])
    return <>
        <FormularioUsuario
            aluno={props.aluno}
            turmas={turmas}
            titulo="Editar usuário"
            resetarForm={false}
            grupos={grupos}
            grupos2={grupos2}
            initialValues={usuario}
            onSubmit={salvar}
        />
    </>
}
