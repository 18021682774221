import React from 'react'
import { Secretaria } from '../../types/Secretaria';
import {FormularioSecretaria} from "./FormularioSecretaria";
import {useDispatch} from "react-redux";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useAlert} from "react-alert";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";

export const CadastrarSecretariaContainer = () => {
    const dispatch = useDispatch()
    const api = useGrowApi()
    const alert = useAlert()
    const cadastrar = async (dados: Secretaria) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Secretaria.cadastrar(dados);
            alert.success(response.message, {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }
    return <FormularioSecretaria
        resetarForm
        initialValues={{
            nome: '',
            nomeResponsavel1: '',
            nomeResponsavel2: '',
            nomeResponsavel3: '',
            nomeResponsavel4: '',
            emailResponsavel1: '',
            emailResponsavel2: '',
            emailResponsavel3: '',
            emailResponsavel4: '',
        } as Secretaria}
        onSubmit={cadastrar}
        titulo="Cadastrar Secretaria"
    />
}
