import React, {useCallback, useEffect, useState} from 'react'
import {QuestoesParaCorrigir} from "./QuestoesParaCorrigir";
import {ROUTES_PATH} from "../RoutesPath";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useDispatch} from "react-redux";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {FindAllParams} from "../../types/FindAllParams";
import {useAlert} from "react-alert";
import {Correcao} from "../../types/Correcao";
import {ColumnHeader} from "../TabelaBase/TabelaBase";
import {
    Button, Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    InputLabel,
    TextareaAutosize,
} from "@material-ui/core";
import {AuxButton, CancelButton, ConfirmButton, WarningButton} from "../Botoes/BotaoAcoes";

export const QuestoesParaCorrigirContainer = () => {
    const api = useGrowApi()
    const dispatch = useDispatch()
    const alert = useAlert()
    const [dados, setDados] = useState<Correcao[]>([])
    const [pesquisa, setPesquisa] = useState<string>("");
    const [qtdPag, setQtdPag] = useState<number>(0);
    const [open, setOpen] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [correcao, setCorrecao] = useState<Correcao>({
        simulado: {
            enunciado: ""
        },
        response: ""
    } as Correcao)
    const headers: ColumnHeader[] = [
        {
            label: "ID",
            columnName: "id",
        },
        {
            label: "Aluno",
            columnName: "user.name",
        },
        {
            label: "Questão",
            columnName: "simulado.enunciado",
            render: (value) => value.length > 20 ? `${value.slice(0, 20)}...` : value
        },
        {
            label: "Resposta",
            columnName: "response",
            render: (value) => value.length > 20 ? `${value.slice(0, 20)}...` : value
        },
        {
            label: "Corrigir",
            columnName: "id",
            render: (id) => <a href="#" onClick={() => {
                const atual = dados.find(d => d.id === id)
                setCorrecao(atual!)
                setOpen(true)
            }}>Corrigir</a>,
        },
    ];

    const buscaDados = async (dados?: FindAllParams) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Simulado.paraCorrigir(dados);
            setDados(response.data.rows);
            setQtdPag(response.data.count);
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }
    const corrigir = async (id: number, acertou: string) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Simulado.corrigir(id, acertou);
            alert.success(response.message, {
                timeout: 4000,
            });
            await buscaDados({page, search: pesquisa})
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }
    const handleClose = useCallback(() => {
        setOpen(false)
    }, [setOpen])

    useEffect(() => {
        buscaDados({
            page,
            search: pesquisa,
        })
    }, [page, pesquisa])
    return <>
       <QuestoesParaCorrigir
           rows={dados}
           cadastrarNovoLink={ROUTES_PATH.CADASTRAR_GRUPOS_ALUNO}
           pesquisar={(pesquisa) => setPesquisa(pesquisa)}
           onPageChange={(page) => setPage(page)}
           headers={headers}
           qtdPaginas={qtdPag}
       />
        <Dialog
            fullWidth
            maxWidth="md"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Corrigir questão
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Container>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <InputLabel>Enunciado</InputLabel>
                                <TextareaAutosize
                                    style={{width: "100%"}}
                                    readOnly
                                    rows={10}
                                    value={correcao.simulado.enunciado}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>Resposta</InputLabel>
                                <TextareaAutosize
                                    style={{width: "100%"}}
                                    readOnly
                                    rows={10}
                                    value={correcao.response}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <AuxButton onClick={handleClose}>Cancelar</AuxButton>
                <CancelButton onClick={async () => {
                    await corrigir(correcao.id, 'false')
                    setOpen(false)
                }}>Errado</CancelButton>
                <WarningButton onClick={async () => {
                    await corrigir(correcao.id, 'meio')
                    setOpen(false)
                }}>Parcial</WarningButton>
                <ConfirmButton onClick={async () => {
                    await corrigir(correcao.id, 'true')
                    setOpen(false)
                }}>
                    Certo
                </ConfirmButton>
            </DialogActions>
        </Dialog>
    </>
}
