import React, {useCallback, useEffect, useState} from 'react'
import {ListarSecretarias} from "./ListarSecretarias";
import {ROUTES_PATH} from "../RoutesPath";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useDispatch} from "react-redux";
import {useAlert} from "react-alert";
import {useNavigate} from "react-router-dom";
import {Secretaria} from "../../types/Secretaria";
import {ColumnHeader} from "../TabelaBase/TabelaBase";
import {BotaoAcoes, CancelButton} from "../Botoes/BotaoAcoes";
import {FindAllParams} from "../../types/FindAllParams";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import {Usuario} from "../Usuario/Usuario";
import { UsuarioType } from '../../types/Usuario';
export const ListarSecretariasContainer = () => {
    const [usuarios, setUsuarios] = useState<UsuarioType[]>([])
    const [open, setOpen] = useState<boolean>(false)
    const [dados, setDados] = useState<Secretaria[]>([])
    const [qtdPag, setQtdPag] = useState<number>(0)
    const [pesquisa, setPesquisa] = useState<string>('')
    const [page, setPage] = useState<number>(1)
    const api = useGrowApi();
    const dispatch = useDispatch()
    const alert = useAlert()
    const navigate = useNavigate()
    const headers: ColumnHeader[] = [
        {
            label: '#',
            columnName: 'id'
        },
        {
            label: 'Nome',
            columnName: 'nome'
        },
        {
            label: 'Ações',
            columnName: 'id',
            render: (id) => <BotaoAcoes
                onBotaoEditarClicado={() => navigate(`/secretarias/editar/${id}`)}
                onBotaoExcluirClicado={async () => {
                    await handleExcluir(id)
                }}
                linksExtras={<>
                <MenuItem
                        onClick={async () => {
                            await obterDiretoresSecretaria(id)
                            setOpen(true)
                        }}
                    >
                        Diretores
                    </MenuItem>
                </>}
            />
        }
    ]

    const headers2: ColumnHeader[] = [
        {
            label: '#',
            columnName: 'id'
        },
        {
            label: 'Nome',
            columnName: 'name'
        },
        {
            label: 'E-mail',
            columnName: 'email'
        },
        {
            label: 'Escola',
            columnName: 'escola.nome'
        },
        {
            label: 'Ações',
            columnName: 'id',
            render: (id) => <BotaoAcoes
                onBotaoEditarClicado={() => navigate(`/alunos/editar/${id}`)}
                onBotaoExcluirClicado={async () => {
                    await handleExcluir2(id)
                }}
            />
        }
    ]
    const buscaDados = useCallback(
        async (dados?: FindAllParams) => {
            try {
                dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
                const response = await api.Secretaria.buscarTodos(dados);
                setQtdPag(response.data.count)
                setDados(response.data.rows)
            } catch (e: any) {
                TratamentoErroPadrao(e, alert);
            } finally {
                dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
            }
        }, [page])
        const handleExcluir2 = useCallback(async (id: number) => {
            try {
                dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
                const response = await api.Usuario.excluir(id)
                await buscaDados({page, search: pesquisa})
                alert.success(response.message, {
                    timeout: 4000
                })
            } catch (e: any) {
                TratamentoErroPadrao(e, alert);
            } finally {
                dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
            }
        }, [alert, api.Usuario, buscaDados, dispatch, page, pesquisa])
    const obterDiretoresSecretaria = async (id: number) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Usuario.buscarDiretoresPorSecretaria(id)
            setUsuarios(response.data);
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }        
    const handleExcluir = useCallback(async (id: number) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Secretaria.excluir(id)
            await buscaDados({page, search: pesquisa})
            alert.success(response.message, {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [page, pesquisa])
    const handleClose = () => {
        setOpen(false)
    }
    useEffect(() => {
        buscaDados({
            page,
            search: pesquisa
        })
    }, [page, pesquisa])
    return <>
        <ListarSecretarias
            rows={dados}
            pesquisar={(pesquisa) => setPesquisa(pesquisa)}
            onPageChange={(page) => setPage(page)}
            cadastrarNovoLink={ROUTES_PATH.CADASTRAR_SECRETARIA}
            headers={headers}
            qtdPaginas={qtdPag}
        />
         <Dialog
            fullWidth
            maxWidth="md"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Diretores de escola
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Usuario
                        rows={usuarios}
                        esconderAdicionar
                        cadastrarNovoLink={ROUTES_PATH.CADASTRAR_DIRETOR}
                        qtdPaginas={qtdPag}
                        pesquisar={(pesquisa) => setPesquisa(pesquisa)}
                        onPageChange={(page) => setPage(page)}
                        headers={headers2}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleClose}>Fechar</CancelButton>
            </DialogActions>
        </Dialog>
    </>
}
