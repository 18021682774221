import React, {useEffect, useState} from 'react'
import {useDispatch} from "react-redux";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useAlert} from "react-alert";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {FormularioSimulados} from "./FormularioSimulados";
import {OpcaoSimulado} from "../../types/Simulado";
import {v4 as uuid} from "uuid";
import {Recuperacao} from "../../types/Recuperacao";
import {UsuarioType} from "../../types/Usuario";

export const CadastrarRecuperacaoContainer = () => {
    const dispatch = useDispatch()
    const api = useGrowApi()
    const alert = useAlert()
    const [alunos, setAlunos] = useState<UsuarioType[]>([])
    const cadastrar = async (dados: Recuperacao) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const {simulados, ...complemento} = dados
            const ids: number[] = []
            for (const aluno of dados?.alunos!) {
                ids.push(aluno?.id!)
            }
            const response = await api.Recuperacao.cadastrar({
                ...complemento,
                alunoIds: ids,
                alunos: [],
            })
            for (const simulado of simulados!) {
                const novo = await api.Simulado.cadastrar({
                    ...simulado,
                    recuperacaoId: response?.data?.id
                });
                if (simulado.titulo) {
                    const formData = new FormData();
                    formData.append('titulo', simulado.titulo);
                    if (simulado.fileTitulo) {
                        formData.append('file', simulado.fileTitulo);
                    }
                    await api.Simulado.cadastrarTitulo(formData, Number(novo?.data?.id ?? 0));
                }
                alert.success(response.message, {
                    timeout: 4000
                })
            }
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }
    const obterAlunos = async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const {data} = await api.Usuario.buscarAlunos();
            setAlunos(data.rows)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }
    useEffect(() => {
        obterAlunos()
    }, [])
    return <>
        <FormularioSimulados
            alunos={alunos}
            initialValues={{
                nome: "",
                revelarNota: false,
                alunoIds: [],
                alunos: [],
                simulados: [
                    {
                        id: uuid(),
                        enunciado: '',
                        discursiva: false,
                        opcoes: [
                            {
                                id: uuid(),
                                titulo: '',
                                correto: false
                            } as OpcaoSimulado,
                            {
                                id: uuid(),
                                titulo: '',
                                correto: false
                            } as OpcaoSimulado
                        ]
                    }
                ]
            }}
            onSubmit={cadastrar}
            resetarForm
            titulo="Cadastrar Recuperação"
        />
    </>
}
