import {
	Backdrop,
	CircularProgress,
	createStyles,
	makeStyles,
	Theme,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff',
		},
	})
);

export type AppBackdropProps = {
	mostrar: boolean;
};

const AppBackdrop = (props: AppBackdropProps) => {
	const classes = useStyles();

	return (
		<Backdrop className={classes.backdrop} open={props.mostrar}>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
};

export { AppBackdrop };
