import { BaseApi } from './BaseApi';
import {Paginacao} from "../types/Paginacao";
import {FindAllParams} from "../types/FindAllParams";
import {IResponsePadrao} from "../types/IResponsePadrao";
import {Constants} from "../types/Constants";
import {Jogo} from "../types/Jogo";

export class JogoApi extends BaseApi {
    async buscarTodos(dados?: FindAllParams): Promise<IResponsePadrao<Paginacao<Jogo>>> {
        const response = await this.api.get('/games', {
            params: {
                page: dados?.page,
                take: Constants.ITEMS_PER_PAGE,
                search: dados?.search
            }
        });
        return response.data;
    }

    async score(dados: any): Promise<any> {
        await this.api.post(`/games-score`, dados)
    }
    async buscarUm(id: number): Promise<IResponsePadrao<Jogo>> {
        const response = await this.api.get(`/games/${id}`);
        return response.data;
    }

    async editar(dados: Jogo, id: number): Promise<IResponsePadrao<Jogo>> {
        const formData = new FormData();
        formData.append("name", dados?.name ?? '')
        if (dados.file){
            formData.append("file", dados.file)
        }
        formData.append("bookId", String(dados.book.id))
        if (dados.page) {
            formData.append("page", String(dados.page))
        }
        return (await this.api.put(`/games/update-file/${id}`, formData)).data;
    }

    async cadastrar(dados: Jogo): Promise<IResponsePadrao<Jogo>> {
        const formData = new FormData();
        formData.append("name", dados?.name ?? '')
        formData.append("file", dados.file!)
        formData.append("bookId", String(dados.book.id))
        if (dados.page) {
            formData.append("page", String(dados.page))
        }
        return (await this.api.post(`/games`, formData)).data;
    }

    async excluir(id: number): Promise<IResponsePadrao<Jogo>> {
        const response = await this.api.delete(`/games/${id}`);
        return response.data;
    }
}
