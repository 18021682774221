import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import { RootState } from './redux/RootState';
import { LoginContainer } from './components/Login/LoginContainer';
import { AppBackdrop } from './components/AppBackdrop';
import {Menu} from "./components/Template/Menu";

function App() {
  const usuarioLogado = useSelector((state: RootState) => state.usuarioLogado);
  const mostrarProcessamento = useSelector(
      (state: RootState) => state.realizandoProcessamento
  );
  useEffect(() => {
      console.log(usuarioLogado)
  }, [usuarioLogado])
  return (
      <div className="App">
        <AppBackdrop mostrar={mostrarProcessamento === 'ATIVO'} />
        {usuarioLogado?.access_token ? <Menu /> : <LoginContainer />}
      </div>
  );
}

export default App;
