import React, {useCallback, useEffect, useState} from 'react'
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useDispatch, useSelector} from "react-redux";
import {useAlert} from "react-alert";
import {FormularioUsuario} from "./FormularioUsuario";
import {UsuarioType} from "../../types/Usuario";
import {GrupoAcesso} from "../../types/GrupoAcesso";
import {GrupoAlunos} from "../../types/GrupoAlunos";
import {Secretaria} from "../../types/Secretaria";
import {Turma} from "../../types/Turma";
import {RootState} from "../../redux/RootState";
import {Escola} from "../../types/Escola";

type Props = {
    aluno?: boolean
    professor?: boolean
    diretor?: boolean
}

export const CadastrarUsuarioContainer = (props: Props) => {
    const usuarioLogado = useSelector((state: RootState) => state.usuarioLogado);
    const api = useGrowApi();
    const dispatch = useDispatch()
    const alert = useAlert()
    const [usuario, setUsuario] = useState<UsuarioType>({
        name: '',
        email: '',
        scopeId: 0,
        senha: '',
        confirmarSenha: '',
        cpf: '',
        materiasSelecionadas: [],
        turmas: [],
    })
    const [grupos, setGrupos] = useState<GrupoAcesso[]>([])
    const [grupos2, setGrupos2] = useState<GrupoAlunos[]>([])
    const [secretarias, setSecretarias] = useState<Secretaria[]>([])
    const [turmas, setTurmas] = useState<Turma[]>([])
    const [escolas, setEscolas] = useState<Escola[]>([])
    const buscaGrupos = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.GrupoAcesso.buscarTodos();
            setGrupos(response.data.rows)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [alert, api.GrupoAcesso, dispatch])
    const buscaSecretarias = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Secretaria.buscarTodos();
            setSecretarias(response.data.rows)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [alert, api.Secretaria, dispatch])
    const buscaTurmas = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Turma.buscarTodos();
            setTurmas(response.data.rows)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [alert, api.Turma, dispatch])
    const buscaGrupos2 = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.GrupoAlunos.buscarTodos();
            setGrupos2(response.data.rows)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [alert, api.GrupoAcesso, dispatch, setGrupos2])
    const buscaEscolas = async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Escola.buscarTodos();
            setEscolas(response.data.rows)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }
    const cadastrar = useCallback(async (dados: UsuarioType) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = !props.professor ? await api.Usuario.cadastrar({
                ...dados,
                email: dados?.email && dados?.email?.length > 0 ? dados.email : undefined,
                student: props.aluno,
                teacher: props.professor,
                cpf: props.aluno || props.professor ? dados.cpf?.split('.').join('').split('-').join('') : undefined,
                scopeId: undefined,
                secretariaId: dados.tipo === "Secretaria" ? dados.secretariaId : undefined,
                escolaId: props.professor ? usuarioLogado?.escolaId : props.diretor ? dados.escola?.id : undefined,
                turmasIds: dados?.turmas?.map(t => t.id) ?? [],
                turmas: undefined
            }) : await api.Usuario.cadastrarProfessor({
                ...dados,
                email: dados?.email && dados?.email?.length > 0 ? dados.email : undefined,
                student: props.aluno,
                materias: dados.materiasSelecionadas?.join(","),
                teacher: props.professor,
                cpf: props.aluno || props.professor ? dados.cpf?.split('.').join('').split('-').join('') : undefined,
                scopeId: !props.aluno && !props.professor && dados.tipo !== "Secretaria" ? dados.scopeId : undefined,
                secretariaId: dados.tipo === "Secretaria" ? dados.secretariaId : undefined,
                escolaId: props.professor ? usuarioLogado?.escolaId : undefined,
                turmasIds: dados?.turmas?.map(t => t.id) ?? [],
                turmas: undefined,
            })
            alert.success(response.message, {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [alert, api.Usuario, dispatch, props.aluno, props.professor])

    useEffect(() => {
        buscaGrupos()
        buscaGrupos2()
        buscaSecretarias()
        buscaTurmas()
        buscaEscolas()
    }, [])
    return <>
        <FormularioUsuario
            turmas={turmas}
            diretor={props.diretor}
            secretarias={secretarias}
            aluno={props.aluno}
            escolas={escolas}
            professor={props.professor}
            titulo={props.aluno ? "Cadastrar aluno" : props.professor ? "Cadastrar Professor" : props.diretor ? "Cadastrar diretor" : "Cadastrar usuário"}
            resetarForm
            grupos={grupos}
            grupos2={grupos2}
            initialValues={usuario}
            onSubmit={cadastrar}
        />
    </>
}
