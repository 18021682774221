import { BaseApi } from './BaseApi';
import {Paginacao} from "../types/Paginacao";
import {FindAllParams} from "../types/FindAllParams";
import {IResponsePadrao} from "../types/IResponsePadrao";
import {Constants} from "../types/Constants";
import {Simulado} from "../types/Simulado";
import {Score} from "../types/Score";
import {RelatorioPorTurmaType} from "../types/RelatorioPorTurma";
import {Correcao} from "../types/Correcao";
import {RankingResponse} from "../types/RankingResponse";

export class SimuladoApi extends BaseApi {
    async buscarTodos(dados?: FindAllParams): Promise<IResponsePadrao<Paginacao<Simulado>>> {
        const response = await this.api.get('/simulado', {
            params: {
                page: dados?.page,
                take: Constants.ITEMS_PER_PAGE,
                search: dados?.search
            }
        });
        return response.data;
    }

    async relatorioPorProva(provaId?: number, beginDate?: Date, endDate?: Date): Promise<IResponsePadrao<Paginacao<Score>>> {
        const response = await this.api.get('/games-score/relatorios/por-prova', {
            params: {
                provaId,
                beginDate,
                endDate
            }
        });
        return response.data;
    }

    async relatorioPorProvaAluno(provaId?: number, beginDate?: Date, endDate?: Date): Promise<IResponsePadrao<Paginacao<Score>>> {
        const response = await this.api.get('/games-score/relatorios/por-prova/aluno', {
            params: {
                provaId,
                beginDate,
                endDate
            }
        });
        return response.data;
    }

    async relatorioPorTurma(grupoAlunoId: number, beginDate?: Date, endDate?: Date): Promise<IResponsePadrao<Paginacao<RelatorioPorTurmaType>>> {
        const response = await this.api.get('/games-score/relatorios/por-turma', {
            params: {
                grupoAlunoId,
                beginDate,
                endDate
            }
        });
        return response.data;
    }

    async relatorioPorAluno(grupoAlunoId: number, beginDate?: Date, endDate?: Date): Promise<IResponsePadrao<Paginacao<RelatorioPorTurmaType>>> {
        const response = await this.api.get('/games-score/relatorios/por-aluno', {
            params: {
                grupoAlunoId,
                beginDate,
                endDate
            }
        });
        return response.data;
    }

    async relatorioPorEscola(grupoAlunoId: number, beginDate?: Date, endDate?: Date): Promise<IResponsePadrao<Paginacao<RelatorioPorTurmaType>>> {
        const response = await this.api.get('/games-score/relatorios/por-escola', {
            params: {
                grupoAlunoId,
                beginDate,
                endDate
            }
        });
        return response.data;
    }

    async relatorioPorMateria(materia: string, grupoAlunoId: number, beginDate?: Date, endDate?: Date): Promise<IResponsePadrao<Paginacao<RelatorioPorTurmaType>>> {
        const response = await this.api.get('/games-score/relatorios/por-materia', {
            params: {
                materia,
                grupoAlunoId,
                beginDate,
                endDate
            }
        });
        return response.data;
    }

    async relatorioPorSecretaria(grupoAlunoId: number, beginDate?: Date, endDate?: Date): Promise<IResponsePadrao<Paginacao<RelatorioPorTurmaType>>> {
        const response = await this.api.get('/games-score/relatorios/por-secretaria', {
            params: {
                grupoAlunoId,
                beginDate,
                endDate
            }
        });
        return response.data;
    }

    async relatorioSemFiltro(grupoAlunoId: number, beginDate?: Date, endDate?: Date): Promise<IResponsePadrao<Paginacao<RelatorioPorTurmaType>>> {
        const response = await this.api.get('/games-score/relatorios/sem-filtro', {
            params: {
                grupoAlunoId,
                beginDate,
                endDate
            }
        });
        return response.data;
    }

    async rankingSemFiltro(beginDate?: Date, endDate?: Date): Promise<IResponsePadrao<RankingResponse[]>> {
        const response = await this.api.get('/games-score/ranking', {
            params: {
                beginDate,
                endDate
            }
        });
        return response.data;
    }

    async rankingPorSecretaria(id: number, beginDate?: Date, endDate?: Date): Promise<IResponsePadrao<RankingResponse[]>> {
        const response = await this.api.get(`/games-score/ranking/secretaria/${id}`, {
            params: {
                beginDate,
                endDate
            }
        });
        return response.data;
    }

    async rankingPorEscola(id: number, beginDate?: Date, endDate?: Date): Promise<IResponsePadrao<RankingResponse[]>> {
        const response = await this.api.get(`/games-score/ranking/escola/${id}`, {
            params: {
                beginDate,
                endDate
            }
        });
        return response.data;
    }

    async rankingPorProfessorETurma(id: number, beginDate?: Date, endDate?: Date): Promise<IResponsePadrao<RankingResponse[]>> {
        const response = await this.api.get(`/games-score/ranking/professor/turma/${id}`, {
            params: {
                beginDate,
                endDate
            }
        });
        return response.data;
    }

    async rankingPorTurma(id: number, beginDate?: Date, endDate?: Date): Promise<IResponsePadrao<RankingResponse[]>> {
        const response = await this.api.get(`/games-score/ranking/turma/${id}`, {
            params: {
                beginDate,
                endDate
            }
        });
        return response.data;
    }

    async rankingPorMateria(materia: string, grupoAlunoId: number, beginDate?: Date, endDate?: Date): Promise<IResponsePadrao<RankingResponse[]>> {
        const response = await this.api.get(`/games-score/ranking/materia`, {
            params: {
                materia,
                grupoAlunoId,
                beginDate,
                endDate
            }
        });
        return response.data;
    }

    async buscarUm(id: number): Promise<IResponsePadrao<Simulado>> {
        const response = await this.api.get(`/simulado/${id}`);
        return response.data;
    }

    async maisAcertos(): Promise<IResponsePadrao<Simulado[]>> {
        const response = await this.api.get(`/simulado/mais-acertos`);
        return response.data;
    }

    async maisErros(): Promise<IResponsePadrao<Simulado[]>> {
        const response = await this.api.get(`/simulado/mais-erros`);
        return response.data;
    }

    async maisRespondidas(): Promise<IResponsePadrao<Simulado[]>> {
        const response = await this.api.get(`/simulado/mais-respondidas`);
        return response.data;
    }

    async excluir(id: number): Promise<IResponsePadrao<Simulado>> {
        const response = await this.api.delete(`/simulado/${id}`);
        return response.data;
    }

    async cadastrarTitulo(formData: FormData, id: number): Promise<IResponsePadrao<Simulado>> {
        const response = await this.api.patch(`/simulado/titulo/${id}`, formData);
        return response.data;
    }

    async cadastrar(dados: Simulado): Promise<IResponsePadrao<Simulado>> {
        if (dados.file){
            const formData = new FormData()
            formData.append('enunciado', dados.enunciado)
            formData.append('provaId', String(dados.provaId))
            formData.append('discursiva', String(dados.discursiva))
            formData.append('file', dados.file)
            const response = await this.api.post(`/simulado`, formData);
            const opcoesFormatadas = [];
            for (const opcao of dados.opcoes) {
                opcoesFormatadas.push({
                    titulo: opcao.titulo,
                    correto: opcao.correto,
                    simuladoId: (response.data as IResponsePadrao<Simulado>).data.id,
                    file: opcao.file
                })
            }
            if (!dados.discursiva) {
                await this.cadastraOpcoes(opcoesFormatadas)
            }
            return response.data;
        }
        const response = await this.api.post(`/simulado`, {
            ...dados,
            book: undefined,
            opcoes: []
        });
        const opcoesFormatadas = [];
        for (const opcao of dados.opcoes) {
            opcoesFormatadas.push({
                titulo: opcao.titulo,
                correto: opcao.correto,
                simuladoId: (response.data as IResponsePadrao<Simulado>).data.id,
                file: opcao.file
            })
        }
        if (!dados.discursiva) {
            await this.cadastraOpcoes(opcoesFormatadas)
        }
        return response.data;
    }

    async editar(dados: Simulado, id: number): Promise<IResponsePadrao<Simulado>> {
        const opcoesFormatadas = [];
        for (const opcao of dados.opcoes) {
            opcoesFormatadas.push({
                titulo: opcao.titulo,
                correto: opcao.correto
            })
        }
        const response = await this.api.put(`/simulado/${id}`, {
            ...dados,
            book: undefined,
            //bookId: dados.book.id,
            opcoes: opcoesFormatadas
        });
        return response.data;
    }

    async paraCorrigir(dados?: FindAllParams): Promise<IResponsePadrao<Paginacao<Correcao>>> {
        const response = await this.api.get(`/games-score/to-be-corrected`, {
            params: {
                page: dados?.page,
                take: Constants.ITEMS_PER_PAGE,
                search: dados?.search
            }
        });
        return response.data;
    }

    async corrigir(id: number, acertou: string): Promise<IResponsePadrao<Correcao>> {
        const response = await this.api.patch(`/games-score/correction`, {}, {
            params: {
                id,
                acertou,
            }
        });
        return response.data;
    }

    private async cadastraOpcoes(opcoes: {
        titulo: string,
        correto: boolean,
        simuladoId: string | number,
        file?: File
    }[]): Promise<void> {
        for (const opcao of opcoes) {
            const formData = new FormData()
            formData.append("titulo", opcao.titulo)
            formData.append("correto", String(opcao.correto))
            formData.append("simuladoId", String(opcao.simuladoId))
            if (opcao.file) {
                formData.append("file", opcao.file)
            }
            await this.api.post(`/opcao-simulado`, formData);
        }
    }
}
