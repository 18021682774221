import { LoginApi } from './LoginApi';
import {UsuarioApi} from "./UsuarioApi";
import {GrupoAcessoApi} from "./GrupoAcessoApi";
import {LivroApi} from "./LivroApi";
import {JogoApi} from "./JogoApi";
import {RealidadeAumentadaApi} from "./RealidadeAumentadaApi";
import {SimuladoApi} from "./SimuladoApi";
import {GrupoAlunosApi} from "./GrupoAlunosApi";
import {ProvaApi} from "./ProvaApi";
import {SecretariaApi} from "./SecretariaApi";
import {EscolaApi} from "./EscolaApi";
import {TurmaApi} from "./TurmaApi";
import {RecuperacaoApi} from "./RecuperacaoApi";

export class GrowApi {
	constructor() {
		this.Login = new LoginApi();
		this.Usuario = new UsuarioApi();
		this.GrupoAcesso = new GrupoAcessoApi();
		this.Livro = new LivroApi();
		this.Jogo = new JogoApi();
		this.RealidadeAumentada = new RealidadeAumentadaApi();
		this.Simulado = new SimuladoApi();
		this.GrupoAlunos = new GrupoAlunosApi();
		this.Prova = new ProvaApi();
		this.Secretaria = new SecretariaApi();
		this.Escola = new EscolaApi();
		this.Turma = new TurmaApi();
		this.Recuperacao = new RecuperacaoApi();
	}

	public readonly Turma: TurmaApi;
	public readonly Escola: EscolaApi;
	public readonly Prova: ProvaApi;
	public readonly Jogo: JogoApi;
	public readonly Livro: LivroApi;
	public readonly GrupoAcesso: GrupoAcessoApi;
	public readonly GrupoAlunos: GrupoAlunosApi;
	public readonly Login: LoginApi;
	public readonly Usuario: UsuarioApi;
	public readonly RealidadeAumentada: RealidadeAumentadaApi;
	public readonly Simulado: SimuladoApi;
	public readonly Secretaria: SecretariaApi;
	public readonly Recuperacao: RecuperacaoApi;
}
