import React, {useCallback, useEffect, useState} from 'react'
import {ROUTES_PATH} from "../RoutesPath";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useDispatch} from "react-redux";
import {useAlert} from "react-alert";
import {AdicionarNovo, ColumnHeader, TabelaBase} from "../TabelaBase/TabelaBase";
import {BotaoAcoes} from "../Botoes/BotaoAcoes";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {useParams} from "react-router-dom";
import {UsuarioType} from "../../types/Usuario";
import {ModalAdicionarAluno} from "./ModalAdicionarAluno";
import {Turma} from "../../types/Turma";

export const EditarAlunosContainer = () => {
    const {id} = useParams<{ id: string }>()
    const [openAluno, setOpenAluno] = useState<boolean>(false)
    const [turma, setTurma] = useState<Turma>()
    const [dados, setDados] = useState<UsuarioType[]>([])
    const [qtdPag, setQtdPag] = useState<number>(0)
    const [pesquisa, setPesquisa] = useState<string>('')
    const [page, setPage] = useState<number>(1)
    const api = useGrowApi();
    const dispatch = useDispatch()
    const alert = useAlert()
    const headers: ColumnHeader[] = [
        {
            label: '#',
            columnName: 'id'
        },
        {
            label: 'Escola',
            columnName: 'escola.nome'
        },
        {
            label: 'Aluno',
            columnName: 'name'
        },
        {
            label: 'CPF',
            columnName: 'cpf'
        },
        {
            label: 'E-mail',
            columnName: 'email'
        },
        {
            label: 'Ações',
            columnName: 'id',
            render: (id) => <BotaoAcoes
                esconderEditar
                onBotaoEditarClicado={() => {
                }}
                onBotaoExcluirClicado={async () => {
                    await handleExcluir(id)
                }}
            />
        }
    ]
    const buscaDados = useCallback(
        async () => {
            try {
                dispatch({type: REALIZANDO_PROCESSAMENTO.ATIVO});
                const turma = await api.Turma.buscarUm(Number(id));
                setTurma(turma.data)
                const response = await api.Turma.alunosPorTurma(Number(id));
                setDados(response.data)
            } catch (e: any) {
                TratamentoErroPadrao(e, alert);
            } finally {
                dispatch({type: REALIZANDO_PROCESSAMENTO.NENHUM});
            }
        }, [page])
    const handleExcluir = useCallback(async (userId: number) => {
        try {
            dispatch({type: REALIZANDO_PROCESSAMENTO.ATIVO});
            const response = await api.Turma.removerAlunoDaTurma(userId, Number(id))
            await buscaDados()
            alert.success(response.message, {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({type: REALIZANDO_PROCESSAMENTO.NENHUM});
        }
    }, [page, pesquisa])
    useEffect(() => {
        buscaDados()
    }, [])
    return <>
        <TabelaBase
            extra={<>
                <h2>Turma: {turma?.nome ?? ''}</h2>
                <AdicionarNovo to="#" onClick={() => setOpenAluno(true)}>Adicionar aluno</AdicionarNovo>
            </>
            }
            esconderPesquisar
            rows={dados}
            pesquisar={(pesquisa: any) => setPesquisa(pesquisa)}
            onPageChange={(page: any) => setPage(page)}
            cadastrarNovoLink={ROUTES_PATH.CADASTRAR_TURMA}
            esconderAdicionar
            headers={headers}
            qtdPaginas={qtdPag}
        />
        <ModalAdicionarAluno turmaId={turma?.id ?? 0} escolaId={turma?.escolaId ?? 0} open={openAluno} onClose={() => {
            setOpenAluno(false)
            buscaDados()
        }} />
    </>
}