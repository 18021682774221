import React, {useCallback, useEffect, useState} from 'react'
import {FormularioGrupoAcesso} from "./FormularioGrupoAcesso";
import {GrupoAcesso} from "../../types/GrupoAcesso";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useDispatch} from "react-redux";
import {useAlert} from "react-alert";
import {useParams} from "react-router-dom";
import {Permissions} from "../../types/Permissions";

export const EditarGrupoAcessoContainer = () => {
    const api = useGrowApi();
    const dispatch = useDispatch()
    const alert = useAlert()
    const {id} = useParams<{id: string}>()
    const [grupo, setGrupo] = useState<GrupoAcesso>({
        name: '',
        permissions: '',
        permissionsArray: []
    })
    const [originalName, setOriginalName] = useState<string>('')
    const buscaDados = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.GrupoAcesso.buscarUm(id!);
            setGrupo({
                ...response.data,
                permissionsArray: response.data.permissions.split(",") as Permissions[]
            })
            setOriginalName(response.data?.name ?? '')
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [id])
    const editar = useCallback(async (dados: GrupoAcesso) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.GrupoAcesso.editar({
                ...dados,
                name: dados.name === originalName ? undefined : dados.name,
            }, id!);
            alert.success(response.message, {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [])
    useEffect(() => {
        buscaDados()
    }, [id])
    return <>
        <FormularioGrupoAcesso
            resetarForm={false}
            initialValues={grupo}
            onSubmit={editar}
        />
    </>
}
