import { BaseApi } from './BaseApi';
import { LoginFields } from '../types/LoginFields';
import { LoginResponse } from '../types/LoginResponse';

export class LoginApi extends BaseApi {
	async login(data: LoginFields): Promise<LoginResponse> {
		const response = await this.api.post('/auth/login', {
			email: data.email,
			senha: data.senha,
		});
		return response.data;
	}

	async refresh(dados: {
		refresh_token: string;
		userId: number;
	}): Promise<LoginResponse> {
		const response = await this.api.post('/auth/refresh', dados);
		return response.data;
	}
}
