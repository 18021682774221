import React from 'react'
import {TabelaBase, TabelaBaseProps} from "../TabelaBase/TabelaBase";
import {Jogo} from "../../types/Jogo";

interface ListarJogosProps extends TabelaBaseProps<Jogo> {}

export const ListarJogos = (props: ListarJogosProps) => {
    return <>
        <TabelaBase
            esconderAdicionar={props.esconderAdicionar}
            qtdPaginas={props.qtdPaginas}
            rows={props.rows}
            pesquisar={props.pesquisar}
            cadastrarNovoLink={props.cadastrarNovoLink}
            onPageChange={props.onPageChange}
            headers={props.headers}
        />
    </>
}
