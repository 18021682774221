import React from 'react'
import {TabelaBase, TabelaBaseProps} from "../TabelaBase/TabelaBase";
import {Recuperacao} from "../../types/Recuperacao";

interface ListarSimuladosProps extends TabelaBaseProps<Recuperacao> {}

export const ListarRecuperacao = (props: ListarSimuladosProps) => {
    return <>
        <TabelaBase
            esconderAdicionar={props.esconderAdicionar}
            qtdPaginas={props.qtdPaginas}
            rows={props.rows}
            pesquisar={props.pesquisar}
            cadastrarNovoLink={props.cadastrarNovoLink}
            onPageChange={props.onPageChange}
            headers={props.headers}
        />
    </>
}
