import { BaseApi } from "./BaseApi";
import { Paginacao } from "../types/Paginacao";
import { FindAllParams } from "../types/FindAllParams";
import { IResponsePadrao } from "../types/IResponsePadrao";
import { Constants } from "../types/Constants";
import { Prova } from "../types/Simulado";
import { Turma } from "../types/Turma";

export class ProvaApi extends BaseApi {
  async buscarTodos(
    dados?: FindAllParams
  ): Promise<IResponsePadrao<Paginacao<Prova>>> {
    const response = await this.api.get("/prova", {
      params: {
        page: dados?.page,
        take: dados?.page ? Constants.ITEMS_PER_PAGE : undefined,
        search: dados?.search,
      },
    });
    return response.data;
  }

  async porProfessor(
    dados?: FindAllParams
  ): Promise<IResponsePadrao<Paginacao<Prova>>> {
    const response = await this.api.get("/prova/professor", {
      params: {
        page: dados?.page,
        take: dados?.page ? Constants.ITEMS_PER_PAGE : undefined,
        search: dados?.search,
      },
    });
    return response.data;
  }

  async cadastrar(dados: Prova): Promise<IResponsePadrao<Prova>> {
    const { book, ...resto } = dados;
    const turmasFormatadas: number[] = [];
    for (const turma of resto?.turmas ?? []) {
      turmasFormatadas.push(turma.id);
    }
    return (
      await this.api.post(`/prova`, {
        ...resto,
        bookId: book?.id,
        turmas: turmasFormatadas,
      })
    ).data;
  }

  async montarLivro(id: number): Promise<IResponsePadrao<Prova>> {
    return (await this.api.get(`/prova/montar-livro/${id}`)).data;
  }

  async editar(dados: Prova, id: number): Promise<IResponsePadrao<Prova>> {
    const { simulados, ...resto } = dados;
    const response = await this.api.put(`/prova/${id}`, resto);
    return response.data;
  }

  async buscarUm(id: number): Promise<IResponsePadrao<Prova>> {
    const response = await this.api.get(`/prova/${id}`);
    return response.data;
  }

  async excluir(id: number): Promise<IResponsePadrao<Prova>> {
    const response = await this.api.delete(`/prova/${id}`);
    return response.data;
  }
}
