import React, {useEffect, useState} from "react";
import {
    Container,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import {Formik, FormikState} from "formik";
import * as Yup from "yup";
import {CancelButton, ConfirmButton} from "../Botoes/BotaoAcoes";
import {useNavigate} from "react-router-dom";
import {ROUTES_PATH} from "../RoutesPath";
import {StyledContainer} from "../GrupoAcesso/FormularioGrupoAcesso";
import {UsuarioType} from "../../types/Usuario";
import {GrupoAcesso} from "../../types/GrupoAcesso";
import {GrupoAlunos} from "../../types/GrupoAlunos";
import {Secretaria} from "../../types/Secretaria";
import {Turma} from "../../types/Turma";
import {Escola} from "../../types/Escola";
import {Autocomplete} from "@material-ui/lab";

export type FormularioUsuarioProps = {
    initialValues: UsuarioType;
    onSubmit: (values: UsuarioType) => void;
    resetarForm?: boolean;
    grupos: GrupoAcesso[];
    grupos2?: GrupoAlunos[];
    aluno?: boolean;
    professor?: boolean;
    diretor?: boolean;
    secretarias?: Secretaria[];
    turmas?: Turma[];
    escolas?: Escola[];
    titulo: string;
};

export const FormularioUsuario = (props: FormularioUsuarioProps) => {
    const [rerender, setRerender] = useState<number>(0)
    const materias = [
        'Língua portuguesa',
        'Artes',
        'Ed. Física',
        'Língua inglesa',
        'Matemática',
        'Ciências',
        'Geografia',
        'História'
    ]
    const navigate = useNavigate();
    const [turma, setTurma] = useState<Turma>()
    const onSubmit = async (
        values: UsuarioType,
        {
            resetForm,
            setStatus,
            setErrors,
        }: {
            resetForm: (
                nextState?: Partial<FormikState<UsuarioType>> | undefined
            ) => void;
            setStatus: any;
            setErrors: any;
        }
    ) => {
        try {
            await props.onSubmit({...values, turmaId: turma?.id});
            if (props.resetarForm) {
                resetForm();
            }
        } catch (error: any) {
            setStatus({success: false});
            setErrors({submit: error.message});
        }
    };
    const schema = Yup.object().shape({
        name: Yup.string().required("Campo obrigatório."),
        cpf: props.aluno ? Yup.string().required('Campo obrigatório') : Yup.string().nullable(),
        //scopeId: props.aluno || props.professor ? Yup.number().optional().nullable() : Yup.number().required('Campo obrigatório.').min(1, 'Campo obrigatório'),
        senha: props.resetarForm && !props.aluno
            ? Yup.string().required("Campo obrigatório")
            : Yup.string(),
        confirmarSenha: props.resetarForm && !props.aluno
            ? Yup.string()
                .required("Campo obrigatório")
                .oneOf(
                    [Yup.ref("senha"), null],
                    "Confirmação de senha deve ser igual a senha"
                )
            : Yup.string().oneOf(
                [Yup.ref("senha"), null],
                "Confirmação de senha deve ser igual a senha"
            ),
        email: !props.aluno ? Yup.string()
            .required("Campo obrigatório.")
            .email("Envie um formato de e-mail válido") : Yup.string()
            .email("Envie um formato de e-mail válido"),
    });
    useEffect(() => {
        const selecionado = props.turmas?.find(t => t.id === props.initialValues.turmaId)
        setTurma(selecionado)
        setRerender(prev => prev + 1)
    }, [props.initialValues, props.turmas])
    return (
        <>
            <Formik
                initialValues={props.initialValues}
                enableReinitialize
                validationSchema={schema}
                onSubmit={onSubmit}
            >
                {({values, errors, touched, handleChange, handleSubmit, setFieldValue}) => (
                    <StyledContainer>
                        <form onSubmit={handleSubmit}>
                            <Container>
                                <h2>{props.titulo}</h2>
                                <Grid container spacing={2}>
                                    <Grid item sm={6} xs={12}>
                                        <TextField
                                            label="Nome"
                                            variant="standard"
                                            id="name"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            fullWidth
                                            error={!!errors.name && touched.name}
                                            helperText={
                                                touched.name && errors.name ? errors.name : ""
                                            }
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <TextField
                                            label="CPF"
                                            variant="standard"
                                            id="cpf"
                                            name="cpf"
                                            value={values.cpf}
                                            onChange={handleChange}
                                            fullWidth
                                            error={!!errors.cpf && touched.cpf}
                                            helperText={touched.cpf && errors.cpf ? errors.cpf : ""}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <TextField
                                            label="E-mail"
                                            variant="standard"
                                            id="email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            fullWidth
                                            error={!!errors.email && touched.email}
                                            helperText={
                                                touched.email && errors.email ? errors.email : ""
                                            }
                                        />
                                    </Grid>
                                    {props.professor &&
                                        <Grid item sm={6} xs={12}>
                                            <Autocomplete
                                                multiple
                                                id="materias"
                                                value={values.materiasSelecionadas}
                                                options={materias}
                                                getOptionLabel={(option) => option}
                                                onChange={(e, values) => setFieldValue('materiasSelecionadas', values)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label="Disciplinas"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    }
                                    {(props.professor || props.aluno) && (
                                        <Grid item sm={6} xs={12}>
                                            <Autocomplete
                                                multiple
                                                id="turmas"
                                                key={rerender}
                                                value={values.turmas}
                                                options={props.turmas!}
                                                getOptionLabel={(option) => option.nome}
                                                onChange={(e, values) => setFieldValue("turmas", values)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label="Turmas Relacionadas"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    )}
                                    {props.diretor && (
                                        <Grid item sm={6} xs={12}>
                                            <Autocomplete
                                                id="escola"
                                                value={values.escola}
                                                options={props.escolas!}
                                                getOptionLabel={(option) => option.nome}
                                                onChange={(e, values) => setFieldValue("escola", values)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label="Escola"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    )}
                                    {!props.aluno && !props.professor && !props.diretor && (
                                        <Grid item sm={6} xs={12}>
                                            <FormControl
                                                variant="standard"
                                                fullWidth
                                                error={!!errors.scopeId && touched.scopeId}
                                            >
                                                <InputLabel id="demo-simple-select-standard-label">
                                                    Tipo de usuário
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    value={values.tipo}
                                                    onChange={handleChange}
                                                    name="tipo"
                                                    label="Tipo de usuário"
                                                >
                                                    <MenuItem value="Administrador">
                                                        Administrador
                                                    </MenuItem>
                                                    <MenuItem value="Secretaria">Secretaria</MenuItem>
                                                    <MenuItem value="Professor">Professor</MenuItem>
                                                    <MenuItem value="Aluno">Aluno</MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    {touched.tipo && errors.tipo ? errors.tipo : ""}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    )}
                                    {values.tipo === "Secretaria" && (
                                        <Grid item sm={6} xs={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel id="demo-simple-select-standard-label">
                                                    Secretaria
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    value={values.secretariaId}
                                                    onChange={handleChange}
                                                    id="secretariaId"
                                                    name="secretariaId"
                                                    label="Secretaria"
                                                >
                                                    {props?.secretarias?.map((s) => (
                                                        <MenuItem
                                                            key={s.id}
                                                            value={s.id}
                                                            selected={values.secretariaId === s.id}
                                                        >
                                                            {s.nome}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText>
                                                    {touched.scopeId && errors.scopeId
                                                        ? errors.scopeId
                                                        : ""}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    )}
                                    <Grid item sm={6} xs={12}>
                                        <TextField
                                            label="Senha"
                                            variant="standard"
                                            id="senha"
                                            name="senha"
                                            type="password"
                                            value={values.senha}
                                            onChange={handleChange}
                                            fullWidth
                                            error={!!errors.senha && touched.senha}
                                            helperText={
                                                touched.senha && errors.senha ? errors.senha : ""
                                            }
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <TextField
                                            label="Confirmar senha"
                                            variant="standard"
                                            id="confirmarSenha"
                                            name="confirmarSenha"
                                            type="password"
                                            value={values.confirmarSenha}
                                            onChange={handleChange}
                                            fullWidth
                                            error={!!errors.confirmarSenha && touched.confirmarSenha}
                                            helperText={
                                                touched.confirmarSenha && errors.confirmarSenha
                                                    ? errors.confirmarSenha
                                                    : ""
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4} style={{marginTop: "10px"}}>
                                        <ConfirmButton type="submit" fullWidth>
                                            Salvar
                                        </ConfirmButton>
                                    </Grid>
                                    <Grid item xs={4} style={{marginTop: "10px"}}>
                                        <CancelButton
                                            type="button"
                                            fullWidth
                                            onClick={() =>
                                                navigate(
                                                    props.aluno
                                                        ? ROUTES_PATH.ALUNOS
                                                        : props.professor
                                                            ? ROUTES_PATH.PROFESSORES
                                                            : ROUTES_PATH.USUARIOS
                                                )
                                            }
                                        >
                                            Cancelar
                                        </CancelButton>
                                    </Grid>
                                </Grid>
                            </Container>
                        </form>
                    </StyledContainer>
                )}
            </Formik>
        </>
    );
};
