export enum Permissions {
    ADD_USER = 'AddUser',
    UPDATE_USER = 'UpdateUser',
    ADD_SCOPE = 'AddScope',
    UPDATE_SCOPE = 'UpdateScope',
    LIST_SCOPE = 'ListScope',
    LIST_USER = 'ListUser',
    DELETE_USER = 'DeleteUser',
    DELETE_SCOPE = 'DeleteScope',
    ADD_STUDENT = 'AddStudent',
    LIST_STUDENT = 'ListStudent',
    DELETE_STUDENT = 'DeleteStudent',
    UPDATE_STUDENT = 'UpdateStudent',
    ADD_STUDENT_GROUP = 'AddStudentGroup',
    LIST_STUDENT_GROUP = 'ListStudentGroup',
    DELETE_STUDENT_GROUP = 'DeleteStudentGroup',
    UPDATE_STUDENT_GROUP = 'UpdateStudentGroup',
    ADD_BOOK = 'AddBook',
    LIST_BOOK = 'ListBook',
    DELETE_BOOK = 'DeleteBook',
    UPDATE_BOOK = 'UpdateBook',
    ADD_GAMES = 'AddGames',
    LIST_GAMES = 'ListGames',
    DELETE_GAMES = 'DeleteGames',
    UPDATE_GAMES = 'UpdateGames',
    ADD_3D = 'Add3D',
    LIST_3D = 'List3D',
    DELETE_3D = 'Delete3D',
    UPDATE_3D = 'Update3D',
    ADD_QUESTIONS = 'AddQUESTIONS',
    LIST_QUESTIONS = 'ListQUESTIONS',
    DELETE_QUESTIONS = 'DeleteQUESTIONS',
    UPDATE_QUESTIONS = 'UpdateQUESTIONS',
    REPORT_PER_QUESTION = 'ReportPerQuestion',
    REPORT_PER_GROUP = 'ReportPerGroup'
}
