import { BaseApi } from './BaseApi';
import {Paginacao} from "../types/Paginacao";
import {FindAllParams} from "../types/FindAllParams";
import {IResponsePadrao} from "../types/IResponsePadrao";
import {Constants} from "../types/Constants";
import {GrupoAcesso} from "../types/GrupoAcesso";

export class GrupoAcessoApi extends BaseApi {
    async buscarTodos(dados?: FindAllParams): Promise<IResponsePadrao<Paginacao<GrupoAcesso>>> {
        const response = await this.api.get('/escopo', {
            params: {
                page: dados?.page,
                take: dados?.page ? Constants.ITEMS_PER_PAGE : undefined,
                search: dados?.search
            }
        });
        return response.data;
    }

    async buscarUm(id: string): Promise<IResponsePadrao<GrupoAcesso>> {
        const response = await this.api.get(`/escopo/${id}`);
        return response.data;
    }

    async excluir(id: number): Promise<IResponsePadrao<GrupoAcesso>> {
        const response = await this.api.delete(`/escopo/${id}`);
        return response.data;
    }

    async cadastrar(dados: GrupoAcesso): Promise<IResponsePadrao<GrupoAcesso>> {
        const response = await this.api.post(`/escopo`, {...dados, permissions: dados.permissionsArray});
        return response.data;
    }

    async editar(dados: GrupoAcesso, id: string): Promise<IResponsePadrao<GrupoAcesso>> {
        const response = await this.api.put(`/escopo/${id}`, {...dados, permissions: dados.permissionsArray, permissionsArray: undefined});
        return response.data;
    }
}
