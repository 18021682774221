import React, {useCallback, useEffect, useState} from 'react'
import {ROUTES_PATH} from "../RoutesPath";
import {ColumnHeader} from "../TabelaBase/TabelaBase";
import {BotaoAcoes} from "../Botoes/BotaoAcoes";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useDispatch} from "react-redux";
import {useAlert} from "react-alert";
import {useNavigate} from "react-router-dom";
import {FindAllParams} from "../../types/FindAllParams";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {ListarSimulados} from "./ListarSimulados";
import {Prova} from "../../types/Simulado";
import {ObterTipoUsuario} from "../../utils/ObterTipoUsuario";
import {TipoUsuario} from "../../types/TipoUsuario";
import {MenuItem} from "@material-ui/core";

type ListarSimuladosContainerProps = {
    aluno?: boolean
}

export const ListarSimuladosContainer = (props: ListarSimuladosContainerProps) => {
    const [provas, setProvas] = useState<Prova[]>([])
    const [qtdPag, setQtdPag] = useState<number>(0)
    const [pesquisa, setPesquisa] = useState<string>('')
    const [page, setPage] = useState<number>(1)
    const api = useGrowApi();
    const dispatch = useDispatch()
    const alert = useAlert()
    const navigate = useNavigate()
    const tipoUsuario = ObterTipoUsuario()
    const headers: ColumnHeader[] = [
       {
            label: '#',
            columnName: 'id'
        },
        {
            label: 'Nome',
            columnName: 'nome'
        },
        {
            label: 'Livro relacionado',
            columnName: 'book.name',
            render: (value) => value ?? "Não informado"
        },
        {
            label: 'Página do livro',
            columnName: 'page',
            render: (value) => value && value > 0 ? value : 'Não informada'
        },
        {
            label: 'Ações',
            columnName: 'id',
            render: (id) => <BotaoAcoes
                onBotaoEditarClicado={() => navigate(`/simulados/editar/${id}`)}
                onBotaoExcluirClicado={async () => {
                    await handleExcluir(id)
                }}
                esconderEditar={props.aluno}
                esconderExcluir={props.aluno}
                linksExtras={
                <>
                    {
                        !props.aluno &&
                        <MenuItem
                            onClick={() => {
                                navigate(`/livros/clonar/${id}`)
                            }}
                        >
                            Clonar
                        </MenuItem>
                    }
                    {
                        props.aluno && <MenuItem
                            onClick={() => {
                                navigate(`/simulado/resolver/${id}`)
                            }}
                        >
                            Fazer simulado
                        </MenuItem>
                    }
                </>
            }
            />
        }
    ]
    const buscaDados = useCallback(
        async (dados?: FindAllParams) => {
            try {
                dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
                const response = tipoUsuario === TipoUsuario.PROFESSOR ? await api.Prova.porProfessor(dados) : await api.Prova.buscarTodos(dados);
                setQtdPag(response.data.count)
                setProvas(response.data.rows)
            } catch (e: any) {
                TratamentoErroPadrao(e, alert);
            } finally {
                dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
            }
        }, [page])
    const handleExcluir = useCallback(async (id: number) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Prova.excluir(id)
            await buscaDados({page, search: pesquisa})
            alert.success(response.message, {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [page, pesquisa])
    useEffect(() => {
        buscaDados({
            page,
            search: pesquisa
        })
    }, [page, pesquisa])
    return <>
        <ListarSimulados
            esconderAdicionar={props.aluno}
            rows={provas}
            cadastrarNovoLink={ROUTES_PATH.CADASTRAR_SIMULADOS}
            qtdPaginas={qtdPag}
            pesquisar={(pesquisa) => setPesquisa(pesquisa)}
            onPageChange={(page) => setPage(page)}
            headers={headers}
        />
    </>
}
