/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useDispatch, useSelector} from "react-redux";
import {useAlert} from "react-alert";
import {RelatorioPorTurma, RelatorioTurmaValues} from "./RelatorioPorTurma";
import {RelatorioPorTurmaType} from "../../types/RelatorioPorTurma";
import {Secretaria} from "../../types/Secretaria";
import {RootState} from "../../redux/RootState";

type Props = {
    secretaria?: boolean
    escola?: boolean
    professor?: boolean
}

export const RelatorioPorTurmaContainer = (props: Props) => {
    const [secretarias, setSecretarias] = useState<Secretaria[]>([])
    const [dados, setDados] = useState<RelatorioPorTurmaType[]>([]);
    const usuarioLogado = useSelector((state: RootState) => state.usuarioLogado);
    const api = useGrowApi();
    const dispatch = useDispatch();
    const alert = useAlert();

    const buscaDados = useCallback(async () => {
        try {
            dispatch({type: REALIZANDO_PROCESSAMENTO.ATIVO});
            const response = await api.Secretaria.buscarTodos();
            setSecretarias(response.data.rows);
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({type: REALIZANDO_PROCESSAMENTO.NENHUM});
        }
    }, [api, dispatch]);
    const buscaRelatorio = useCallback(
        async (values: RelatorioTurmaValues) => {
            try {
                dispatch({type: REALIZANDO_PROCESSAMENTO.ATIVO});

                if(values.aluno){
                    const response = await api.Simulado.relatorioPorAluno(
                        values.aluno?.id ?? 0,
                        values.dataInicial as Date,
                        values.dataFinal as Date
                    );
                    setDados(response.data.rows)
                }
                else if (values.turmas && values.turmas.length > 0) {
                    const novosDados: RelatorioPorTurmaType[] = []
                    for (const turma of values.turmas) {
                        const response = await api.Simulado.relatorioPorTurma(
                            turma.id!,
                            values.dataInicial as Date,
                            values.dataFinal as Date
                        );
                        for (const dado of response.data.rows) {
                            novosDados.push(dado)
                        }
                    }
                    setDados(novosDados)
                } else if (values.materias && values.materias.length > 0) {
                    const novosDados: RelatorioPorTurmaType[] = []
                    for (const materia of values.materias) {
                        for (const escola of values.escolas) {
                            const response = await api.Simulado.relatorioPorMateria(
                                materia,
                                escola?.id,
                                values.dataInicial as Date,
                                values.dataFinal as Date
                            );
                            for (const dado of response.data.rows) {
                                novosDados.push(dado)
                            }
                        }
                    }
                    setDados(novosDados)
                } else if ((values.escolas && values.escolas.length > 0) || props.escola || props.professor) {
                    const novosDados: RelatorioPorTurmaType[] = []
                    if (props.professor) {
                        const response = await api.Simulado.relatorioPorEscola(
                            usuarioLogado?.escolaId ?? 0,
                            values.dataInicial as Date,
                            values.dataFinal as Date
                        );
                        for (const dado of response.data.rows) {
                            novosDados.push(dado)
                        }
                    }
                    if (props.escola) {
                        const response = await api.Simulado.relatorioPorEscola(
                            usuarioLogado?.escolaId ?? 0,
                            values.dataInicial as Date,
                            values.dataFinal as Date
                        );
                        for (const dado of response.data.rows) {
                            novosDados.push(dado)
                        }
                    }
                    for (const escola of values.escolas ?? []) {
                        const response = await api.Simulado.relatorioPorEscola(
                            props.escola ? usuarioLogado?.escolaId ?? 0 : escola.id,
                            values.dataInicial as Date,
                            values.dataFinal as Date
                        );
                        for (const dado of response.data.rows) {
                            novosDados.push(dado)
                        }
                    }
                    setDados(novosDados)

                }
                else if (values.secretaria || props.secretaria) {
                    const response = await api.Simulado.relatorioPorSecretaria(
                        props.secretaria ? usuarioLogado?.secretariaId ?? 0 : values.secretaria.id,
                        values.dataInicial as Date,
                        values.dataFinal as Date
                    );
                    setDados(response.data.rows);
                } else {
                    const response = await api.Simulado.relatorioSemFiltro(
                        0,
                        values.dataInicial as Date,
                        values.dataFinal as Date
                    );
                    setDados(response.data.rows);
                }
            } catch (e: any) {
                TratamentoErroPadrao(e, alert);
            } finally {
                dispatch({type: REALIZANDO_PROCESSAMENTO.NENHUM});
            }
        },
        [api, dispatch]
    );

    useEffect(() => {
        buscaDados();
    }, []);

    return (
        <>
            <RelatorioPorTurma
                escola={props.escola}
                professor={props.professor}
                secretaria={props.secretaria}
                secretarias={secretarias}
                dados={dados}
                onSubmit={buscaRelatorio}
                initialValues={{} as RelatorioTurmaValues}
            />
        </>
    );
};
