/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGrowApi } from "../../hooks/useGrowApi";
import { useDispatch } from "react-redux";
import { TratamentoErroPadrao } from "../../utils/TratamentoErroPadrao";
import { useAlert } from "react-alert";
import { REALIZANDO_PROCESSAMENTO } from "../../redux/actions/realizandoProcessamento.actions";

export const VisualizarLivroContainer = () => {
  const api = useGrowApi();
  const [htmlDoc, setHtml] = useState<any>(null);
  const { bookId } = useParams<{ bookId: string }>();
  const dispatch = useDispatch();
  const alert = useAlert();

  const renderBook = useCallback(async () => {
    try {
      if (!bookId) return;
      dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
      await api.Livro.preparar(bookId)
      const res = await api.Livro.renderById();
      setHtml(res);
    } catch (error) {
      TratamentoErroPadrao(error, alert);
    } finally {
      dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
    }
  }, []);

  useEffect(() => {
    renderBook();
  }, []);

  return (
    <>
      {htmlDoc ? (
        <iframe
          title="render-book"
          src={`${process.env.REACT_APP_API_URL}/0001.html`}
          style={{ width: "100vw" }}
          allowFullScreen
        />
      ) : null}
    </>
  );
};
