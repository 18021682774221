import React, {useEffect, useState} from 'react'
import { Secretaria } from '../../types/Secretaria';
import {FormularioSecretaria} from "./FormularioSecretaria";
import {useDispatch} from "react-redux";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useAlert} from "react-alert";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {useParams} from "react-router-dom";

export const EditarSecretariaContainer = () => {
    const {id} = useParams<{id: string}>()
    const [initialValues, setInitialValues] = useState<Secretaria>({} as Secretaria)
    const dispatch = useDispatch()
    const api = useGrowApi()
    const alert = useAlert()
    const salvar = async (dados: Secretaria) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Secretaria.editar(dados, Number(id));
            alert.success(response.message, {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }
    const obterDados = async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Secretaria.buscarUm(Number(id));
            setInitialValues(response.data)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }
    useEffect(() => {
        obterDados()
    }, [])
    return <FormularioSecretaria
        initialValues={initialValues}
        onSubmit={salvar}
        titulo="Editar Secretaria"
    />
}
