import React from 'react'
import {useDispatch} from "react-redux";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useAlert} from "react-alert";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {FormularioTurma} from "./FormularioTurma";
import {Turma} from "../../types/Turma";

type Props = {
    escolaId?: number
}

export const CadastrarTurmaContainer = (props: Props) => {
    const dispatch = useDispatch()
    const api = useGrowApi()
    const alert = useAlert()
    const cadastrar = async (dados: Turma) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Turma.cadastrar({ ...dados, escolaId: props.escolaId });
            alert.success(response.message, {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }
    return <FormularioTurma
        resetarForm
        initialValues={{
            nome: ''
        } as Turma}
        onSubmit={cadastrar}
        titulo="Cadastrar Turma"
    />
}
