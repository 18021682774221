import React, {useCallback, useEffect, useState} from 'react'
import {RelatorioSimulado, RelatorioValues} from "./RelatorioSimulado";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useDispatch} from "react-redux";
import {useAlert} from "react-alert";
import {Prova} from "../../types/Simulado";
import {Score} from "../../types/Score";

type Props = {
    aluno?: boolean
}

export const RelatorioSimuladoContainer = (props: Props) => {
    const [provas, setProvas] = useState<Prova[]>([])
    const [dados, setDados] = useState<Score[]>([])
    const api = useGrowApi();
    const dispatch = useDispatch()
    const alert = useAlert()
    const buscaDados = useCallback(
        async () => {
            try {
                dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
                const response = await api.Prova.buscarTodos();
                setProvas(response.data.rows)
            } catch (e: any) {
                TratamentoErroPadrao(e, alert);
            } finally {
                dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
            }
        }, [api, dispatch, setProvas])
    const buscaRelatorio = useCallback(
        async (values: RelatorioValues) => {
            try {
                dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
                const novosDados: Score[] = []
                if (values.provas && values.provas?.length > 0) {
                    for (const prova of values.provas) {
                        const response =
                            props.aluno ? await api.Simulado.relatorioPorProvaAluno(prova?.id ?? undefined, values.dataInicial as Date, values.dataFinal as Date)
                                :
                                await api.Simulado.relatorioPorProva(prova?.id ?? undefined, values.dataInicial as Date, values.dataFinal as Date);
                        for (const dado of response.data.rows) {
                            novosDados.push(dado)
                        }
                    }
                    setDados(novosDados)
                } else {
                    const response =
                        props.aluno ? await api.Simulado.relatorioPorProvaAluno(undefined, values.dataInicial as Date, values.dataFinal as Date)
                            :
                            await api.Simulado.relatorioPorProva(undefined, values.dataInicial as Date, values.dataFinal as Date);
                    setDados(response.data.rows)
                }
            } catch (e: any) {
                TratamentoErroPadrao(e, alert);
            } finally {
                dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
            }
        }, [api, dispatch])
    useEffect(() => {
        buscaDados()
    }, [])
    return <>
        <RelatorioSimulado
            aluno={props.aluno}
            dados={dados}
            onSubmit={buscaRelatorio}
            initialValues={{} as RelatorioValues}
            provas={provas}
        />
    </>
}
