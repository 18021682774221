import React, {useCallback, useEffect, useState} from 'react'
import {
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    TextField,
    Tooltip
} from "@material-ui/core";
import {Formik, FormikState} from "formik";
import {CancelButton, ConfirmButton} from "../Botoes/BotaoAcoes";
import {useNavigate} from "react-router-dom";
import {ROUTES_PATH} from "../RoutesPath";
import {StyledContainer} from "../GrupoAcesso/FormularioGrupoAcesso";
import {Autocomplete} from "@material-ui/lab";
import {OpcaoSimulado, Simulado} from "../../types/Simulado";
import styled from "styled-components";
import {useAlert} from "react-alert";
import {v4 as uuid} from "uuid";
import * as R from "ramda";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/RootState";
import {Recuperacao} from "../../types/Recuperacao";
import {UsuarioType} from "../../types/Usuario";

const OpcoesContainer = styled(Grid)`
  border: 1px solid rgba(0,0,0,0.4);
  padding-top: 0 !important;
  padding: 25px;
  margin-top: 20px;
`

const CheckboxContainer = styled(FormControlLabel)`
  margin-top: 20px;
`

const PlusButton = styled.button`
  background: #66bf3c;
  border: none;
  padding: 0 10px;
  cursor: pointer;
  font-size: 20pt;
  color: #FFF;
`

const MinunButton = styled.button`
  background: #e10000;
  border: none;
  padding: 0 10px;
  cursor: pointer;
  font-size: 20pt;
  position: relative;
  top: 20px;
  right: 80px;
  color: #FFF;
`

const StyledGrid = styled(Grid)`
  margin-top: 15px;
`

export type FormularioSimuladosProps = {
    initialValues: Partial<Recuperacao>
    onSubmit: (values: Recuperacao) => void;
    resetarForm?: boolean
    titulo: string
    editar?: boolean
    alunos: UsuarioType[]
}

export const FormularioSimulados = (props: FormularioSimuladosProps) => {
    const alert = useAlert()
    const navigate = useNavigate()
    const [questoes, setQuestoes] = useState<Simulado[]>([
        {
            id: uuid(),
            enunciado: '',
            titulo: '',
            discursiva: false,
            opcoes: [
                {
                    id: uuid(),
                    titulo: '',
                    correto: false
                } as OpcaoSimulado,
                {
                    id: uuid(),
                    titulo: '',
                    correto: false
                } as OpcaoSimulado
            ]
        }
    ])
    const adicionarOpcao = useCallback((questao: Simulado) => {
        const index = questoes.indexOf(questao)
        const novaQuestao = {
            ...questao,
            opcoes: [
                ...questao.opcoes,
                {
                    id: uuid(),
                    titulo: '',
                    correto: false
                } as OpcaoSimulado
            ]
        }
        const novoArray = R.update(index, novaQuestao, questoes)
        setQuestoes(novoArray)
    }, [questoes, setQuestoes])
    const removerOpcao = useCallback((opcao: OpcaoSimulado, questao: Simulado) => {
        if (questao.opcoes.length <= 2) {
            alert.error('É obrigatório o envio de, ao menos, 2 opções', {
                timeout: 4000
            })
            return;
        }
        const index = questao.opcoes.indexOf(opcao)
        const novasOpcoes = R.remove(index, 1, questao.opcoes)
        const novaQuestao = {
            ...questao,
            opcoes: novasOpcoes
        }
        const index2 = questoes.indexOf(questao)
        const novoArray = R.update(index2, novaQuestao, questoes)
        setQuestoes(novoArray)
    }, [questoes, setQuestoes])
    const adicionarQuestao = useCallback(() => {
        setQuestoes([
            ...questoes,
            {
                id: uuid(),
                enunciado: '',
                discursiva: false,
                opcoes: [
                    {
                        id: uuid(),
                        titulo: '',
                        correto: false
                    } as OpcaoSimulado,
                    {
                        id: uuid(),
                        titulo: '',
                        correto: false
                    } as OpcaoSimulado
                ]
            }
        ])
    }, [questoes, setQuestoes])
    const handleChangeQuestao = useCallback((questao: Simulado) => {
        const questaoAtual = questoes.find(q => q.id === questao.id)
        const index = questoes.indexOf(questaoAtual!)
        const novoArray = R.update(index, questao, questoes)
        setQuestoes(novoArray)
    }, [questoes, setQuestoes])

    const removerQuestao = useCallback((questao: Simulado) => {
        if (questoes.length <= 1) {
            alert.error('É obrigatório o envio de ao menos uma questão.', {
                timeout: 4000
            })
            return;
        }
        const index = questoes.indexOf(questao)
        const novoArray = R.remove(index, 1, questoes)
        setQuestoes(novoArray)
    }, [questoes, setQuestoes])

    const handleChangeOpcao = useCallback((opcao: OpcaoSimulado, questao: Simulado) => {
        if (opcao.correto) {
            const novasOpcoes: OpcaoSimulado[] = []
            for (const valor of questao.opcoes) {
                novasOpcoes.push(
                    valor.id === opcao.id ? opcao : {
                        ...valor,
                        correto: false
                    }
                )
            }
            const novaQuestao = {
                ...questao,
                opcoes: novasOpcoes
            }
            const index2 = questoes.indexOf(questao)
            const novoArray = R.update(index2, novaQuestao, questoes)
            setQuestoes(novoArray)
            return;
        }
        const opcaoEnviada = questao.opcoes.find(o => o.id === opcao.id)
        const index = questao.opcoes.indexOf(opcaoEnviada!)
        const novasOpcoes = R.update(index, opcao, questao.opcoes)
        const novaQuestao = {
            ...questao,
            opcoes: novasOpcoes
        }
        const index2 = questoes.indexOf(questao)
        const novoArray = R.update(index2, novaQuestao, questoes)
        setQuestoes(novoArray)
    }, [questoes, setQuestoes])
   useEffect(() => {
        setQuestoes(props.initialValues.simulados || [])
    }, [props.initialValues.simulados])

    const onSubmit = async (
        values: Recuperacao,
        {
            resetForm,
            setStatus,
            setErrors,
        }: {
            resetForm: (
                nextState?: Partial<FormikState<Recuperacao>> | undefined
            ) => void;
            setStatus: any;
            setErrors: any;
        }
    ) => {
        try {
            await props.onSubmit({
                ...values,
                simulados: questoes
            });
            if (props.resetarForm) {
                setQuestoes([
                    {
                        id: uuid(),
                        enunciado: '',
                        discursiva: false,
                        opcoes: [
                            {
                                id: uuid(),
                                titulo: '',
                                correto: false
                            } as OpcaoSimulado,
                            {
                                id: uuid(),
                                titulo: '',
                                correto: false
                            } as OpcaoSimulado
                        ]
                    }
                ])
                resetForm();
            }
        } catch (error: any) {
            setStatus({ success: false });
            setErrors({ submit: error.message });
        }
    };
    return <>
        <Formik
            initialValues={props.initialValues as Recuperacao}
            enableReinitialize
            onSubmit={onSubmit}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue
              }) => (
                <StyledContainer>
                    <form onSubmit={handleSubmit}>
                        <Container>
                            <h2>{props.titulo}</h2>
                            <h5>Os campos com * são de preenchimento obrigatório</h5>
                            <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                    <TextField
                                        label="Nome *"
                                        variant="standard"
                                        id="nome"
                                        name="nome"
                                        value={values.nome}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleChange}
                                        fullWidth
                                        error={!!errors.nome && touched.nome}
                                        helperText={touched.nome && errors.nome ? errors.nome : ''}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Autocomplete
                                        multiple
                                        id="alunos"
                                        value={values.alunos}
                                        options={props.alunos}
                                        getOptionLabel={(option) => option?.name}
                                        onChange={(e, values) => setFieldValue('alunos', values)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Alunos"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <FormControlLabel
                                        checked={values.revelarNota}
                                        control={
                                            <Checkbox
                                                key={Number(values.revelarNota)}
                                                onChange={(event, checked) => {
                                                    setFieldValue('revelarNota', checked)
                                                }}
                                            />
                                        }
                                        label="Revelar nota aos alunos"
                                    />
                                </Grid>
                            </Grid>
                            {
                                questoes.map((q, index) => (
                                    <OpcoesContainer container spacing={2}>
                                        <Grid item sm={11}>
                                            <h3>Questão {index + 1}</h3>
                                        </Grid>
                                        <Grid item sm={1}>
                                            <Tooltip title="Remover questão">
                                                <MinunButton
                                                    type="button"
                                                    onClick={() => removerQuestao(q)}
                                                >
                                                    X
                                                </MinunButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item sm={12} xs={12}>
                                            <TextField
                                                label="Título"
                                                variant="standard"
                                                id="titulo *"
                                                name="titulo"
                                                value={q.titulo}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleChangeQuestao({
                                                    ...q,
                                                    titulo: e.target.value
                                                })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item sm={12} xs={12}>
                                            <input
                                                style={{ display: 'none' }}
                                                id={`button-file${q.id}`}
                                                onChange={(e) => handleChangeQuestao({
                                                    ...q,
                                                    fileTitulo: e.target?.files ? e.target.files[0] : undefined
                                                })}
                                                type="file"
                                                accept="image/*"
                                            />
                                            <label htmlFor={`button-file${q.id}`}>
                                                <Button component="span" variant="contained" color="primary">
                                                    {q.fileTitulo?.name ? q?.fileTitulo?.name : q?.imagemTitulo ? `Imagem.${q?.imagemTitulo.split('.')[5]}` : "Enviar imagem"}
                                                </Button>
                                            </label>
                                        </Grid>
                                        <Grid item sm={12} xs={12}>
                                            <TextField
                                                label="Enunciado"
                                                variant="standard"
                                                id="enunciado *"
                                                name="enunciado"
                                                value={q.enunciado}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleChangeQuestao({
                                                    ...q,
                                                    enunciado: e.target.value
                                                })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <input
                                                style={{ display: 'none' }}
                                                id={`raised-button-file${q.id}`}
                                                onChange={(e) => handleChangeQuestao({
                                                    ...q,
                                                    file: e.target?.files ? e.target.files[0] : undefined
                                                })}
                                                type="file"
                                                accept="image/*"
                                            />
                                            <label htmlFor={`raised-button-file${q.id}`}>
                                                <Button component="span" variant="contained" color="primary">
                                                    {q.file?.name ? q?.file?.name : q?.imagem ? `Imagem.${q.imagem.split('.')[5]}` : "Enviar imagem"}
                                                </Button>
                                            </label>
                                        </Grid>
                                        <Grid item sm={12} xs={12}>
                                            <CheckboxContainer
                                                checked={q.discursiva}
                                                control={
                                                    <Checkbox
                                                        onChange={(e, checked) => {
                                                            handleChangeQuestao({
                                                                ...q,
                                                                discursiva: checked
                                                            })
                                                        }}
                                                    />
                                                }
                                                label="Questão discursiva"
                                            />
                                        </Grid>
                                        {!q.discursiva && <Grid item sm={12} xs={12}>
                                            <h3>Opções</h3>
                                            <h5>É obrigatório o envio de pelo menos 2 alternativas</h5>
                                        </Grid>}
                                        {
                                            !q.discursiva && q.opcoes.map((o, index) => (
                                                <>
                                                    <Grid item sm={6} xs={10}>
                                                        <TextField
                                                            label="Titulo"
                                                            variant="standard"
                                                            value={o.titulo}
                                                            onChange={(e) => {
                                                                handleChangeOpcao({
                                                                    ...o,
                                                                    titulo: e.target.value
                                                                }, q)
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={2}>
                                                        <CheckboxContainer
                                                            checked={o.correto}
                                                            control={
                                                                <Checkbox
                                                                    onChange={(e, checked) => {
                                                                        handleChangeOpcao({
                                                                            ...o,
                                                                            correto: checked
                                                                        }, q)
                                                                    }}
                                                                />
                                                            }
                                                            label="Marcar como certa"
                                                        />
                                                    </Grid>
                                                    <StyledGrid item sm={3}>
                                                        <input
                                                            style={{ display: 'none' }}
                                                            id={`opcao${o.id}`}
                                                            onChange={(e) => {
                                                                handleChangeOpcao({
                                                                    ...o,
                                                                    file: e.target?.files ? e.target.files[0] : undefined
                                                                }, q)
                                                            }}
                                                            type="file"
                                                            accept="image/*"
                                                        />
                                                        <label htmlFor={`opcao${o.id}`}>
                                                            <Button component="span" variant="contained" color="primary">
                                                                {o.file?.name ? `Imagem.${o.file.name.split('.')[1]}` : o?.imagem ? `Imagem.${o?.imagem.split('.')[5]}` : "Enviar imagem"}
                                                            </Button>
                                                        </label>
                                                    </StyledGrid>
                                                    <Grid item sm={1}>
                                                        <Tooltip title="Remover opção">
                                                            <MinunButton
                                                                type="button"
                                                                onClick={() => removerOpcao(o, q)}
                                                            >
                                                                -
                                                            </MinunButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </>
                                            ))
                                        }
                                        {!q.discursiva && <Grid item xs={12}>
                                            <Tooltip title="Adicionar opção">
                                                <PlusButton
                                                    type="button"
                                                    onClick={() => adicionarOpcao(q)}
                                                >
                                                    +
                                                </PlusButton>
                                            </Tooltip>
                                        </Grid>}
                                    </OpcoesContainer>
                                ))}
                            <Grid item xs={4} style={{marginTop: "30px"}}>
                                <ConfirmButton
                                    onClick={adicionarQuestao}
                                    type="button"
                                    fullWidth
                                >
                                    + Adicionar questão
                                </ConfirmButton>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4} style={{marginTop: "10px"}}>
                                    <ConfirmButton
                                        type="submit"
                                        fullWidth
                                    >
                                        Salvar
                                    </ConfirmButton>
                                </Grid>
                                <Grid item xs={4} style={{marginTop: "10px"}}>
                                    <CancelButton
                                        type="submit"
                                        fullWidth
                                        onClick={() => navigate(ROUTES_PATH.SIMULADOS)}
                                    >
                                        Cancelar
                                    </CancelButton>
                                </Grid>
                            </Grid>
                        </Container>
                    </form>
                </StyledContainer>
            )}
        </Formik>
    </>
}
