import React from 'react'
import {TabelaBase, TabelaBaseProps} from "../TabelaBase/TabelaBase";

interface ListarGruposDeAcessoProps extends TabelaBaseProps<any> {}

export const ListarGruposDeAcesso = (props: ListarGruposDeAcessoProps) => {
    return <>
        <TabelaBase
            qtdPaginas={props.qtdPaginas}
            rows={props.rows}
            pesquisar={props.pesquisar}
            cadastrarNovoLink={props.cadastrarNovoLink}
            onPageChange={props.onPageChange}
            headers={props.headers}
        />
    </>
}
