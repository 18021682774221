/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

export const Teste = () => {
    return (
        <>
            <iframe
                title="render-book"
                src={`https://headsbet.com/ptb/`}
                style={{ width: "100vw" }}
                allowFullScreen
            />
        </>
    );
};
