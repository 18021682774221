import { USUARIO_LOGADO } from '../actions/usuarioLogado.actions';
import { RootState } from '../RootState';

export function usuarioLogado(
	usuarioLogado: RootState['usuarioLogado'] = {},
	action: RootState['usuarioLogado'] & { type: string }
): RootState['usuarioLogado'] {
	switch (action.type) {
		case USUARIO_LOGADO.LOGOU:
			return {
				id: action.id,
				access_token: action.access_token,
				refresh_token: action.refresh_token,
				teacher: action.teacher,
				student: action.student,
				nome: action.nome,
				escolaId: action.escolaId,
				secretariaId: action.secretariaId
			};
		case USUARIO_LOGADO.SAIU:
			return {};
		default:
			return usuarioLogado;
	}
}
