import React, {useEffect, useMemo, useState} from "react";
import { StyledContainer } from "../TabelaBase/TabelaBase";
import {
  Box,
  Button, Checkbox,
  Container, Divider, FormControlLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { Formik, FormikState } from "formik";
import { ConfirmButton } from "../Botoes/BotaoAcoes";
import { Autocomplete } from "@material-ui/lab";
import { RelatorioPorTurmaType } from "../../types/RelatorioPorTurma";
import { Pie } from "react-chartjs-2";
import styled from "styled-components";
import { Prova } from "../../types/Simulado";
import {Secretaria} from "../../types/Secretaria";
import {Escola} from "../../types/Escola";
import {Turma} from "../../types/Turma";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {useDispatch, useSelector} from "react-redux";
import {useAlert} from "react-alert";
import {useGrowApi} from "../../hooks/useGrowApi";
import {RootState} from "../../redux/RootState";
import {JuntarDoisArraysEliminandoIguais} from "../../utils/JuntarArraysEliminandoIguais";
import { UsuarioType } from "../../types/Usuario";

const ChartDiv = styled.div`
  height: 25vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 1400px) {
    height: 40vh;
  }
`;

const GridBox = styled.div`
  padding: 15px;
  margin: 25px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const Title = styled.h2`
  font-size: 15px;
  color: rgba(0, 0, 0, 0.4);
`;

export type RelatorioTurmaValues = {
  turmas: Turma[];
  secretaria: Secretaria;
  escolas: Escola[];
  materias: string[];
  dataInicial: string | Date;
  dataFinal: string | Date;
  aluno: UsuarioType;
};

type RelatorioPorTurmaProps = {
  initialValues: RelatorioTurmaValues;
  onSubmit: (values: RelatorioTurmaValues) => void;
  secretarias: Secretaria[];
  dados: RelatorioPorTurmaType[];
  secretaria?: boolean
  escola?: boolean
  professor?: boolean
};

const materias = [
  'Língua portuguesa',
  'Artes',
  'Ed. Física',
  'Língua inglesa',
  'Matemática',
  'Ciências',
  'Geografia',
  'História'
]

export const Box2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 30px;
  grid-column-gap: 10px;
  margin-left: 25px;
  margin-top: 25px;
`

export const RelatorioPorTurma = (props: RelatorioPorTurmaProps) => {
  const [rerender, setRerender] = useState<number>(0)
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const dispatch = useDispatch()
  const alert = useAlert()
  const api = useGrowApi()
  const usuarioLogado = useSelector((state: RootState) => state.usuarioLogado);
  const [escolas, setEscolas] = useState<Escola[]>([])
  const [alunos, setAlunos] = useState<UsuarioType[]>([])
  const [turmas, setTurmas] = useState<Turma[]>([])
  const onSubmit = async (
    values: RelatorioTurmaValues,
    {
      setStatus,
      setErrors,
    }: {
      resetForm: (nextState?: Partial<FormikState<any>> | undefined) => void;
      setStatus: any;
      setErrors: any;
    }
  ) => {
    try {
      await props.onSubmit(values);
    } catch (error: any) {
      setStatus({ success: false });
      setErrors({ submit: error.message });
    }
  };
  const provas = useMemo(() => {
    const provasListadas: Prova[] = [];
    props.dados.forEach((d) => {
      if (!provasListadas.includes(d?.simulado?.prova!)) {
        provasListadas.push(d?.simulado?.prova!);
      }
    });
    return provasListadas;
  }, [props.dados]);

  const maisAcertos = useMemo(() => {
    return props.dados
      .sort(function (a, b) {
        return a.totalAcertos < b.totalAcertos
          ? 1
          : a.totalAcertos > b.totalAcertos
          ? -1
          : 0;
      })
      .slice(0, 4);
  }, [props.dados]);
  const maisAcertadosConfig = {
    labels: ["Questão 1", "Questão 2", "Questão 3", "Questão 4", "Questão 5"],
    datasets: [
      {
        label: "Quantidade de acertos",
        data: [
          maisAcertos[0]?.totalAcertos,
          maisAcertos[1]?.totalAcertos,
          maisAcertos[2]?.totalAcertos,
          maisAcertos[3]?.totalAcertos,
          maisAcertos[4]?.totalAcertos,
        ],
        backgroundColor: [
          "rgba(255, 99, 132)",
          "rgba(54, 162, 235)",
          "rgba(255, 206, 86)",
          "rgba(75, 192, 192)",
          "rgba(153, 102, 255)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const porcentagemAcertosProva = (provaId: number): number => {
    const dados = props.dados.filter((d) => d.simulado.provaId === provaId);
    let totalPorcentagens = 0;
    for (const valor of dados) {
      const porcentagem = (valor.totalAcertos / valor.numTentativas) * 100;
      totalPorcentagens += porcentagem;
    }
    return totalPorcentagens / dados.length;
  };

  const porcentagemErrosProva = (provaId: number): number => {
    const dados = props.dados.filter((d) => d.simulado.provaId === provaId);
    let totalPorcentagens = 0;
    for (const valor of dados) {
      const porcentagem = (valor.totalErros / valor.numTentativas) * 100;
      totalPorcentagens += porcentagem;
    }
    return totalPorcentagens / dados.length;
  };

  const melhoresProvas = useMemo(() => {
    const provasIds: number[] = [];
    props.dados.forEach((d) => {
      if (!provasIds.includes(d?.simulado?.provaId!)) {
        provasIds.push(d?.simulado?.provaId!);
      }
    });
    const porcentagens: { prova?: Prova; porcentagem: number }[] = [];
    for (const id of provasIds) {
      const porcentagem = porcentagemAcertosProva(id);
      porcentagens.push({
        prova: provas?.find((p) => p?.id === id),
        porcentagem,
      });
    }
    return porcentagens
      .sort(function (a, b) {
        return a.porcentagem < b.porcentagem
          ? 1
          : a.porcentagem > b.porcentagem
          ? -1
          : 0;
      })
      .slice(0, 4);
  }, [porcentagemAcertosProva, props.dados, provas]);

  const pioresProvas = useMemo(() => {
    const provasIds: number[] = [];
    props.dados.forEach((d) => {
      if (!provasIds.includes(d?.simulado?.provaId!)) {
        provasIds.push(d?.simulado?.provaId!);
      }
    });
    const porcentagens: { prova?: Prova; porcentagem: number }[] = [];
    for (const id of provasIds) {
      const porcentagem = porcentagemErrosProva(id);
      porcentagens.push({
        prova: provas?.find((p) => p?.id === id),
        porcentagem,
      });
    }
    return porcentagens
      .sort(function (a, b) {
        return a.porcentagem < b.porcentagem
          ? 1
          : a.porcentagem > b.porcentagem
          ? -1
          : 0;
      })
      .slice(0, 4);
  }, [porcentagemErrosProva, props.dados, provas]);

  const provasMaisAcertosConfig = {
    labels: [
      melhoresProvas[0]?.prova?.nome,
      melhoresProvas[1]?.prova?.nome,
      melhoresProvas[2]?.prova?.nome,
      melhoresProvas[3]?.prova?.nome,
      melhoresProvas[4]?.prova?.nome,
    ],
    datasets: [
      {
        label: "Porcentagem de acertos",
        data: [
          melhoresProvas[0]?.porcentagem,
          melhoresProvas[1]?.porcentagem,
          melhoresProvas[2]?.porcentagem,
          melhoresProvas[3]?.porcentagem,
          melhoresProvas[4]?.porcentagem,
        ],
        backgroundColor: [
          "rgba(255, 99, 132)",
          "rgba(54, 162, 235)",
          "rgba(255, 206, 86)",
          "rgba(75, 192, 192)",
          "rgba(153, 102, 255)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const provasMaisErrosConfigs = {
    labels: [
      pioresProvas[4]?.prova?.nome,
      pioresProvas[2]?.prova?.nome,
      pioresProvas[3]?.prova?.nome,
      pioresProvas[1]?.prova?.nome,
      pioresProvas[0]?.prova?.nome,
    ],
    datasets: [
      {
        label: "Porcentagem de acertos",
        data: [
          pioresProvas[4]?.porcentagem,
          pioresProvas[2]?.porcentagem,
          pioresProvas[3]?.porcentagem,
          pioresProvas[1]?.porcentagem,
          pioresProvas[0]?.porcentagem,
        ],
        backgroundColor: [
          "rgba(255, 99, 132)",
          "rgba(54, 162, 235)",
          "rgba(255, 206, 86)",
          "rgba(75, 192, 192)",
          "rgba(153, 102, 255)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const obterEscolas = async (secretariaId: number) => {
    try {
      dispatch({type: REALIZANDO_PROCESSAMENTO.ATIVO});
      if (secretariaId !== 0 ) {
        const response = await api.Escola.getBySecretariaId(secretariaId)
        setEscolas(response.data);
        return;
      }
      setEscolas([])
    } catch (e: any) {
      TratamentoErroPadrao(e, alert);
    } finally {
      dispatch({type: REALIZANDO_PROCESSAMENTO.NENHUM});
    }
  }

  const obterTurmas = async (escolas: Escola[]) => {
    try {
      dispatch({type: REALIZANDO_PROCESSAMENTO.ATIVO});
      const dados: Turma[] = []
      if (escolas.length > 0) {
        for (const escola of escolas) {
          const response = await api.Turma.porEscola(escola?.id)
          for (const dado of response.data) {
            dados.push(dado);
          }
        }
        setTurmas(dados)
        return;
      }
      setTurmas([])
    } catch (e: any) {
      TratamentoErroPadrao(e, alert);
    } finally {
      dispatch({type: REALIZANDO_PROCESSAMENTO.NENHUM});
    }
  }


  const obterTurmasPorPorfessor = async () => {
    try {
      dispatch({type: REALIZANDO_PROCESSAMENTO.ATIVO});
      const response = await api.Turma.porProfessor(Number(usuarioLogado?.id ?? 0))
      setTurmas(response.data);
    } catch (e: any) {
      TratamentoErroPadrao(e, alert);
    } finally {
      dispatch({type: REALIZANDO_PROCESSAMENTO.NENHUM});
    }
  }

  const TurmasPorAno = (ano: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void, values: any) => {
    const dados = turmas.filter(t => t.serie === ano)
    const novoArray = JuntarDoisArraysEliminandoIguais<Turma>(dados, values.turmas)
    setFieldValue('turmas', novoArray)
    setRerender(prev => prev + 1)
  }

  const obterTurmasPorMateria = async (materias: string[], escolas: Escola[]) => {
    try {
      dispatch({type: REALIZANDO_PROCESSAMENTO.ATIVO});
      const dados: Turma[] = []
      if (materias.length > 0) {
        for (const materia of materias) {
          if (props.escola) {
            const response = await api.Turma.porMateria2(materia, usuarioLogado?.escolaId ?? 0)
            for (const dado of response.data) {
              dados.push(dado);
            }
          }
          if (props.professor) {
            const response = await api.Turma.porMateria3(materia, Number(usuarioLogado?.id ?? 0))
            for (const dado of response.data) {
              dados.push(dado);
            }
          }
          for (const escola of escolas ?? []) {
            const response = await api.Turma.porMateria(materia, usuarioLogado?.secretariaId ?? 0, escola?.id)
            for (const dado of response.data) {
              dados.push(dado);
            }
          }
        }
        setTurmas(dados)
        return;
      }
      setTurmas([])
    } catch (e: any) {
      TratamentoErroPadrao(e, alert);
    } finally {
      dispatch({type: REALIZANDO_PROCESSAMENTO.NENHUM});
    }
  }
  const obterAlunosPorTurma = async (turma: Turma): Promise<UsuarioType[]> => {
    try {
      dispatch({type: REALIZANDO_PROCESSAMENTO.ATIVO});
      const response = await api.Usuario.buscarAlunosPorTurma(turma.id)
      return response?.data ?? [];
    } catch (e: any) {
      return (TratamentoErroPadrao(e, alert) as any);
    } finally {
      dispatch({type: REALIZANDO_PROCESSAMENTO.NENHUM});
    }
  }
  const handleToggleSelectAll = (setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    setSelectAll(prev => {
      if (!prev) {
        setFieldValue('turmas', turmas)
      } else {
        setFieldValue('turmas', [])
      }
      setRerender(prev => prev + 1)
      return !prev
    })
  };
  useEffect(() => {
    if (props.secretaria) {
      obterEscolas(usuarioLogado?.secretariaId ?? 0)
    }
  }, [props.secretaria, usuarioLogado?.secretariaId])

  useEffect(() => {
    if (props.professor) {
      obterTurmasPorPorfessor()
    }
  }, [props.professor])
  return (
    <>
      <Formik
        initialValues={props.initialValues}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <StyledContainer>
            <form
              onSubmit={handleSubmit}
              style={{ marginTop: "25px", marginBottom: "25px" }}
            >
              <Container>
                <Grid container spacing={2}>
                  {!props.secretaria && !props.escola && !props.professor && <Grid item sm={4} xs={12}>
                    <Autocomplete
                        id="secretarias"
                        value={values.secretaria}
                        options={props.secretarias}
                        getOptionLabel={(option) => option.nome ?? ""}
                        onChange={(e, value) => {
                          setFieldValue("secretaria", value)
                          obterEscolas(value?.id ?? 0)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Secretaria"
                            />
                        )}
                    />
                  </Grid>}
                  {!props.escola && !props.professor && <Grid item sm={4} xs={12}>
                    <Autocomplete
                        id="escolas"
                        value={values.escolas}
                        multiple
                        options={escolas}
                        getOptionLabel={(option) => option.nome ?? ""}
                        onChange={(e, value) => {
                          setFieldValue("escolas", value)
                          obterTurmas(value)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Escola"
                            />
                        )}
                    />
                  </Grid>}
                  {(props.secretaria || props.escola || props.professor) &&<Grid item sm={4} xs={12}>
                    <Autocomplete
                        id="materias"
                        value={values.materias}
                        multiple
                        options={materias}
                        getOptionLabel={(option) => option ?? ""}
                        onChange={(e, value) => {
                          setFieldValue("materias", value)
                          obterTurmasPorMateria(value, values.escolas)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Matérias"
                            />
                        )}
                    />
                  </Grid>}
                  <Grid item sm={4} xs={12}>
                    <Autocomplete
                      id="turmas"
                      value={values.turmas}
                      key={rerender}
                      multiple
                      options={turmas}
                      getOptionLabel={(option) => option.nome ?? ""}
                      onChange={async (e, value) => {
                        for (const turma of value) {
                          const data = await obterAlunosPorTurma(turma)
                          setAlunos(prev => [...prev, ...data])
                        }
                        setFieldValue("turmas", value)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Turma"
                        />
                      )}
                      PaperComponent={(paperProps) => {
                        const {children, ...restPaperProps} = paperProps;
                        return (
                            <Paper {...restPaperProps}>
                              <Box
                                  onMouseDown={(e) => e.preventDefault()} // prevent blur
                                  pl={1.5}
                                  py={0.5}
                              >
                                <FormControlLabel
                                    onClick={(e) => {
                                      e.preventDefault(); // prevent blur
                                      handleToggleSelectAll(setFieldValue);
                                    }}
                                    label="Todas as turmas"
                                    control={
                                      <Checkbox id="select-all-checkbox" checked={selectAll}/>
                                    }
                                />
                              </Box>
                              <Divider/>
                              {children}
                            </Paper>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Autocomplete
                        id="aluno"
                        value={values.aluno}
                        options={alunos}
                        getOptionLabel={(option) => option.name ?? ""}
                        onChange={(e, value) => {
                          setFieldValue("aluno", value)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Aluno"
                            />
                        )}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      label="Data inicial"
                      type="date"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      id="dataInicial"
                      name="dataInicial"
                      value={values.dataInicial}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      label="Data final"
                      type="date"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      id="dataFinal"
                      name="dataFinal"
                      value={values.dataFinal}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4} style={{ marginTop: "10px" }}>
                    <ConfirmButton type="submit" fullWidth>
                      Gerar relatório
                    </ConfirmButton>
                  </Grid>
                </Grid>
              </Container>
              {(props.secretaria || props.escola) && values.materias && values.materias.length > 0 && <>
                <h3 style={{marginLeft: '25px'}}>Turmas</h3>
                <Box2>
                  <React.Fragment>
                    <Button component="span"
                            onClick={() => {
                              TurmasPorAno('Primeiro Ano', setFieldValue, values)
                            }}
                            variant="contained"
                            color="primary"
                    >
                      Todas 1° ano
                    </Button>
                    <Button
                        component="span"
                        variant="contained"
                        onClick={() => {
                          TurmasPorAno('Segundo Ano', setFieldValue, values)
                        }}
                        color="primary"
                    >
                      Todas 2° ano
                    </Button>
                    <Button
                        component="span"
                        variant="contained"
                        onClick={() => {
                          TurmasPorAno('Terceiro Ano', setFieldValue, values)
                        }}
                        color="primary"
                    >
                      Todas 3° ano
                    </Button>
                    <Button
                        component="span"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          TurmasPorAno('Quarto Ano', setFieldValue, values)
                        }}
                    >
                      Todas 4° ano
                    </Button>
                    <Button
                        component="span"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          TurmasPorAno('Quinto Ano', setFieldValue, values)
                        }}
                    >
                      Todas 5° ano
                    </Button>
                    <Button
                        component="span"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          TurmasPorAno('Sexto Ano', setFieldValue, values)
                        }}
                    >
                      Todas 6° ano
                    </Button>
                    <Button
                        component="span"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          TurmasPorAno('Sétimo Ano', setFieldValue, values)
                        }}
                    >
                      Todas 7° ano
                    </Button>
                    <Button
                        component="span"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          TurmasPorAno('Oitavo Ano', setFieldValue, values)
                        }}
                    >
                      Todas 8° ano
                    </Button>
                    <Button
                        component="span"
                        variant="contained"
                        onClick={() => {
                          TurmasPorAno('Nono Ano', setFieldValue, values)
                        }}
                        color="primary"
                    >
                      Todas 9° ano
                    </Button>
                  </React.Fragment>
                </Box2>
              </>}
            </form>
            {maisAcertos.length > 0 && (
              <GridBox>
                <ChartDiv>
                  <Title>Questões com mais acertos</Title>
                  <Pie
                    data={maisAcertadosConfig}
                    options={{
                      plugins: {
                        tooltip: {
                          callbacks: {
                            label: (tooltipItem) => {
                              const numQuestao = tooltipItem.label
                                .split("Questão ")
                                .join("");
                              const enunciado =
                                maisAcertos[Number(numQuestao) - 1]?.simulado
                                  ?.enunciado.length > 20
                                  ? maisAcertos[
                                      Number(numQuestao) - 1
                                    ]?.simulado?.enunciado.slice(0, 20) + "..."
                                  : maisAcertos[Number(numQuestao) - 1]
                                      ?.simulado?.enunciado;
                              return `Enunciado: ${enunciado} Total de acertos: ${tooltipItem.formattedValue}`;
                            },
                          },
                        },
                      },
                    }}
                  />
                </ChartDiv>
                <ChartDiv>
                  <Title>Simulados com mais acertos</Title>
                  <Pie
                    data={provasMaisAcertosConfig}
                    options={{
                      plugins: {
                        tooltip: {
                          callbacks: {
                            label: (tooltipItem) => {
                              return `Porcentagem de acertos ${tooltipItem.formattedValue}%`;
                            },
                          },
                        },
                      },
                    }}
                  />
                </ChartDiv>
                <ChartDiv>
                  <Title>Simulados com mais erros</Title>
                  <Pie
                    data={provasMaisErrosConfigs}
                    options={{
                      plugins: {
                        tooltip: {
                          callbacks: {
                            label: (tooltipItem) => {
                              return `Porcentagem de acertos ${tooltipItem.formattedValue}%`;
                            },
                          },
                        },
                      },
                    }}
                  />
                </ChartDiv>
              </GridBox>
            )}
            <TableContainer component={Paper}>
              <Table size="medium">
                <TableHead>
                  <TableRow>
                    <TableCell>Questão</TableCell>
                    <TableCell>Escola</TableCell>
                    <TableCell>Turma</TableCell>
                    <TableCell>Quantidade de acertos</TableCell>
                    <TableCell>Quantidade de erros</TableCell>
                    <TableCell>Quantidade de tentativas</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.dados.map((d) => (
                    <TableRow key={d.id}>
                      <TableCell component="th" scope="row">
                        {d?.simulado?.enunciado}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {d?.grupoAlunos?.escola?.nome}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {d?.grupoAlunos?.nome}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {d?.totalAcertos}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {d?.totalErros}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {d?.numTentativas}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledContainer>
        )}
      </Formik>
    </>
  );
};
