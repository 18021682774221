import React from 'react';
import {Formik} from "formik";
import * as Yup from 'yup';
import {LoginFields} from "../../types/LoginFields";
import {Button, Grid, TextField} from "@material-ui/core";
import styled from 'styled-components';
import Logo from '../../terrasul-logo.png'

const LogoTipo = styled.img`
  margin-left: 18%;
  @media (max-width: 1368px) {
    margin-left: 5%;
  }
`

const Formulario = styled.form`
  background: #1673ac;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Content = styled.div`
  background: #FFF;
  min-height: 80vh;
  width: 40%;
  border-radius: 20px;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(92, 92, 92, 1);
  -moz-box-shadow: 0px 1px 5px 0px rgba(92, 92, 92, 1);
  box-shadow: 0px 1px 5px 0px rgba(92, 92, 92, 1);
`

const CadastrarSe = styled.a`
  color: #3363ff;
  font-size: 18px;
`

const Container = styled.div`
    margin-left: 15%;
    margin-top: 20%;
`

export type LoginProps = {
    onSubmitLogin: (dados: LoginFields) => void;
    onCadastrar: () => void
};

export const Login = (props: LoginProps) => {
    const loginSchema = Yup.object().shape({
        email: Yup.string()
            .required('E-mail é obrigatório')
            .email('Envie um e-mail válido'),
        senha: Yup.string().required('Senha é obrigatório'),
    });
    return <>
        <Formik
            initialValues={{ email: '', senha: '' }}
            validationSchema={loginSchema}
            onSubmit={(values) => {
                props.onSubmitLogin(values);
            }}
        >
            {({ errors, handleChange, handleSubmit }) => (
                <Formulario onSubmit={handleSubmit}>
                    <Content>
                        <Container>
                            <LogoTipo src={Logo} alt="logo" />
                            <Grid item xs={10}>
                                <TextField
                                    label="Seu e-mail"
                                    variant="standard"
                                    id="email"
                                    name="email"
                                    onChange={handleChange}
                                    fullWidth
                                    error={!!errors.email}
                                    helperText={errors.email}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    label="Sua senha"
                                    variant="standard"
                                    id="senha"
                                    name="senha"
                                    onChange={handleChange}
                                    type="password"
                                    error={!!errors.senha}
                                    helperText={errors.senha}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={10} style={{marginTop: "10px"}}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                >
                                    Fazer Login
                                </Button>
                            </Grid>
                            <Grid item xs={10} style={{marginTop: "10px", textAlign: "center"}}>
                                <CadastrarSe href="#" onClick={(e) => {
                                    e.preventDefault()
                                    props.onCadastrar()
                                }}>Cadastrar-se</CadastrarSe>
                            </Grid>
                        </Container>
                    </Content>
                </Formulario>
            )}
        </Formik>
    </>
}
