import React, {useCallback, useEffect, useState} from 'react'
import {FormularioRealidadeAumentada} from "./FormularioRealidadeAumentada";
import {useDispatch} from "react-redux";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useAlert} from "react-alert";
import {Livro} from "../../types/Livro";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {RealidadeAumentada} from "../../types/RealidadeAumentada";

export const CadastrarRealidadeAumentadaContainer = () => {
    const dispatch = useDispatch()
    const api = useGrowApi()
    const alert = useAlert()
    const [livros, setLivros] = useState<Livro[]>([])
    const buscaLivros = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Livro.buscarTodos();
            setLivros(response.data.rows)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [api, dispatch, alert])
    const cadastrar = useCallback(async (dados: RealidadeAumentada) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.RealidadeAumentada.cadastrar(dados);
            alert.success(response.message, {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [api, dispatch, alert])
    useEffect(() => {
        buscaLivros()
    }, [])
    return <>
        <FormularioRealidadeAumentada
            initialValues={{
                name: "",
                file: {},
                book: {},
            } as RealidadeAumentada}
            onSubmit={cadastrar}
            resetarForm
            titulo="Cadastrar realidade aumentada"
            livros={livros}
        />
    </>
}
