import React, {useCallback, useState} from 'react'
import {CancelButton, ConfirmButton} from "../Botoes/BotaoAcoes";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField
} from "@material-ui/core";
import styled from "styled-components";
import {Autocomplete} from "@material-ui/lab";
import { Turma } from '../../types/Turma';

const GridS = styled(Grid) `
    margin-bottom: 30px;
`

type CarregarCSVProps = {
    cadastrar: (file: File, id: number) => void
    grupos: Turma[]
}

export const CarregarCSV = ({cadastrar, grupos}: CarregarCSVProps) => {
    const [open, setOpen] = useState<boolean>(false)
    const [grupo, setGrupo] = useState<Turma>()
    const [file, setFile] = useState<any>()
    const [error, setError] = useState<string>('')
    const handleClose = useCallback(() => {
        setOpen(false)
    }, [setOpen])

    const finalizar = () => {
        if (!grupo || (grupo as any) === 0) {
            setError('Campo obrigatório')
            return
        }
        cadastrar(file, grupo?.id ?? 0)
        handleClose()
    }
    return <>
        <GridS item xs={4} style={{marginTop: "10px"}}>
            <ConfirmButton
                type="button"
                onClick={() => setOpen(true)}
                fullWidth
            >
                Carregar CSV
            </ConfirmButton>
        </GridS>
        <Dialog
            fullWidth
            maxWidth="md"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Carregar CSV
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Grid item sm={12} xs={12}>
                        <input
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            onChange={(e) => {
                                setFile(e.target?.files ? e.target.files[0] : undefined)
                            }}
                            type="file"
                            accept=".csv"
                        />
                        <label htmlFor="raised-button-file">
                            <Button component="span" variant="contained" color="primary">
                                {file?.name ?? "Enviar arquivo"}
                            </Button>
                        </label>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Autocomplete
                            id="grupos"
                            value={grupo}
                            options={grupos}
                            getOptionLabel={(option: Turma) => option.nome!}
                            onChange={(e, values) => setGrupo(values!)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Turma"
                                    error={error.length > 0}
                                    helperText={error}
                                />
                            )}
                        />
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleClose}>Cancelar</CancelButton>
                <ConfirmButton onClick={() => {
                    finalizar()
                }} autoFocus>
                    Confirmar
                </ConfirmButton>
            </DialogActions>
        </Dialog>
    </>
}
