import React, {useState} from 'react'
import {
    Button,
    Container, FormControl, FormHelperText,
    Grid, InputLabel, MenuItem, Select,
    TextField
} from "@material-ui/core";
import {Formik, FormikState} from "formik";
import * as Yup from 'yup'
import {CancelButton, ConfirmButton} from "../Botoes/BotaoAcoes";
import {useNavigate} from "react-router-dom";
import {ROUTES_PATH} from "../RoutesPath";
import {StyledContainer} from "../GrupoAcesso/FormularioGrupoAcesso";
import {Turma} from "../../types/Turma";
import {Autocomplete} from "@material-ui/lab";

export type FormularioTurmaProps = {
    initialValues: Turma
    onSubmit: (values: Turma) => void;
    resetarForm?: boolean
    titulo: string
}

export const FormularioTurma = (props: FormularioTurmaProps) => {
    const [materias, setMaterias] = useState<string[]>([])
    const navigate = useNavigate()
    const onSubmit = async (
        values: Turma,
        {
            resetForm,
            setStatus,
            setErrors,
        }: {
            resetForm: (
                nextState?: Partial<FormikState<Turma>> | undefined
            ) => void;
            setStatus: any;
            setErrors: any;
        }
    ) => {
        try {
            await props.onSubmit(values);
            if (props.resetarForm) {
                resetForm();
            }
        } catch (error: any) {
            setStatus({ success: false });
            setErrors({ submit: error.message });
        }
    };
    const schema = Yup.object().shape({
        nome: Yup.string().required('Campo obrigatório.'),
    });
    return <>
        <Formik
            initialValues={props.initialValues}
            enableReinitialize
            validationSchema={schema}
            onSubmit={onSubmit}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue
              }) => (
                <StyledContainer>
                    <form onSubmit={handleSubmit}>
                        <Container>
                            <h2>{props.titulo}</h2>
                            <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="Nome"
                                        variant="standard"
                                        id="nome"
                                        name="nome"
                                        value={values.nome}
                                        onChange={handleChange}
                                        fullWidth
                                        error={!!errors.nome && touched.nome}
                                        helperText={touched.nome && errors.nome ? errors.nome : ''}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                    >
                                        <InputLabel id="demo-simple-select-standard-label">Série</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            value={values.serie}
                                            onChange={(event) => {
                                                setFieldValue('serie', event.target.value)
                                                if (['Primeiro Ano', 'Segundo Ano', 'Terceiro Ano', 'Quarto Ano', 'Quinto Ano'].includes(event.target.value as string)) {
                                                    setMaterias([
                                                        'Língua portuguesa',
                                                        'Artes',
                                                        'Ed. Física',
                                                        'Matemática',
                                                        'Ciências',
                                                        'Geografia',
                                                        'História'
                                                    ])
                                                } else if (['Sexto Ano', 'Sétimo Ano', 'Oitavo Ano', 'Nono Ano'].includes(event.target.value as string)){
                                                    setMaterias([
                                                        'Língua portuguesa',
                                                        'Artes',
                                                        'Ed. Física',
                                                        'Língua inglesa',
                                                        'Matemática',
                                                        'Ciências',
                                                        'Geografia',
                                                        'História'
                                                    ])
                                                }
                                            }}
                                            id="serie"
                                            name="serie"
                                            label="serie"
                                        >
                                            <MenuItem value="Primeiro Ano" selected={values.serie === "Primeiro Ano"}>Primeiro Ano</MenuItem>
                                            <MenuItem value="Segundo Ano" selected={values.serie === "Segundo Ano"}>Segundo Ano</MenuItem>
                                            <MenuItem value="Terceiro Ano" selected={values.serie === "Terceiro Ano"}>Terceiro Ano</MenuItem>
                                            <MenuItem value="Quarto Ano" selected={values.serie === "Quarto Ano"}>Quarto Ano</MenuItem>
                                            <MenuItem value="Quinto Ano" selected={values.serie === "Quinto Ano"}>Quinto Ano</MenuItem>
                                            <MenuItem value="Sexto Ano" selected={values.serie === "Sexto Ano"}>Sexto Ano</MenuItem>
                                            <MenuItem value="Sétimo Ano" selected={values.serie === "Sétimo Ano"}>Sétimo Ano</MenuItem>
                                            <MenuItem value="Oitavo Ano" selected={values.serie === "Oitavo Ano"}>Oitavo Ano</MenuItem>
                                            <MenuItem value="Nono Ano" selected={values.serie === "Nono Ano"}>Nono Ano</MenuItem>
                                        </Select>
                                        <FormHelperText>{touched.serie && errors.serie ? errors.serie : ''}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                    >
                                        <InputLabel id="demo-simple-select-standard-label">Matéria</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            value={values.serie}
                                            onChange={handleChange}
                                            id="materia"
                                            name="materia"
                                            label="materia"
                                        >
                                            {
                                                materias.map((m, key) => (
                                                    <MenuItem key={key} value={m} selected={values.materia === m}>{m}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                        <FormHelperText>{touched.materia && errors.materia ? errors.materia : ''}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Autocomplete
                                        multiple
                                        id="turno"
                                        value={values.turno as string[]}
                                        options={[
                                            "Matutino",
                                            "Vespertino",
                                            "Noturno"
                                        ]}
                                        getOptionLabel={(option) => option}
                                        onChange={(e, values) => setFieldValue('turno', values)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Turno"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4} style={{marginTop: "10px"}}>
                                    <ConfirmButton
                                        type="submit"
                                        fullWidth
                                    >
                                        Salvar
                                    </ConfirmButton>
                                </Grid>
                                <Grid item xs={4} style={{marginTop: "10px"}}>
                                    <CancelButton
                                        type="submit"
                                        fullWidth
                                        onClick={() => navigate(ROUTES_PATH.LISTAR_ESCOLAS)}
                                    >
                                        Cancelar
                                    </CancelButton>
                                </Grid>
                            </Grid>
                        </Container>
                    </form>
                </StyledContainer>
            )}
        </Formik>
    </>
}
