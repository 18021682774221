import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch} from "react-redux";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useAlert} from "react-alert";
import {Livro} from "../../types/Livro";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {FormularioSimulados} from "./FormularioSimulados";
import {Prova} from "../../types/Simulado";
import {useParams} from "react-router-dom";

export const EditarSimuladoContainer = () => {
    const dispatch = useDispatch()
    const api = useGrowApi()
    const alert = useAlert()
    const {id} = useParams<{id: string}>()
    const [livros, setLivros] = useState<Livro[]>([])
    const [prova, setProva] = useState<Prova>({
        book: {} as Livro
    } as Prova)
    const buscaLivros = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Livro.buscarTodos();
            setLivros(response.data.rows)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [api, dispatch, alert])
    const buscarSimulado = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Prova.buscarUm(Number(id));
            console.log(response.data.revelarNota)
            setProva(response.data)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [api, dispatch, alert, setProva, id])
    const editar = useCallback(async (dados: Prova) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const { simulados } = dados
            const response = await api.Prova.editar(dados, Number(id));
            const paraRemover = prova.simulados.filter(s => !dados.simulados?.find(ds => ds.id === s.id))
            if (paraRemover && paraRemover.length > 0) {
                for (const simulado of paraRemover) {
                    await api.Simulado.excluir(Number(simulado.id));
                }
            }
            for (const simulado of simulados) {
                const simuladoExiste = prova.simulados?.find(s => s.id === simulado.id)
                if (simuladoExiste) {
                    await api.Simulado.excluir(Number(simulado.id));
                    const novo = await api.Simulado.cadastrar({
                        ...simulado,
                        id: undefined as any,
                        provaId: response.data.id
                    });
                    if (simulado.titulo) {
                        const formData = new FormData();
                        formData.append('titulo', simulado.titulo);
                        if (simulado.fileTitulo) {
                            formData.append('file', simulado.fileTitulo);
                        }
                        await api.Simulado.cadastrarTitulo(formData, Number(novo?.data?.id ?? 0));
                    }
                } else {
                    const novo = await api.Simulado.cadastrar({
                        ...simulado,
                        id: undefined as any,
                        provaId: response.data.id
                    });
                    if (simulado.titulo) {
                        const formData = new FormData();
                        formData.append('titulo', simulado.titulo);
                        if (simulado.fileTitulo) {
                            formData.append('file', simulado.fileTitulo);
                        }
                        await api.Simulado.cadastrarTitulo(formData, Number(novo?.data?.id ?? 0));
                    }
                }
            }
            setProva({
                ...response.data,
                    simulados: dados.simulados
            })
            alert.success(response.message, {
                timeout: 4000
            })
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [api, dispatch, alert, prova, setProva])
    useEffect(() => {
        buscaLivros()
        buscarSimulado()
    }, [])
    return <>
        <FormularioSimulados
            initialValues={prova}
            editar
            onSubmit={editar}
            resetarForm={false}
            titulo="Editar simulado"
            livros={livros}
        />
    </>
}
