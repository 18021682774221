import React, {useCallback, useEffect, useState} from "react";
import {Ranking, RelatorioRankingValues} from "./Ranking";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {Secretaria} from "../../types/Secretaria";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/RootState";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useAlert} from "react-alert";
import {RankingResponse} from "../../types/RankingResponse";

type Props = {
    secretaria?: boolean
    escola?: boolean
    professor?: boolean
}

export const RankingContainer = (props: Props) => {
    const [secretarias, setSecretarias] = useState<Secretaria[]>([])
    const [dados, setDados] = useState<RankingResponse[]>([]);
    const usuarioLogado = useSelector((state: RootState) => state.usuarioLogado);
    const api = useGrowApi();
    const dispatch = useDispatch();
    const alert = useAlert();
    const buscaDados = useCallback(async () => {
        try {
            dispatch({type: REALIZANDO_PROCESSAMENTO.ATIVO});
            const response = await api.Secretaria.buscarTodos();
            setSecretarias(response.data.rows);
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({type: REALIZANDO_PROCESSAMENTO.NENHUM});
        }
    }, [api, dispatch]);

    const buscaRelatorio = useCallback(
        async (values: RelatorioRankingValues) => {
            try {
                dispatch({type: REALIZANDO_PROCESSAMENTO.ATIVO});

                if (values.turmas && values.turmas.length > 0) {
                    if (props.professor) {
                        const novosDados: RankingResponse[] = []
                        for (const turma of values.turmas) {
                            const response = await api.Simulado.rankingPorProfessorETurma(
                                turma.id!,
                                values.dataInicial as Date,
                                values.dataFinal as Date
                            );
                            for (const dado of response.data) {
                                novosDados.push(dado)
                            }
                        }
                        setDados(novosDados)
                        return;
                    }
                    const novosDados: RankingResponse[] = []
                    for (const turma of values.turmas) {
                        const response = await api.Simulado.rankingPorTurma(
                            turma.id!,
                            values.dataInicial as Date,
                            values.dataFinal as Date
                        );
                        for (const dado of response.data) {
                            novosDados.push(dado)
                        }
                    }
                    setDados(novosDados)
                } else if (values.materias && values.materias.length > 0) {
                    const novosDados: RankingResponse[] = []
                    for (const materia of values.materias) {
                        if (values.escolas && values.escolas.length > 0) {
                            for (const escola of values.escolas) {
                                const response = await api.Simulado.rankingPorMateria(
                                    materia,
                                    escola.id,
                                    values.dataInicial as Date,
                                    values.dataFinal as Date
                                );
                                for (const dado of response.data) {
                                    novosDados.push(dado)
                                }
                            }
                        } else {
                            const response = await api.Simulado.rankingPorMateria(
                                materia,
                                usuarioLogado?.escolaId ?? 0,
                                values.dataInicial as Date,
                                values.dataFinal as Date
                            );
                            for (const dado of response.data) {
                                novosDados.push(dado)
                            }
                        }
                    }
                    setDados(novosDados)
                } else if ((values.escolas && values.escolas.length > 0) || props.escola || props.professor) {
                    const novosDados: RankingResponse[] = []
                    if (props.professor) {
                        const response = await api.Simulado.rankingPorEscola(
                            usuarioLogado?.escolaId ?? 0,
                            values.dataInicial as Date,
                            values.dataFinal as Date
                        );
                        for (const dado of response.data) {
                            novosDados.push(dado)
                        }
                    }
                    if (props.escola) {
                        const response = await api.Simulado.rankingPorEscola(
                            usuarioLogado?.escolaId ?? 0,
                            values.dataInicial as Date,
                            values.dataFinal as Date
                        );
                        for (const dado of response.data) {
                            novosDados.push(dado)
                        }
                    }
                    for (const escola of values.escolas ?? []) {
                        const response = await api.Simulado.rankingPorEscola(
                            props.escola ? usuarioLogado?.escolaId ?? 0 : escola.id,
                            values.dataInicial as Date,
                            values.dataFinal as Date
                        );
                        for (const dado of response.data) {
                            novosDados.push(dado)
                        }
                    }
                    setDados(novosDados)

                }
                else if (values.secretaria || props.secretaria) {
                    const response = await api.Simulado.rankingPorSecretaria(
                        props.secretaria ? usuarioLogado?.secretariaId ?? 0 : values.secretaria.id,
                        values.dataInicial as Date,
                        values.dataFinal as Date
                    );
                    setDados(response.data);
                } else {
                    const response = await api.Simulado.rankingSemFiltro(
                        values.dataInicial as Date,
                        values.dataFinal as Date
                    );
                    setDados(response.data);
                }
            } catch (e: any) {
                TratamentoErroPadrao(e, alert);
            } finally {
                dispatch({type: REALIZANDO_PROCESSAMENTO.NENHUM});
            }
        },
        [api, dispatch]
    );

    useEffect(() => {
        buscaDados()
    }, [])
    return <>
        <Ranking
            initialValues={{} as RelatorioRankingValues}
            secretaria={props.secretaria}
            escola={props.escola}
            professor={props.professor}
            onSubmit={buscaRelatorio}
            secretarias={secretarias}
            dados={dados}
        />
    </>
}