import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch} from "react-redux";
import {useGrowApi} from "../../hooks/useGrowApi";
import {useAlert} from "react-alert";
import {Livro} from "../../types/Livro";
import {REALIZANDO_PROCESSAMENTO} from "../../redux/actions/realizandoProcessamento.actions";
import {TratamentoErroPadrao} from "../../utils/TratamentoErroPadrao";
import {FormularioSimulados} from "./FormularioSimulados";
import {OpcaoSimulado, Prova} from "../../types/Simulado";
import {v4 as uuid} from "uuid";

export const CadastrarSimuladoContainer = () => {
    const dispatch = useDispatch()
    const api = useGrowApi()
    const alert = useAlert()
    const [livros, setLivros] = useState<Livro[]>([])
    const buscaLivros = useCallback(async () => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const response = await api.Livro.buscarTodos();
            setLivros(response.data.rows)
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }, [api, dispatch, alert])
    const cadastrar = async (dados: Prova) => {
        try {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.ATIVO });
            const {simulados, ...complemento} = dados
            const response = await api.Prova.cadastrar(complemento as Prova)
            for (const simulado of simulados) {
                const novo = await api.Simulado.cadastrar({
                    ...simulado,
                    provaId: response?.data?.id
                });
                if (simulado.titulo) {
                    const formData = new FormData();
                    formData.append('titulo', simulado.titulo);
                    if (simulado.fileTitulo) {
                        formData.append('file', simulado.fileTitulo);
                    }
                    await api.Simulado.cadastrarTitulo(formData, Number(novo?.data?.id ?? 0));
                }
            }
            if (dados.bookId && (dados.bookId as any) !== 'undefined') {
                await api.Prova.montarLivro(response.data.id)
            }
        } catch (e: any) {
            TratamentoErroPadrao(e, alert);
        } finally {
            dispatch({ type: REALIZANDO_PROCESSAMENTO.NENHUM });
        }
    }
    useEffect(() => {
        buscaLivros()
    }, [])
    return <>
        <FormularioSimulados
            initialValues={{
                nome: "",
                book: {} as Livro,
                page: Number(""),
                revelarNota: false,
                escolas: [],
                simulados: [
                    {
                        id: uuid(),
                        enunciado: '',
                        discursiva: false,
                        opcoes: [
                            {
                                id: uuid(),
                                titulo: '',
                                correto: false
                            } as OpcaoSimulado,
                            {
                                id: uuid(),
                                titulo: '',
                                correto: false
                            } as OpcaoSimulado
                        ]
                    }
                ]
            }}
            cadastrando
            onSubmit={cadastrar}
            resetarForm
            titulo="Cadastrar simulado"
            livros={livros}
        />
    </>
}
